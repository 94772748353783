import { createSelector } from 'reselect';

const docsState = state => state.documents;

export const getDocuments = createSelector(docsState, state => state.documents);

export const getDocumentsStatus = createSelector(
  docsState,
  state => state.documentsStatus
);

export const isLoading = createSelector(
  docsState,
  state => state.documentsStatus === 'loading'
);

export const hasError = createSelector(
  docsState,
  state => state.documentsStatus === 'error'
);

export const getCollectionName = createSelector(
  docsState,
  state => (state.collection ? state.collection.name : null)
);

export const getCurrentPage = createSelector(
  docsState,
  state => state.currentPage
);

export const getTotalItems = createSelector(
  docsState,
  state => state.totalItems
);

export const getPageSize = createSelector(docsState, state => state.pageSize);

export const getTotalPages = createSelector(
  getTotalItems,
  getPageSize,
  (totalItems, pageSize) => Math.ceil(totalItems / pageSize) || 1
);

export const isTransactionUnderway = createSelector(
  docsState,
  state => state.isTransactionUnderway
);
