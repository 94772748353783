import React from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import s from './styles.scss';

interface Props {
  text: string;
  t: any;
}
interface State {
  textExpanded?: boolean;
}
class ToggleableText extends React.Component<Props, State> {

  private text = React.createRef<HTMLDivElement>();

  constructor(props){
    super(props);
    this.state = {
      textExpanded:  undefined
    }
  }
  componentDidMount() {
    const contentWidth = this.text.current.scrollWidth;
    const elementWidth = this.text.current.clientWidth;
    contentWidth > elementWidth
      ? this.setState({textExpanded: false})
      : null;

  }
  render() {
    const {text, t} = this.props;
    const {textExpanded} = this.state;
    return (
      <>
        <div className={classNames(
          s.toggleableText,
          {[s.textExpanded]: textExpanded}
        )} ref={this.text}>
          {text}
        </div>
        {textExpanded === false &&
          <a href="javascript:void(0)" onClick={()=> this.setState({textExpanded: true})}>{t('toggleableText.showMore')}</a>
        }
        {textExpanded &&
          <a href="javascript:void(0)" onClick={()=> this.setState({textExpanded: false})}>{t('toggleableText.showLess')}</a>
        }
      </>
    );
  }
}
export default translate('translations')(withStyles(s)(ToggleableText));
