import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

const modelsState = state => state.models;
export const getModelDetails = (state, props) => (state.models &&
  state.models.model &&
  state.models.model[props.modelId]) || {}
export const getModels = createSelector(modelsState, state => state.models);

export const getPublishedModels = createSelector(getModels, models =>
  models.filter(model => model.published === true)
);
export const getTaxonomyValues = createSelector(
  modelsState,
  model => model.taxonomyValues
);
export const getModelParams = createSelector(getModelDetails, model => model.parameters && Object.entries(model.parameters));

export const getModelMetrics = createSelector(getModelDetails, model => model.metrics || {});


export const getModelDetailsStatus = createSelector(
  modelsState,
  state => state.modelStatus
);

export const getModelsStatus = createSelector(
  modelsState,
  state => state.modelsStatus
);

export const isLoading = createSelector(
  modelsState,
  state => state.modelsStatus === 'loading' || state.modelStatus === 'loading'
);

export const hasError = createSelector(
  modelsState,
  state => state.modelsStatus === 'error'
);

export const getCurrentPage = createSelector(
  modelsState,
  state => state.currentPage
);

export const getTotalItems = createSelector(
  modelsState,
  state => state.totalItems
);

export const getPageSize = createSelector(modelsState, state => state.pageSize);

export const getTotalPages = createSelector(
  getTotalItems,
  getPageSize,
  (totalItems, pageSize) => Math.ceil(totalItems / pageSize) || 1
);

export const getModelsAsSelectOptions = createSelector(
  getPublishedModels,
  models => {
    return models.map(model => ({
      value: model.id,
      label: model.name,
      taxonomyId: model.taxonomyId
    }));
  }
);

export const getTrainingPipelines = createSelector(modelsState, state => {
  const pipelines = state.trainingPipelines;
  let parsedPipelines;
  try {
    parsedPipelines = pipelines.map(pipeline => {
      return {
        ...pipeline,
        parameterSchema: JSON.parse(pipeline.parameterSchema),
        defaultParameters: JSON.parse(pipeline.defaultParameters)
      };
    });
  } catch (e) {
    parsedPipelines = [];
  }
  return parsedPipelines;
});

export const getDatasets = createSelector(modelsState, state => state.datasets);

export const getTrainingDatasets = createSelector(
  getDatasets,
  datasets => Immutable.asMutable(datasets).filter(dataset => dataset.type === 'TRAINING')
);

export const getTrainingDatasetOptions = createSelector(
  getTrainingDatasets,
  trainingDatasets => trainingDatasets.map(dataset => ({
    label: dataset.name,
    value: dataset.id,
    taxonomyId: dataset.taxonomy.id
  }))
);

export const getGroundTruthDatasets = createSelector(
  getDatasets,
  datasets => Immutable.asMutable(datasets).filter(dataset => dataset.type === 'GROUND_TRUTH')
);

export const getGroundTruthDatasetOptions = createSelector(
  getGroundTruthDatasets,
  groundTruthDatasets => groundTruthDatasets.map(dataset => ({
    label: dataset.name,
    value: dataset.id,
    taxonomyId: dataset.taxonomy.id
  }))
);

export const isLoadingFormData = createSelector(
  modelsState,
  state =>
    state.trainingPipelinesStatus === 'loading' ||
    state.datasetsStatus === 'loading'
);

export const isTransactionUnderway = createSelector(
  modelsState,
  state => state.isTransactionUnderway
);

export const getSelectedModels = createSelector(
  modelsState,
  state => state.selectedModels
)

export const getSelectedTaxonomy = createSelector(
  modelsState,
  state => state.selectedTaxonomy
)

const computeDistributionFromMetrics = (classificationReport) => {
  let count = 0;
  const items =  Object.entries(classificationReport).filter(([key]) =>{
    return !['micro avg', 'macro avg', 'weighted avg'].includes(key)
  }).sort(
    ([,itemA], [, itemB]) => itemA['total-support'] - itemB['total-support'])
  .map(([key, label]) => {
    // "total-support" is the value count for test-classification reports
    // but "support" is the value count for gt-classification reports
    count += (label['total-support'] || label['support']);
    return {
      taxonomyValue: key,
      taxonomyValueId: key,
      count: (label['total-support'] || label['support']),
      ['f1-score']: label['f1-score'],
      precision: label['precision'],
      recall: label['recall'],
      support: label['support'],
      ['training-support']: label['training-support'],
    }
  });
  return {
    totalCount: count,
    items
  }
}

export const getDistributionFromMetrics = createSelector(getModelMetrics, (metricObj = {}) => {
  const metrics = metricObj.metrics || {};
  const testClassificationReport = metrics['classification-report'] || metrics['test-classification-report'] || {};
  return computeDistributionFromMetrics(testClassificationReport);
});

export const getGroundTruthDistribution = createSelector(
  getModelMetrics,
  (metricsObj) => {
    const metrics = metricsObj.metrics || {};
    const gtClassificationReport = metrics['gt-classification-report'] || {};
    return computeDistributionFromMetrics(gtClassificationReport);
  }
);
