import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './styles.scss';
import ModelDetailParameters from './parameters';
import ModelDetailMetrics from './metrics';
import classNames from 'classnames';
import SvgSpinner from '../../../../toolkit/svgSpinner';
interface Props {
  getModel: () => void;
  parameters: [String, any];
  metrics: [String, any];
  distribution: any;
  groundTruthDistribution: any;
  isLoading: boolean;
  compare: boolean;
  modelDetails: any;
  t: (key: string) => string;
}

class ModelDetail extends Component<Props> {
  state = {
    activeTab: '1'
  };

  componentDidMount() {
    const { getModel } = this.props;
    getModel();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { parameters, metrics, isLoading, distribution, groundTruthDistribution, compare, modelDetails } = this.props;
    if (isLoading) {
      return (
        <article className={s['loading-overlay']}>
          <SvgSpinner/>
        </article>
      );
    }
    return (
      <main className={classNames({[s['compare']]: compare })}>
        {
          compare &&
          <header>{modelDetails.name}</header>
        }
        <ModelDetailParameters parameters={parameters} compare={compare} />
        <div style={{ width: '85%' }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                Test Metrics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                Ground Truth Metrics
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent style={{ width: '100%', borderLeft: '1px solid #ddd' }} activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <ModelDetailMetrics metrics={metrics} distribution={distribution} compare={compare} />
            </TabPane>
            <TabPane tabId="2">
              <ModelDetailMetrics isGroundTruth metrics={metrics} distribution={groundTruthDistribution} compare={compare} />
            </TabPane>
          </TabContent>
        </div>
      </main>
    );
  }
}

export default translate('translations')(
  withStyles(s)(ModelDetail)
);
