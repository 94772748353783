import { createSelector } from 'reselect';

const collectionsState = state => state.collections;

export const getCollections = createSelector(
  collectionsState,
  state => state.collections
);

export const getCollectionsStatus = createSelector(
  collectionsState,
  state => state.collectionsStatus
);
export const getDocumentTypes = createSelector(
  collectionsState,
  state => {
    return [...state.documentTypes.map(type => {
      return {
        label: type.value,
        value: type.id
      }
    })]
  }
);
export const getDocumentTypesStatus = createSelector(
  collectionsState,
  state => state.documentTypesStatus
);
export const getCurrentPage = createSelector(
  collectionsState,
  state => state.currentPage
);

export const getTotalItems = createSelector(
  collectionsState,
  state => state.totalItems
);

export const getPageSize = createSelector(
  collectionsState,
  state => state.pageSize
);

export const getTotalPages = createSelector(
  getTotalItems,
  getPageSize,
  (totalItems, pageSize) => Math.ceil(totalItems / pageSize) || 1
);
