import React from 'react';
import BaseInput from '../../../../toolkit/baseInput';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';

import BaseButton from '../../../../toolkit/baseButton';
import Form from '../../../../toolkit/form';
import s from './styles.scss';

// TODO: Can we merge validationRules below into the fields() definitions?
class CollectionNew extends Form {
  fields() {
    return {
      collectionName: ''
    };
  }

  validationRules = {
    collectionName: {
      required: true,
      maxLength: 75
    }
  };

  render() {
    const { t } = this.props;
    return (
      <form className={s.newCollectionForm} onSubmit={this.onSubmit}>
        <BaseInput
          name="collectionName"
          type="text"
          label={t('collections.collection-name-label')}
          value={this.state.fields.collectionName}
          placeholder={t('collections.collection-name-placeholder')}
          dataElmId="collection_add_name"
          onChange={this.handleChangeFor('collectionName')}
          errors={this.errorsFor('collectionName')}
        />
        <BaseButton
          type="submit"
          onClick={this.onSubmit}
          className={'m-t-25'}
          color="primary"
          size="lg"
          block
          data-elm-id={'create_collection_button'}>
          {t('collections.create-button-label')}
        </BaseButton>
      </form>
    );
  }
}

export default translate('translations')(
  withStyles(s)(withRouter(CollectionNew))
);
