import { connect } from 'react-redux';
import ModelNew from './component';
import {
  requestTrainingPipelines,
  requestDatasetsForModel,
  trainModel
} from '../../../actions/creators';
import {
  getTrainingPipelines,
  isLoadingFormData,
  getTrainingDatasetOptions,
  getGroundTruthDatasetOptions
} from '../../../reducers/models/selectors';
import { TrainModelRequest } from '../../../../types-business/Model';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';

const mapStateToProps = state => ({
  trainingPipelines: getTrainingPipelines(state),
  isLoadingFormData: isLoadingFormData(state),
  datasets: getTrainingDatasetOptions(state),
  groundTruthDatasets: getGroundTruthDatasetOptions(state)
});

const mapDispatchToProps = dispatch => ({
  requestTrainingPipelines: () => dispatch(requestTrainingPipelines()),
  requestDatasetsForModel: () => dispatch(requestDatasetsForModel()),
  trainModel(model: TrainModelRequest) {
    const onSuccess = () => {
      const { t, history } = this.props;
      history.push('/models');
      dispatch(showToastSuccess(t('models.new.train-success')));
    };
    const onFailure = () => {
      const { t } = this.props;
      dispatch(toggleErrorModal(t('models.new.train-failure')));
    };
    dispatch(
      trainModel(model, {
        onSuccess,
        onFailure
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelNew);
