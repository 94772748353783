import {
  getDatasets,
  getDataset,
  getDatasetItems,
  getModels,
  getTaxonomies,
  getTaxonomyValues,
  getAddItemsQueryResults,
  getDatasetDistribution,
  getUserReviewProgress
} from './queries';
import {
  addItemsToDataset,
  addLabel as createLabel,
  createDataset,
  publishDataset as publish,
  unpublishDataset as unpublish,
  deleteDatasetItems,
  revertLabel as removeLabel
} from './mutations';
import apolloClient from '../../utils/apolloClient';
import constants from '../../utils/constants';
import Axios from 'axios';

const fetchPolicy = 'network-only';

export const fetchDatasets = (
  page: number = 0,
  size: number = 20,
  sort?: string,
): Promise<object> => {
  const variables = { page, size, sort };
  return apolloClient.query({ query: getDatasets, variables, fetchPolicy });
};

export const fetchTaxonomies = (): Promise<object> => {
  return apolloClient.query({ query: getTaxonomies, fetchPolicy });
};

export const fetchModels = (
  page: number = 0,
  size: number = 20,
  sort: string = 'createdBy,DESC',
  where?: string
): Promise<object> => {
  const variables = { page, size, sort, where };
  return apolloClient.query({ query: getModels, variables, fetchPolicy });
};

export const fetchDataset = (datasetId: string): Promise<object> => {
  const variables = { datasetId };
  return apolloClient.query({ query: getDataset, variables, fetchPolicy });
};

export const fetchDatasetItems = (
  datasetId: string,
  allLabels: boolean = false,
  page: number = 0,
  size: number = 20
): Promise<object> => {
  const variables = { datasetId, allLabels, page, size };
  return apolloClient.query({
    query: getDatasetItems,
    variables,
    fetchPolicy
  });
};

export const removeDatasetItems = (
  datasetId: string,
  ids: string[]
): Promise<object> => {
  const mutation = deleteDatasetItems;
  const variables = { datasetId, ids };
  return apolloClient.mutate({ mutation, variables });
};

export const newDataset = (dataset): Promise<object> => {
  const mutation = createDataset;
  const variables = { dataset };
  return apolloClient.mutate({ mutation, variables });
};

export const publishDataset = (datasetId: string): Promise<object> => {
  const mutation = publish;
  const variables = { datasetId };
  return apolloClient.mutate({ mutation, variables });
};

export const unpublishDataset = (datasetId: string): Promise<object> => {
  const mutation = unpublish;
  const variables = { datasetId };
  return apolloClient.mutate({ mutation, variables });
};

export const fetchAddItemsQueryResults = (
  datasetId,
  collectionId,
  documentId,
  restrictions,
  textRestrictions,
  limit,
  offset
): Promise<object> => {
  const variables = {
    datasetId,
    collectionId,
    documentId,
    restrictions,
    textRestrictions,
    limit,
    offset
  };
  return apolloClient.query({
    query: getAddItemsQueryResults,
    variables,
    fetchPolicy
  });
};

export const addItems = (
  datasetId: string,
  collectionId: string,
  documentId: string,
  restrictions: string,
  textRestrictions: string,
  limit: string,
  offset: string
): Promise<object> => {
  const mutation = addItemsToDataset;
  const variables = {
    datasetId,
    collectionId,
    documentId,
    restrictions,
    textRestrictions,
    limit,
    offset
  };
  return apolloClient.mutate({ mutation, variables });
};

export const addLabel = (
  datasetId,
  componentId,
  taxonomyValueId,
  isNegative = false,
  start = null,
  end = null
): Promise<object> => {
  const mutation = createLabel;
  const variables = { datasetId, componentId, taxonomyValueId, isNegative, start, end };
  return apolloClient.mutate({ mutation, variables });
};

export const revertLabel = (labelId, taxonomyValueId): Promise<object> => {
  const mutation = removeLabel;
  const variables = { labelId, taxonomyValueId };
  return apolloClient.mutate({ mutation, variables });
};

export const fetchTaxonomyValues = (taxonomyId): Promise<object> => {
  const variables = { taxonomyId };
  return apolloClient.query({
    query: getTaxonomyValues,
    variables,
    fetchPolicy
  });
};

export const fetchDistribution = (datasetId: string): Promise<object> => {
  const variables = { datasetId };
  return apolloClient.query({
    query: getDatasetDistribution,
    variables,
    fetchPolicy
  });
};

export const fetchUserReviewProgress = (
  datasetId: string,
  userId: number
): Promise<object> => {
  const variables = { datasetId, userId };
  return apolloClient.query({
    query: getUserReviewProgress,
    variables,
    fetchPolicy
  });
};

export const fetchSentenceContext = (
  sentenceId: String
): Promise<object> => {
  return Axios.get(`${constants.BASE_URL}/api/sentences/${sentenceId}/context?contextSize=3`, {
    withCredentials: true
  });
};

export const downloadDatasetFile = (datasetId: string, fileName: string = 'text.csv'): Promise<object> => {
  return Axios.get(`${constants.BASE_URL}/api/datasets/${datasetId}/download?fileName=${fileName}`, {
    withCredentials: true
  });
}

export default {
  addItems,
  fetchAddItemsQueryResults,
  fetchDatasets,
  fetchDataset,
  fetchModels,
  fetchUserReviewProgress,
  newDataset,
  publishDataset,
  removeDatasetItems,
  revertLabel
};
