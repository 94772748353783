import { connect } from 'react-redux';

import {
  getDatasetInfo,
  getReviewedItemCount,
  getTotalItemCount,
  isInLabelReviewMode
} from '../../../../reducers/dataset/selectors';
import DatasetReviewToolbar from './component';
import { requestUserReviewProgress, toggleLabelReviewMode } from '../../../../actions/creators';
import { getMyUserId, isDataManager } from '../../../../../_Login/reducers/selectors';

const mapStateToProps = state => ({
  datasetInfo: getDatasetInfo(state),
  userId: getMyUserId(state),
  reviewedItemCount: getReviewedItemCount(state),
  totalItemCount: getTotalItemCount(state),
  isInLabelReviewMode: isInLabelReviewMode(state),
  isDataManager: isDataManager(state)
});

const mapDispatchToProps = dispatch => ({
  requestUserReviewProgress: (datasetId: string, userId: number) =>
    dispatch(requestUserReviewProgress(datasetId, userId)),
    toggleLabelReviewMode: () => dispatch(toggleLabelReviewMode())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetReviewToolbar);
