exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._255_i{padding:20px;height:100%;width:100%}._2799D{display:block;font-size:14px}._19Ocg{display:inline-block;margin-right:15px}._19Ocg label{font-size:12px}._3SGCs textarea{resize:none;height:100px}.aTRac{font-size:10px;color:#d9534f}.R5mI8{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}", ""]);

// exports
exports.locals = {
	"newDatasetForm": "_255_i",
	"formSectionLabel": "_2799D",
	"radioButton": "_19Ocg",
	"description": "_3SGCs",
	"errorSpan": "aTRac",
	"loading": "R5mI8"
};