import { gql } from 'react-apollo';

export default gql`
  mutation(
    $datasetId: String!
    $componentId: String!
    $taxonomyValueId: String!
    $isNegative: Boolean,
    $start: Int,
    $end: Int
  ) {
    addLabel(
      datasetId: $datasetId
      componentId: $componentId
      taxonomyValueId: $taxonomyValueId
      isNegative: $isNegative,
      start: $start,
      end: $end
    ) {
      data {
        labelId
        componentId
        taxonomyValue
        taxonomyValueId
        score
        createdBy
        createdDate
        startPosition
        endPosition
      }
    }
  }
`;
