import * as i18n from "i18next";
import * as XHR from "i18next-xhr-backend";
import * as LanguageDetector from "i18next-browser-languagedetector";

const instance = i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    whitelist: ["en"],
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    react: {
      wait: false,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default instance;
