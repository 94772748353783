import { createSelector } from 'reselect';
import get from 'lodash/get';
import { hasDataManagerRole } from '../../utils/helpers';

const loginState = state => state.login;

export const getUserContext = createSelector(
  loginState,
  state => state.userContext || {}
);

export const getMyCompanyId = createSelector(
  getUserContext,
  userContext => userContext.company.companyId
);

export const getMyUserId = createSelector(
  getUserContext,
  userContext => userContext.userId
);

export const getMyRoles = createSelector(
  getUserContext,
  userContext => get(userContext, 'groups', [])
);

export const isDataManager = createSelector(
  getMyRoles,
  roles => hasDataManagerRole(roles)
);