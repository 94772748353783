import React, { Component } from 'react';
import { History } from 'history';
import { translate } from 'react-i18next';
import BaseButton from '../../../../../toolkit/baseButton';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { matchPath } from 'react-router-dom';

interface Props {
  location: Location;
  history: History;
  selectedModels: string[];
  t: (str: string) => string;
  cleanModelSelection: () => void;
}

class ModelListToolbar extends Component<Props> {
  render() {
    const { history, t, location, selectedModels, cleanModelSelection } = this.props;
    const isShowingUploadSidebar = !!matchPath(location.pathname, {
      path: '/models/new',
      exact: true
    });

    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem active>{t('models.page-title')}</BreadcrumbItem>
          </Breadcrumb>
        </section>
        <section className="controls-zone">
          { selectedModels.length !== 0 &&
            <BaseButton
              onClick={() =>
                cleanModelSelection()
              }
              size="sm"
              color="primary"
              data-elm-id={'clear_selection_button'}>
              {t('models.clearSelection')}
            </BaseButton>
          }
          <BaseButton
            onClick={() =>
              history.push(
                `/model/compare?selectedModels=${JSON.stringify(selectedModels)}`
              )
            }
            size="sm"
            color="primary"
            disabled={selectedModels.length !== 2}
            data-elm-id={'compare_button'}>
            {t('models.compare-models-button-label')}
          </BaseButton>
          <BaseButton
            onClick={() => history.push(`/models/new`)}
            size="sm"
            color="primary"
            disabled={isShowingUploadSidebar}
            data-elm-id={'new_model_button'}>
            {t('models.new-model-button-label')}
          </BaseButton>
        </section>
      </>
    );
  }
}

export default translate('translations')(ModelListToolbar);
