exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._3ZvbE{width:15%;height:100%;background-color:#f6f8fa}._3ZvbE._1rQmg{height:auto;width:90%}._3ZvbE._1rQmg ._2Dx6S table{width:50%}._3ZvbE ._238T1{border-bottom:1px solid #edf0f2;color:#1c1c43;padding:16px 24px;padding:1rem 1.5rem;text-transform:capitalize}._3ZvbE ._3E19C{padding-top:16px;padding-top:1rem}._3ZvbE ._3dj73{margin-left:24px;margin-left:1.5rem;width:60%;display:-ms-flexbox;display:flex}._3ZvbE ._3dj73 div{width:50%;text-align:left}._3ZvbE ._2Dx6S{padding:0 24px;padding:0 1.5rem;margin-bottom:16px;margin-bottom:1rem}._3ZvbE ._2Dx6S table{margin-left:16px;margin-left:1rem;width:100%}._3ZvbE ._2Dx6S table td{width:60%;text-align:left}._3ZvbE ._2Dx6S table td:last-child{width:40%}._3ZvbE .NLjH7{font-weight:700}._3ZvbE ._1xs5X{color:#8b91a3;font-size:12px}._3ZvbE ._3cUki{color:#1c1c43}", ""]);

// exports
exports.locals = {
	"parameters": "_3ZvbE",
	"compare": "_1rQmg",
	"parametersList_item": "_2Dx6S",
	"parametersHeader": "_238T1",
	"parametersContainer": "_3E19C",
	"parametersList": "_3dj73",
	"parametersList__itemHeader": "NLjH7",
	"parametersList__itemLabel": "_1xs5X",
	"parametersList__itemValue": "_3cUki"
};