import React from "react";
import { Helmet } from "react-helmet";
import { translate } from "react-i18next";

import LayoutStyles from "../../../toolkit/baseStyles";
import NotFound from "../../components/not-found";
import Footer from "../../components/footer";

interface Props {
  t: (str: string) => string;
}

const NotFoundPage = (props: Props) => (
  <main>
    <Helmet title="404" />
    <LayoutStyles>
      <div className="full-height">
        <NotFound />
        <Footer t={props.t} />
      </div>
    </LayoutStyles>
  </main>
);

export default translate("translations")(NotFoundPage);
