import { createSelector } from 'reselect';

const datasetsState = state => state.datasets;

export const getDatasets = createSelector(
  datasetsState,
  state => state.datasets
);
export const getDatasetsSort = createSelector(
  datasetsState,
  state => state.sortBy
);

export const getDatasetsStatus = createSelector(
  datasetsState,
  state => state.datasetsStatus
);

export const getCurrentPage = createSelector(
  datasetsState,
  state => state.currentPage
);

export const getTotalItems = createSelector(
  datasetsState,
  state => state.totalItems
);

export const getPageSize = createSelector(
  datasetsState,
  state => state.pageSize
);

export const getTotalPages = createSelector(
  getTotalItems,
  getPageSize,
  (totalItems, pageSize) => Math.ceil(totalItems / pageSize) || 1
);

export const hasError = createSelector(
  datasetsState,
  state => state.datasetsStatus === 'error'
);

export const isTransactionUnderway = createSelector(
  datasetsState,
  state => state.isTransactionUnderway
);
