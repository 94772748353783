import React, { Component } from 'react';
import { History } from 'history';
import { translate } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

interface Props {
  collectionId: string;
  documentId: string;
  documentName: string;
  collectionName: string;
  location: Location;
  history: History;
  t: (str: string) => string;
}

class DocumentViewToolbar extends Component<Props> {
  render() {
    const { t, collectionId, collectionName, documentName } = this.props;
    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem>
              <Link to="/collections">{t('collections.page-title')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/collections/${collectionId}/documents`}>
                {collectionName}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{documentName}</BreadcrumbItem>
          </Breadcrumb>
        </section>
        <section className="controls-zone" />
      </>
    );
  }
}

export default translate('translations')(DocumentViewToolbar);
