import { gql } from 'react-apollo';

export default gql`
  query GetTrainingPipelines {
    getTrainingPipelines {
      data {
        id
        name
        parameterSchema
        defaultParameters
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
      }
    }
  }
`;
