import actionTypes from '../actionTypes';
import IAction from '../../types/IAction';

interface State {
  showErrorModal: boolean;
  showToastSuccess: boolean;
  showToastError: boolean;
  errorModalMessage: string;
  toastSuccessMessage: string;
}

const initialState: State = {
  showErrorModal: false,
  showToastSuccess: false,
  showToastError: false,
  errorModalMessage: null,
  toastSuccessMessage: null
};

export default function modalReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case actionTypes.ERROR_MODAL_TOGGLE: {
      return {
        ...state,
        showErrorModal: !state.showErrorModal,
        errorModalMessage: action.payload
      };
    }
    case actionTypes.SHOW_TOAST_SUCCESS: {
      return {
        ...state,
        showToastSuccess: true,
        toastSuccessMessage: action.payload
      };
    }
    case actionTypes.RESET_TOAST: {
      return {
        ...state,
        showToastSuccess: false,
        showToastError: false,
        toastSuccessMessage: null
      };
    }
    case actionTypes.RESET_ERROR_TOAST: {
      return {
        ...state,
        showErrorModal: false,
        showToastError: false,
        errorModalMessage: null
      };
    }
    case actionTypes.RESET_APP: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState };
