import React, { Component } from 'react';
import Reform from '@franleplant/reform';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import BaseButton from '../../../../toolkit/baseButton';
import UploadImage from '../../../../media/img/upload.png';
import DocumentImage from '../../../../media/img/document.png';
import FileDropZone from '../../../../toolkit/fileDropZone';
import s from './styles.scss';
import BaseSelect from '../../../../toolkit/baseSelect';

interface Props {
  className: string;
  documentTypes: any;
  collectionId: number;
  t: (str: string, vars?: any) => string;
  uploadDocument: (collectionId: number, file: File, documentTypeId: String) => void;
  getDocumentTypes: () => void;
}

interface State {
  fields: any;
  errors: any;
}

class DocumentUpload extends Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);
  documentTypeSelect = null;
  constructor(props) {
    super(props);
    this.state = {
      fields: { ...this.fields() },
      errors: {}
    };
  }
  componentDidMount() {
    const { getDocumentTypes } = this.props
    getDocumentTypes()
  }
  fields() {
    return {
      file: '',
      document_type: '',
    };
  }

  validationRules = {
    file: { required: true }
  };

  validationMessages = {
    required: () => 'This field is required',
    default: (_ruleKey, _ruleValue, fieldName) =>
      `The value for field ${fieldName} is invalid`
  };

  onFileChangeFactory = (files, rejected) => {
    const invalidType = rejected && rejected.length > 0;
    const inputFile = files[0] || null;
    this.re.validateField('file', inputFile);
    this.setState(state => {
      const fields = { ...state.fields, file: inputFile };
      const errors = { ...state.errors, file: { invalidType } };
      return { ...state, errors, fields, message: null };
    });
  };

  errorsFor(fieldName) {
    return this.re.mapFieldErrors(fieldName);
  }

  hasValidFile() {
    const { fields, errors } = this.state;
    // only return true if there is a file staged and it is NOT an invalid type of file
    return !!fields.file && !errors.file.invalidType;
  }

  handleSelectChangeFor = fieldName => {
    return selectedOpt => {
      const value = selectedOpt.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, fields };
      });

    };
  };

  handleSubmit() {
    const { uploadDocument, collectionId } = this.props;
    const { fields } = this.state;
    uploadDocument(collectionId, fields.file, fields.document_type);
  }

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.handleSubmit();
    }
  };

  render() {
    const { t, documentTypes = [] } = this.props;
    const {
      fields: { file }
    } = this.state;
    const documentTypeWithEmptyOption = [
      {
        label: t('collections.emptyDocumentType'),
        value: ''
      },
      ...documentTypes
    ]
    return (
      <div className={s.fileUpload}>
        <BaseSelect
          options={documentTypeWithEmptyOption}
          name='document_type'
          onChange={this.handleSelectChangeFor('document_type')}
          placeholder={t('collections.requestDocumentTypes.select_placeholder')}
        />
        <FileDropZone
          name={'document'}
          fileName={file ? file.name : ''}
          fileSize={file ? file.size : 0}
          multiple={false}
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onDrop={this.onFileChangeFactory}
          errors={this.errorsFor('file')}
          dataElmId={'collection_upload_file'}
          uploadImage={UploadImage}
          documentImage={DocumentImage}
          dragLabel={t('documents.upload.drag-file')}
          chooseFileLabel={t('documents.upload.choose-file')}
          chooseAnotherFileLabel={t('documents.upload.choose-another-file')}
          cancelLabel={t('documents.upload.cancel-upload')}
          notSupportedLabel={t('documents.upload.not-supported-error')}
          isCreatingProject={false} // todo: implement via container and state when upload occurring
        />
        <BaseButton
          type="submit"
          onClick={this.onSubmit}
          className={'m-t-25'}
          color="primary"
          size="lg"
          block
          disabled={!this.hasValidFile()}
          data-elm-id={'upload_document_button'}>
          {t('documents.upload.upload-button-label')}
        </BaseButton>
      </div>
    );
  }
}

export default translate('translations')(withStyles(s)(DocumentUpload));
