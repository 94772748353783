exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2-RP8{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._2-RP8 span{font-style:italic}._1b519{margin-top:15px}.exG0U{display:inline-block;margin:5px;width:100%;height:calc(100vh - 140px);overflow-y:auto}", ""]);

// exports
exports.locals = {
	"loading": "_2-RP8",
	"taxonomy": "_1b519",
	"sentencesSection": "exG0U"
};