import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import { DOCUMENTS_REQUEST, UPLOAD_DOCUMENT } from '../../actions/types';

const intialState = {
  // all documents for a collection
  documents: [],
  // fetch status for collection documents: loading, error, completed
  documentsStatus: null,
  // collection information
  collection: null,
  // current page number
  currentPage: 0,
  // number of documents to display on each page
  pageSize: 20,
  // total items
  totalItems: 0,
  // indicates whether a transaction is ongoing and screen should be blocked
  isTransactionUnderway: false
};

export default handleActions(
  {
    [DOCUMENTS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('documentsStatus', 'loading'),
        failure: s => s.set('documentsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getDocuments;
          return s.merge({
            documentsStatus: 'completed',
            documents: result.data,
            totalItems: result.meta.totalItems,
            collection: result.meta.collection,
            pageSize: result.meta.pagination.pageSize,
            currentPage: result.meta.pagination.page
          });
        }
      });
    },
    [UPLOAD_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          const uploadedDocument = action.payload.data;
          return s.merge({
            isTransactionUnderway: false,
            documents: [uploadedDocument, ...s.documents]
          });
        }
      });
    }
  },
  Immutable.from(intialState)
);
