import { gql } from 'react-apollo';

export default gql`
  query GetSentenceLabels($sentenceId: ID!) {
    getSentenceLabels(sentenceId: $sentenceId) {
      data {
        id
        source
        taxonomyValue {
          value
        }
        startPosition
        endPosition
        score
        certainty
        probabilities {
          label
          score
        }
      }
    }
  }
`;
