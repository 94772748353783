exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Uj8CA{position:relative}.in6OV{position:absolute;top:0;bottom:0;left:0;right:0;color:transparent}.NCOuj mark{background-color:transparent;padding:0;opacity:.8}.NCOuj mark mark{opacity:.5}.NCOuj mark mark mark{opacity:.3}", ""]);

// exports
exports.locals = {
	"textContent": "Uj8CA",
	"fakeText": "in6OV",
	"text": "NCOuj"
};