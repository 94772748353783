import { connect } from 'react-redux';

import DatasetDetails from './component';
import {
  requestDataset,
  requestDistribution,
  publishDataset,
  requestUserReviewProgress,
  unpublishDataset,
  downloadDataset
} from '../../../actions/creators';
import {
  getDatasetInfo,
  isDatasetInfoLoading,
  getDistribution,
  isDistributionLoading,
  hasDistributionError,
  isLoadingUserProgress,
  getReviewedItemCount,
  getTotalItemCount,
  isPublishable,
} from '../../../reducers/dataset/selectors';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';
import { getMyUserId } from '../../../../_Login/reducers/selectors';

const mapStateToProps = (state, props) => {
  return {
    datasetInfo: getDatasetInfo(state),
    distribution: getDistribution(state, props),
    hasDistributionError: hasDistributionError(state),
    isDistributionLoading: isDistributionLoading(state),
    isLoading: isDatasetInfoLoading(state) || isLoadingUserProgress(state),
    isPublishable: isPublishable(state),
    reviewedItemCount: getReviewedItemCount(state),
    totalItemCount: getTotalItemCount(state),
    userId: getMyUserId(state),
  };
};

const mapDispatchToProps = dispatch => ({
  requestDataset: (datasetId: string) => dispatch(requestDataset(datasetId)),
  requestDistribution: (datasetId: string) =>
    dispatch(requestDistribution(datasetId)),
  requestUserReviewProgress: (datasetId: string, userId: number) =>
    dispatch(requestUserReviewProgress(datasetId, userId)),
  publishDataset(datasetId: string) {
    dispatch(
      publishDataset(datasetId, {
        onSuccess: () => {
          const { t } = this.props;
          dispatch(showToastSuccess(t('dataset-info.publish.success')));
        },
        onFailure: () => {
          const { t } = this.props;
          dispatch(toggleErrorModal(t('dataset-info.publish.failure')));
        }
      })
    );
  },
  unpublishDataset(datasetId: string) {
    dispatch(
      unpublishDataset(datasetId, {
        onSuccess: () => {
          const { t } = this.props;
          dispatch(showToastSuccess(t('dataset-info.unpublish.success')));
        },
        onFailure: () => {
          const { t } = this.props;
          dispatch(toggleErrorModal(t('dataset-info.unpublish.failure')));
        }
      })
    );
  },
  downloadDatasetFile(datasetId: string, fileName: string, onSuccess, onFailure) {
    dispatch(downloadDataset(datasetId, fileName, { onSuccess, onFailure }))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetDetails);
