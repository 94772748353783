import { connect } from 'react-redux';

import CollectionList from './component';
import { requestCollections } from '../../../actions/creators';
import {
  getCollections,
  getCollectionsStatus,
  getCurrentPage,
  getPageSize,
  getTotalItems
} from '../../../reducers/collections/selectors';

const mapStateToProps = state => ({
  items: getCollections(state),
  isLoading: getCollectionsStatus(state) === 'loading',
  currentPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  totalItems: getTotalItems(state),
  hasError: getCollectionsStatus(state) === 'error'
});

const mapDispatchToProps = dispatch => ({
  loadCollections: (page, size) => dispatch(requestCollections(page, size))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionList);
