import { createSelector } from 'reselect';
import _ from 'lodash';

const docState = state => state.document;

export const getDocumentText = createSelector(
  docState,
  state => state.documentText
);

export const getDocumentStatus = createSelector(
  docState,
  state => state.documentStatus
);

export const getDocumentName = createSelector(
  docState,
  state => state.documentName
);

export const getCollectionName = createSelector(
  docState,
  state => state.collectionName
);

export const isLoading = createSelector(
  getDocumentStatus,
  status => status === 'loading'
);

export const hasError = createSelector(
  getDocumentStatus,
  status => status === 'error'
);

export const getSentences = createSelector(docState, state => state.sentences);

export const getLabelMap = createSelector(docState, state => state.labelMap);

export const getSelectedLabelValue = createSelector(
  docState,
  state => state.selectedLabelValue
);

export const getCurrentSelectedLabelSentenceId = createSelector(
  docState,
  state => state.currentSelectedLabelSentenceId
);

export const getSelectedLabelSentenceIds = createSelector(
  docState,
  state => state.selectedLabelSentenceIds
);

export const getFocusSentenceId = createSelector(
  docState,
  state => state.focusSentenceId
);

export const getCurrentSelectedLabelSentenceIdPosition = createSelector(
  getSelectedLabelSentenceIds,
  getFocusSentenceId,
  (labelSentenceIds, currentId) => {
    const currentIndex = labelSentenceIds.indexOf(currentId);
    return currentIndex + 1;
  }
);

export const getNextMatchingSentenceId = createSelector(
  getSelectedLabelSentenceIds,
  getFocusSentenceId,
  (labelSentenceIds, currentId) => {
    const currentIndex = labelSentenceIds.indexOf(currentId);
    return labelSentenceIds[currentIndex + 1];
  }
);

export const getPreviousMatchingSentenceId = createSelector(
  getSelectedLabelSentenceIds,
  getFocusSentenceId,
  (labelSentenceIds, currentId) => {
    const currentIndex = labelSentenceIds.indexOf(currentId);
    return labelSentenceIds[currentIndex - 1];
  }
);

export const hasFocusSentence = createSelector(
  docState,
  state => state.focusSentenceId != null
);

export const getFocusSentenceLabels = createSelector(
  docState,
  state => state.focusSentenceLabels
);

export const isFocusSentenceLabelsLoading = createSelector(
  docState,
  state => state.focusSentenceLabelsStatus === 'loading'
);

export const hasFocusSentenceLabelsError = createSelector(
  docState,
  state => state.focusSentenceLabelsStatus === 'error'
);

export const getFocusSentenceLabelDetails = createSelector(
  getFocusSentenceLabels,
  getSelectedLabelValue,
  (labels, selectedLabel) => {
    return selectedLabel
      ? labels.filter(label => label.value === selectedLabel)
      : labels;
  }
);
