import { connect } from 'react-redux';

import DatasetAddItemsPreview from './component';
import {
  getItemsToAdd,
  isItemsToAddLoading,
  hasItemsToAddError
} from '../../../../reducers/dataset/selectors';

const mapStateToProps = state => ({
  items: getItemsToAdd(state),
  isLoading: isItemsToAddLoading(state),
  hasError: hasItemsToAddError(state)
});

export default connect(mapStateToProps)(DatasetAddItemsPreview);
