import { connect } from 'react-redux';

import ModelList from './component';
import {
  getModels,
  getTotalPages,
  getCurrentPage,
  getPageSize,
  hasError,
  isLoading,
  getSelectedModels,
  getSelectedTaxonomy
} from '../../../reducers/models/selectors';
import { requestModels, toggleModelSelection, cleanModelSelection } from '../../../actions/creators';

const mapStateToProps = state => ({
  isLoading: isLoading(state),
  hasError: hasError(state),
  items: getModels(state),
  currentPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  totalPages: getTotalPages(state),
  selectedModels: getSelectedModels(state),
  selectedTaxonomy: getSelectedTaxonomy(state)
});

const mapDispatchToProps = {
  requestModels,
  toggleModelSelection,
  cleanModelSelection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelList);
