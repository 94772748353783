import React from 'react';
import { Label, Input } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import s from './styles.scss';

interface Props {
  id: string;
  name: string;
  label?: string;
  value: string;
  checked: boolean;
  dataElmId: string;
  onChange: (string: string) => void;
  disabled: boolean;
  innerRef: (input: HTMLInputElement) => void;
  bgClass?: string;
}

class RadioButton extends React.Component<Props, void> {
  static defaultProps = {
    label: '',
    bgClass: 'primary',
    disabled: false
  };

  render() {
    const {
      id,
      name,
      label,
      dataElmId,
      innerRef,
      bgClass,
      disabled,
      onChange,
      value,
      checked
    } = this.props;

    return (
      <Label
        className={classNames(s.container, bgClass)}
        data-disabled={disabled}>
        {label}
        <Input
          id={id}
          data-elm-id={`${dataElmId}_${id}`}
          type="radio"
          innerRef={innerRef}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          bg-class={bgClass}
        />
        <span className={s.checkmark} />
      </Label>
    );
  }
}

export default withStyles(s)(RadioButton);
