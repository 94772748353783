exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2UYOd{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._3tmh8{padding:0;margin:0}._1cbYJ{min-height:30px;padding:15px;border-top:1px solid #e4e5e9}.EZP4t{color:#4c5670}._2i1EF,.EZP4t{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._2laAD,._3d0Ah{font-size:11px;color:#8b91a3;text-transform:capitalize;font-weight:700}.fwBof{margin-top:10px;height:calc(100% - 20px);overflow-y:auto}._3Kp66 li{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}", ""]);

// exports
exports.locals = {
	"loading": "_2UYOd",
	"labels": "_3tmh8",
	"labelItem": "_1cbYJ",
	"labelDetail": "EZP4t",
	"metadata": "_2i1EF",
	"label": "_2laAD",
	"value": "_3d0Ah",
	"sentenceLabels": "fwBof",
	"probabilities": "_3Kp66"
};