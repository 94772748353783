import { createNetworkInterface } from "react-apollo";
import constants from "./constants";

const INetwork = createNetworkInterface({
  uri: `${constants.BASE_URL}/api/gql`,
  opts: {
    credentials: constants.BASE_URL.length > 0 ? "include" : "same-origin",
  },
});

INetwork.useAfter([
  {
    applyAfterware: async (req, next) => {
      const newResp = req.response.clone();
      try {
        const result = await newResp.json();
        if (
          result &&
          result.errors &&
          result.errors[0] &&
          result.errors[0].message &&
          result.errors[0].message === constants.DEAD_SESSION_MSG &&
          result.errors[0].path &&
          result.errors[0].path[0] !== "me"
        ) {
          if (window.location.pathname !== "/login") {
            window.location.replace("/login");
          }
        }
        next();
      } catch (e) {
        next();
      }
    },
  },
]);

export default INetwork;
