import React, { Component } from 'react';
import { translate } from 'react-i18next';
import ReactTable from 'react-table';
import ReactTableStyles from 'react-table/react-table.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Message from '../../../../toolkit/message';
import classNames from 'classnames';
import { History, Location } from 'history';

import columns from './_table/columns';
import s from './styles.scss';
import ReactTableStylesOverride from '../../../../scss/_react-table.scss';
import { Model } from '../../../../types-business/Model';
import { getPaginationData } from '../../../../utils/helpers';
import Pagination from '../../../../toolkit/pagination';

const Table = ReactTable;
interface Props {
  items: Model[];
  isLoading: boolean;
  hasError: boolean;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  selectedModels: string[];
  selectedTaxonomy: string;
  location: Location;
  history: History;
  t: (key: string) => string;
  requestModels: (page?: number, size?: number, sort?: string) => void;
  toggleModelSelection:  (id: string) => void;
  cleanModelSelection: () => void;
}

class ModelList extends Component<Props> {

  componentWillMount() {
    const { requestModels, location } = this.props;
    const { page, size, sort } = getPaginationData(location)
    requestModels(page, size, sort || 'createdDate,DESC');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { requestModels, location } = this.props;
      const { page, size, sort } = getPaginationData(location)
      requestModels(page, size, sort);
    }
  }

  onSortBy = (field: string, direction?: string) => {
    const { location } = this.props;
    const {page} = getPaginationData(location)
    const sortString = direction ? `&sort=${field},${direction}` : '';
    this.props.history.push(`?page=${page + 1}${ sortString }`);
  }

  requestPage = (page) => {
    const { location } = this.props;
    const { sort } = getPaginationData(location);
    const sortString = sort ? `&sort=${sort}` : '';
    this.props.history.push(`?page=${page + 1}${ sortString }`);
  }

  isSelected = (id) => {
    return this.props.selectedModels.includes(id);
  }

  isComparable = taxonomyId => this.props.selectedTaxonomy === taxonomyId;

  render() {
    const {
      items,
      isLoading,
      hasError,
      totalPages,
      pageSize,
      toggleModelSelection,
      selectedModels,
      t,
    } = this.props;

    const { page } = getPaginationData(location);

    if (hasError) {
      return <Message message={t('errors.data-load')} type="error" />;
    }

    return (
      <>
        <Table
          className={classNames('default-akorda-table', s.trainingStatus)}
          columns={columns(
            {
              onSortBy: this.onSortBy,
              isSelected: this.isSelected,
              toggleModelSelection,
              ...this.props
            }
          )}
          data={items}
          loading={isLoading}
          loadingText={t('models.loading')}
          manual
          noDataText={t('models.no-data')}
          pageSize={pageSize}
          sortable={false}
          showPaginationBottom={false}
          getTdProps={(_,rowInfo) => {
            const id = rowInfo &&
              rowInfo.original &&
              rowInfo.original.id
            const taxonomyId = rowInfo &&
              rowInfo.original &&
              rowInfo.original.taxonomyId
            const sameTaxonomy = this.isComparable(taxonomyId)
            if( (sameTaxonomy && selectedModels.length > 0 && selectedModels.length < 2) ||
                selectedModels.length === 0 ||
                this.isSelected(id)
              ){
              return {
                style: {
                  background: 'white',
                  whiteSpace: 'normal',
                  transition: '0.3s ease all'
                }
              };
            }
            else{
              return {
                style: {
                  background: '#ddd',
                  opacity: 0.5,
                  pointerEvents: 'none',
                  whiteSpace: 'normal',
                  transition: '0.3s ease all'

                }
              };
            }
          }}
          onPageChange={this.requestPage}
        />
        <Pagination
          pageNumber={page}
          pageSize={pageSize}
          totalPages={totalPages}
          onPrevious={this.requestPage}
          onNext={this.requestPage}
        />
      </>
    );
  }
}

export default translate('translations')(
  withStyles(ReactTableStyles, ReactTableStylesOverride, s)(ModelList)
);
