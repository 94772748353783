import React, { Component } from 'react';
import { Dataset } from '../../../../types-business/Dataset';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import classNames from 'classnames';

import ReactTable from 'react-table';
import ReactTableStyles from 'react-table/react-table.css';
import ReactTableStylesOverride from '../../../../scss/_react-table.scss';
import columns from './_table/columns';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import Pagination from '../../../../toolkit/pagination';
import Message from '../../../../toolkit/message';
import s from './styles.scss';
import { History, Location } from 'history';
import { getPaginationData } from '../../../../utils/helpers';

interface Props {
  hasError: boolean;
  items: Dataset[];
  isLoading: boolean;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  location: Location;
  history: History;
  requestDatasets: (page?: number, size?: number, sort?: string) => void;
  t: (str: string, vars?: any) => string;
}

export class DatasetList extends Component<Props> {
  componentWillMount() {
    const { requestDatasets, location } = this.props;
    const {page, size, sort} = getPaginationData(location)
    requestDatasets(page, size, sort || 'createdDate,DESC');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { requestDatasets, location } = this.props;
      const {page, size, sort} = getPaginationData(location)
      requestDatasets(page, size, sort);
    }
  }
  onSortBy = (field: string, direction?: string) => {
    const { location } = this.props;
    const {page} = getPaginationData(location)
    const sortString = direction ? `&sort=${field},${direction}` : '';
    this.props.history.push(`?page=${page + 1}${ sortString }`);
  }
  requestPage = (page) => {
    const { location } = this.props;
    const { sort } = getPaginationData(location);
    const sortString = sort ? `&sort=${sort}` : '';
    this.props.history.push(`?page=${page + 1}${ sortString }`);
  }

  render() {
    const {
      currentPage,
      hasError,
      items,
      isLoading,
      pageSize,
      t,
      totalItems
    } = this.props;

    if (hasError) {
      return (
        <Message message={t('datasets.datasets-load-error')} type="error" />
      );
    }

    if (isLoading) {
      return (
        <div className={`${s.loadingDatasets} loading-datasets`}>
          <SvgSpinner />
        </div>
      );
    }

    if (items.length) {
      return (
        <div className={s.datasets}>
          <ReactTable
            className={classNames('default-akorda-table', s.datasetTable)}
            columns={columns({onSortBy: this.onSortBy, styles: s, ...this.props})}
            data={items}
            loading={isLoading}
            loadingText={t('models.loading')}
            manual
            noDataText={t('models.no-data')}
            pageSize={pageSize}
            sortable={false}
            showPaginationBottom={false}
            onPageChange={this.requestPage}
          />
          <Pagination
            className={s.dataSetPagination}
            pageNumber={currentPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPrevious={this.requestPage}
            onNext={this.requestPage}
          />
        </div>
      );
    } else {
      return <Message message={t('datasets.no-items-message')} type="info" />;
    }
  }
}

export default translate('translations')(
  withStyles(ReactTableStyles, ReactTableStylesOverride, s)(DatasetList)
);
