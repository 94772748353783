import { gql, graphql } from "react-apollo";

export function pureMe() {
  return gql`
    query {
      me {
        email
        firstName
        lastName
        company {
          companyId
          companyName
        }
        groups
        profileId
        userId
        latestLoginDate
        latestPasswordChangeDate
        verificationDate
        status
        createdDate
      }
    }
  `;
}

export function me(options = {}) {
  return graphql(pureMe(), {
    name: "getMe",
    options: {
      ...options,
    },
  });
}
