import { gql } from 'react-apollo';

export default gql`
  mutation($collectionName: String!, $companyId: Int!) {
    createCollection(collectionName: $collectionName, companyId: $companyId) {
      id
      name
      documentCount
    }
  }
`;
