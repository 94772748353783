import { gql } from 'react-apollo';

export default gql`
  query GetTaxonomyValues($taxonomyId: String!) {
    getTaxonomyValues(taxonomyId: $taxonomyId) {
      data {
        id
        value
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
      }
    }
  }
`;
