import { connect } from 'react-redux';

import DatasetItem from './component';
import {
  requestSentenceContext
} from '../../../../actions/creators';
import {
  getPredecessor,
  getSuccessor,
  getContextStatus
} from '../../../../reducers/dataset/selectors';

const mapStateToProps = (state, ownProps) => ({
  predecessor: getPredecessor(state, ownProps),
  successor: getSuccessor(state, ownProps),
  status: getContextStatus(state, ownProps)
});

const mapDispatchToProps = (dispatch) => ({
  requestSentenceContext: (sentenceId: string) => dispatch(requestSentenceContext(sentenceId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetItem);
