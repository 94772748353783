export const DOCUMENT_REQUEST = 'DATA-SCI::DOCUMENT_REQUEST';
export const DOCUMENT_LABELS_REQUEST = 'DATA-SCI::DOCUMENT_LABELS_REQUEST';
export const DOCUMENTS_REQUEST = 'DATA-SCI::DOCUMENTS_REQUEST';
export const DOCUMENT_VIEW_LABEL_SELECT =
  'DATA-SCI::DOCUMENT_VIEW_LABEL_SELECT';
export const DOCUMENT_VIEW_SENTENCE_LABELS_REQUEST =
  'DATA-SCI::DOCUMENT_VIEW_SENTENCE_LABELS_REQUEST';
export const DOCUMENT_VIEW_FOCUS_SENTENCE =
  'DATA-SCI::DOCUMENT_VIEW_FOCUS_SENTENCE';
export const DOCUMENT_VIEW_CLEAR_SELECTED_LABEL =
  'DATA-SCI::DOCUMENT_VIEW_CLEAR_SELECTED_LABEL';
export const COLLECTIONS_REQUEST = 'DATA-SCI::COLLECTIONS_REQUEST';
export const COLLECTION_CREATE = 'DATA-SCI::COLLECTION_CREATE';
export const UPLOAD_DOCUMENT = 'DATA-SCI::UPLOAD_DOCUMENT';
export const DOCUMENT_TYPES_REQUEST = 'DATA-SCI::DOCUMENT_TYPES_REQUEST';
