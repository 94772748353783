import { gql } from 'react-apollo';

export default gql`
  query GetDocuments($collectionId: ID!, $page: Int! = 0, $size: Int! = 20, $sort: String) {
    getDocuments(collectionId: $collectionId, page: $page, size: $size, sort: $sort) {
      data {
        id
        name
        pipelineDocumentId
        createdBy
        createdDate
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
        collection {
          name
        }
      }
    }
  }
`;
