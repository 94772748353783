import { createSelector } from 'reselect';

const dictionariesState = state => state.dictionaries;

export const getModels = createSelector(
  dictionariesState,
  state => state.models
);

export const getPublishedModels = createSelector(getModels, models =>
  models.filter(model => model.published === true)
);

export const getModelsStatus = createSelector(
  dictionariesState,
  state => state.modelsStatus
);

export const getTaxonomies = createSelector(
  dictionariesState,
  state => state.taxonomies
);

export const getTaxonomiesStatus = createSelector(
  dictionariesState,
  state => state.taxonomiesStatus
);

export const isLoading = createSelector(
  getTaxonomiesStatus,
  taxonomiesStatus => taxonomiesStatus === 'loading'
);

export const hasLoadError = createSelector(
  getTaxonomiesStatus,
  taxonomiesStatus => taxonomiesStatus === 'error'
);

export const getTaxonomiesAsSelectOptions = createSelector(
  getTaxonomies,
  taxonomies => {
    return taxonomies.map(taxonomy => ({
      value: taxonomy.id,
      label: taxonomy.value,
      type: taxonomy.type
    }));
  }
);

export const getModelsAsSelectOptions = createSelector(
  getPublishedModels,
  models => {
    return models.map(model => ({
      value: model.id,
      label: model.name,
      taxonomyId: model.taxonomyId
    }));
  }
);

export const isModelsLoading = createSelector(
  dictionariesState,
  state => state.modelsStatus === 'loading'
);

export const hasModelsError = createSelector(
  dictionariesState,
  state => state.modelsStatus === 'error'
);
