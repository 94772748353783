import { gql, graphql } from "react-apollo";

export function logout(options = {}) {
  return graphql<void, any>(
    gql`
      mutation {
        logout
      }
    `,
    {
      name: "logout",
      options: {
        ...options,
      },
    }
  );
}
