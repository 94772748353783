import IAction from '../../types/IAction';
import actionTypes from '../actionTypes';

export const toggleErrorModal = (errorMessage: string): IAction => ({
  type: actionTypes.ERROR_MODAL_TOGGLE,
  payload: errorMessage
});

export const setCurrentPage = (page: string): IAction => ({
  type: actionTypes.SET_CURRENT_PAGE,
  payload: page
});

export const resetToast = (): IAction => ({
  type: actionTypes.RESET_TOAST
});

export const resetErrorToast = (): IAction => ({
  type: actionTypes.RESET_ERROR_TOAST
});

export const showToastSuccess = (message: string): IAction => ({
  type: actionTypes.SHOW_TOAST_SUCCESS,
  payload: message
});

export const resetApp = (): IAction => ({
  type: actionTypes.RESET_APP
});
