import { gql } from 'react-apollo';

export default gql`
  query GetDatasetItems(
    $datasetId: String!
    $allLabels: Boolean
    $page: Int! = 0
    $size: Int! = 20
  ) {
    getDatasetItems(datasetId: $datasetId, allLabels: $allLabels, page: $page, size: $size) {
      data {
        id
        text
        collectionId
        documentId
        labels {
          id
          score
          source {
            id
            type
          }
          taxonomyId
          taxonomyValueId
          value,
          startPosition,
          endPosition
        }
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
      }
    }
  }
`;
