import { gql } from 'react-apollo';

export default gql`
  mutation($dataset: DatasetInput!) {
    createDataset(dataset: $dataset) {
      id
      name
      companyId
      description
      taxonomy {
        value
      }
      suggestionModel {
        name
      }
      componentType
      type
      itemCount
    }
  }
`;
