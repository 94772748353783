import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import DatasetDistribution from '../../../../../_Datasets/components/dataset/distribution';
import ConfusionMetrics from './confusion-matrix';
import classnames from 'classnames';
import s from './styles.scss';
import get from 'lodash/get';

interface Props {
  t: (str: string) => string;
  metrics: any;
  distribution: any;
  compare: boolean;
  isGroundTruth: boolean
}

class ModelDetailMetrics extends Component<Props> {

  getTestClassificationReport = () => {
    const { metrics, isGroundTruth } = this.props;
    // look for "test-classification-report" first, then fallback to "classification-report"
    return isGroundTruth
      ? get(metrics, 'metrics.gt-classification-report', {})
      : get(metrics, 'metrics.test-classification-report', get(metrics, 'metrics.classification-report', {}))
  }

  getConfusionMatrixData = () => {
    const { metrics, isGroundTruth } = this.props;
    return isGroundTruth
      ? {
        confusionMatrix: get(metrics, 'metrics.gt-confusion-matrix', null),
        confusionMatrixLabels: get(metrics, 'metrics.gt-confusion-matrix-labels', null)
      }
      : {
        confusionMatrix: get(metrics, 'metrics.test-confusion-matrix', get(metrics, 'metrics.confusion-matrix', null)),
        confusionMatrixLabels: get(metrics, 'metrics.test-confusion-matrix-labels', get(metrics, 'metrics.confusion-matrix-labels', null))
      }
  }

  render() {
    const { t, metrics = {}, distribution, compare, isGroundTruth } = this.props;
    const {
      test_size,
      training_size,
      metrics: otherMetrics = {},
      ...otherInfo
    } = metrics;
    const innerMetrics = this.getTestClassificationReport();
    const total_size = test_size + training_size;
    const macroAvg = Object.entries(innerMetrics['macro avg'] || otherMetrics['macro avg'] || {});
    const microAvg = Object.entries(innerMetrics['micro avg'] || otherMetrics['micro avg'] || {});
    const weightedAvg = Object.entries(innerMetrics['weighted avg'] || otherMetrics['weighted avg'] || {});
    const avgs = [{macroAvg}, {microAvg}, {weightedAvg}]
    const testNTrainSplit = [
      {label: t('modelDetails.totalItems'), value: total_size },
      {label: t('modelDetails.testItems'), value: test_size },
      {label: t('modelDetails.trainItems'), value: training_size },
    ]

    otherMetrics['min-prediction-latency']
      ? testNTrainSplit.push(
        {label: t('modelDetails.minPredictionLatency'), value: otherMetrics['min-prediction-latency'] }
      ) : null;
    otherMetrics['max-prediction-latency']
      ? testNTrainSplit.push(
        {label: t('modelDetails.maxPedictionLatency'), value: otherMetrics['max-prediction-latency'] }
      )
      : null;
    otherMetrics['average-prediction-latency']
      ? testNTrainSplit.push(
        {label: t('modelDetails.averagePredictionLatency'), value: otherMetrics['average-prediction-latency'] }
      )
      : null;
    otherMetrics['train-accuracy']
      ? testNTrainSplit.push(
        {label: t('modelDetails.trainAccuracy'), value: otherMetrics['train-accuracy'] }
      )
      : null;
    otherMetrics['test-accuracy']
      ? testNTrainSplit.push(
        {label: t('modelDetails.testAccuracy'), value: otherMetrics['test-accuracy'] }
      )
      : null;
    const otherInfoArray = Object.entries(otherInfo || {});
    const areMetricsAvailable = Object.entries(metrics).length > 0;
    const distributionItems = get(distribution, 'items', []);
    const isDistributionAvailable = distributionItems.length > 0;
    const { confusionMatrix, confusionMatrixLabels } = this.getConfusionMatrixData();
    return (
      <section className={classnames(s['metrics'], {[s['compare']]: compare })}>
        <section className={s['metricsList_container']} >
          {!areMetricsAvailable && !isDistributionAvailable &&
            <section className={s['metricsList_item']} >
              <header className={s['metricsList__itemHeader']}> { t('modelDetails.noMetrics') } </header>
            </section>
          }
          {areMetricsAvailable &&
            <>
              { !compare &&
                confusionMatrix &&
                  <ConfusionMetrics
                    matrix={ confusionMatrix }
                    labels={ confusionMatrixLabels }
                  />
              }
              <section className={classnames(s['metricsList'], {[s['compare']]: compare } )}>
                <header className={`${s['metricsList__itemHeader']} ${s['metricsHeader']}`}>{t('modelDetails.metrics')}</header>
                {!isGroundTruth && (
                  <section className={s['metricsList_item']} >
                    <header className={s['metricsList__itemHeader']}> { t('modelDetails.trainingTestSplit') } </header>
                    <table>
                      <tbody>
                        {
                          testNTrainSplit.map(({label, value}) => {
                            return (<tr key={`test-split-${label}`}>
                            <td className={s['metricsList__itemLabel']}>{ label }</td>
                            <td className={s['metricsList__itemValue']}>{ value }</td>
                          </tr>)
                          })
                        }
                      </tbody>
                    </table>
                  </section>
                )}
                {
                  avgs.map((avg) => {
                    const [[avgName, avgValues]] = Object.entries(avg);
                    if(avgValues.length > 0 ) {
                      return (
                        <section className={s['metricsList_item']}  key={`${avgName}`}>
                          <header className={s['metricsList__itemHeader']}> { t(`modelDetails.${avgName}`) } </header>
                          <table>
                            <tbody>
                              {
                                avgValues && avgValues.map(([label, value]) => {
                                  return (<tr key={`${avgName}-${label}`}>
                                  <td className={s['metricsList__itemLabel']}>{ label }</td>
                                  <td className={s['metricsList__itemValue']}>{ value }</td>
                                </tr>)
                                })
                              }
                            </tbody>
                          </table>
                        </section>);
                    } else {
                      return null;
                    }
                  })
                }
                {!isGroundTruth && (
                  <section className={s['metricsList_item']} >
                    <header className={s['metricsList__itemHeader']}> { t('modelDetails.otherInfo') } </header>
                    <table>
                      <tbody>
                        {
                          otherInfoArray.map(([label, value]) => {
                            return (<tr key={`otherInfo-${label}`}>
                            <td className={s['metricsList__itemLabel']}>{ t(`modelDetails.${label}`) }</td>
                            <td className={s['metricsList__itemValue']}>{ value }</td>
                          </tr>)
                          })
                        }
                      </tbody>
                    </table>
                  </section>
                )}
              </section>
            </>
          }
          {isDistributionAvailable &&
            <section className={classnames(s['metricsList'], {[s['compare']]: compare })} >
              <DatasetDistribution distribution={distribution} withMetrics/>
            </section>
          }
      </section>
    </section>
    );
  }
}

export default translate('translations')(
  withStyles(s)(ModelDetailMetrics)
);
