import actionTypes from "../actionTypes";
import commonActionTypes from "../../_Home/actionTypes";
import IAction from "../../types/IAction";
import { UserContext } from "../../types-business/Profile";

interface State {
  userContext?: UserContext;
}

const initialState: State = {
  userContext: null,
};

export default function loginReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case actionTypes.SET_USER_CONTEXT: {
      const userContext: UserContext = action.payload;
      return {
        ...state,
        userContext,
      };
    }
    case commonActionTypes.RESET_APP: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState };
