import React, { Component } from 'react';
import Reform from '@franleplant/reform';
import { FormGroup, Label } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import classNames from 'classnames';

import SvgSpinner from '../../../../toolkit/svgSpinner';
import Message from '../../../../toolkit/message';
import RadioButton from '../../../../toolkit/radioButton';
import BaseInput from '../../../../toolkit/baseInput';
import BaseButton from '../../../../toolkit/baseButton';
import s from './styles.scss';
import { hasDataManagerRole } from '../../../../utils/helpers';

interface taxonomyOption {
  value: string;
  label: string;
  type?: string;
}

interface modelOption {
  value: string;
  label: string;
  taxonomyId?: string;
}

interface Props {
  className: string;
  taxonomies: taxonomyOption[];
  models: modelOption[];
  myCompanyId: number;
  isLoading: boolean;
  hasLoadError: boolean;
  userRoles: string[];
  handleCreateDataset: (dataset) => void;
  requestTaxonomies: () => void;
  requestModels: () => void;
  t: (str: string, vars?: any) => string;
}

interface State {
  fields: any;
  errors: any;
}

class DatasetNew extends Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  constructor(props) {
    super(props);
    this.state = {
      // allow consumer to fill in form via props
      fields: {
        name: props.name || '',
        type: props.type || '',
        componentType: props.componentType || '',
        taxonomyId: props.taxonomyId || '',
        suggestionModelId: props.suggestionModelId || '',
        description: props.description || ''
      },
      errors: {}
    };
  }

  validationRules = {
    name: { required: true, maxLength: 75 },
    type: { required: true },
    componentType: { required: true },
    taxonomyId: { required: true }
  };

  validationMessages = {
    required: () => this.props.t('validations.required'),
    default: (_ruleKey, _ruleValue, fieldName) =>
      `The value for field ${fieldName} is invalid`
  };

  componentWillMount() {
    this.props.requestTaxonomies();
    this.props.requestModels();
  }

  errorsFor(fieldName) {
    return this.re.mapFieldErrors(fieldName);
  }

  handleChangeFor = fieldName => {
    return event => {
      const value = event.target.value;

      // empty values should always be represented as empty string (no extra whitespace)
      const hasEmptyValue = value.trim() === '';
      const fieldValue = hasEmptyValue ? '' : value;

      this.setState(state => {
        const fields = { ...state.fields, [fieldName]: fieldValue };
        // reset the taxonomy field when datasetComponentType changes
        if (fieldName === 'componentType') {
          fields.taxonomyId = '';
        }
        if (fieldName === 'type') {
          fields.suggestionModelId = '';
        }
        return { ...state, error: {}, fields };
      });

      this.re.validateField(fieldName, value);
    };
  };

  getTaxonomyOptions() {
    const { taxonomies, t } = this.props;
    const {
      fields: { componentType }
    } = this.state;

    // Find the taxonomies relevant for the component type (sentence, clause, doc)
    const taxonomiesForType = taxonomies.filter(
      taxonomy => taxonomy.type === componentType
    );

    // remap the label to translate it to something user friendly (i.e., "NORM_TYPE" to "Sentence Type")
    const options = taxonomiesForType.map(tax => ({
      ...tax,
      label: t(`taxonomies.types.${tax.label}`)
    }));

    // if there are options to show, add in a placeholder option that provides instructions
    return options.length
      ? [
          { value: '', label: t('datasets.new.choose-taxonomy') },
          ...options
        ]
      : [];
  }

  getModelOptions() {
    const { models, t } = this.props;
    const {
      fields: { taxonomyId }
    } = this.state;

    const modelsForTaxonomy = models.filter(
      model => model.taxonomyId === taxonomyId
    );
    return modelsForTaxonomy.length
      ? [{ value: '', label: t('datasets.new.no-model') }, ...modelsForTaxonomy]
      : [{ value: '', label: t('datasets.new.no-matching-model') }];
  }

  shouldShowModelOptions() {
    const { fields } = this.state;
    return fields.type === 'REVIEW' && fields.taxonomyId !== '';
  }

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      const { handleCreateDataset, myCompanyId } = this.props;
      const { fields } = this.state;
      const dataset = {
        ...fields,
        companyId: myCompanyId,
        suggestionModelId: fields.suggestionModelId || null
      };
      handleCreateDataset.call(this, dataset);
    }
  };

  render() {
    const { t, hasLoadError, isLoading, userRoles } = this.props;
    const { fields } = this.state;
    const taxonomyOptions = this.getTaxonomyOptions();
    const modelOptions = this.getModelOptions();
    const isDataManager = hasDataManagerRole(userRoles);

    // Do not render form unless the user is a data manager
    if (!isDataManager) {
      return null;
    }

    // Show the spinner if the taxonomies/models are loading
    if (isLoading) {
      return (
        <div className={`${s.loading}`}>
          <SvgSpinner small={true} />
        </div>
      );
    }
    // Show an error message if the taxonomies/models load produced an error
    if (hasLoadError) {
      return <Message message={t('datasets.new.load-error')} type="error" />;
    }
    return (
      <form className={s.newDatasetForm} onSubmit={this.onSubmit}>
        <BaseInput
          name="name"
          type="text"
          label={t('datasets.new.dataset-name-label')}
          value={fields.name}
          placeholder={t('datasets.new.dataset-name-placeholder')}
          dataElmId="dataset_add_name"
          onChange={this.handleChangeFor('name')}
          errors={this.errorsFor('name')}
        />
        <FormGroup>
          <Label className={s.formSectionLabel}>{t('datasets.new.type')}</Label>

          <div className={classNames(s.radioButton)}>
            <RadioButton
              name="type"
              id="training_dataset_type"
              value="TRAINING"
              label={t('datasets.types.TRAINING')}
              dataElmId="dataset_type_radio"
              checked={fields.type === 'TRAINING'}
              onChange={this.handleChangeFor('type')}
              errors={this.errorsFor('type')}
            />
          </div>
          <div className={classNames(s.radioButton)}>
            <RadioButton
              name="type"
              id="review_dataset_type"
              value="REVIEW"
              label={t('datasets.types.REVIEW')}
              dataElmId="dataset_type_radio"
              checked={fields.type === 'REVIEW'}
              onChange={this.handleChangeFor('type')}
            />
          </div>
          <div className={classNames(s.radioButton)}>
            <RadioButton
              name="type"
              id="groundtruth_dataset_type"
              value="GROUND_TRUTH"
              label={t('datasets.types.GROUND_TRUTH')}
              dataElmId="dataset_type_radio"
              checked={fields.type === 'GROUND_TRUTH'}
              onChange={this.handleChangeFor('type')}
            />
          </div>
          {this.errorsFor('type').map((message, index) => (
            <div key={index} className={s.errorSpan}>
              {message}
            </div>
          ))}
        </FormGroup>

        <FormGroup>
          <Label className={s.formSectionLabel}>
            {t('datasets.new.componentType-selection')}
          </Label>

          <div className={classNames(s.radioButton)}>
            <RadioButton
              name="componentType"
              id="sentence_component_type"
              value="SENTENCE"
              checked={fields.componentType === 'SENTENCE'}
              label={t('datasets.new.sentence')}
              dataElmId="component_type_radio_sentence"
              onChange={this.handleChangeFor('componentType')}
            />
          </div>
          <div className={classNames(s.radioButton)}>
            <RadioButton
              name="componentType"
              id="clause_component_type"
              value="CLAUSE"
              checked={fields.componentType === 'CLAUSE'}
              label={t('datasets.new.clause')}
              dataElmId="component_type_radio_clause"
              onChange={this.handleChangeFor('componentType')}
            />
          </div>
          <div className={classNames(s.radioButton)}>
            <RadioButton
              name="componentType"
              id="document_component_type"
              value="DOCUMENT"
              checked={fields.componentType === 'DOCUMENT'}
              label={t('datasets.new.document')}
              dataElmId="component_type_radio_document"
              onChange={this.handleChangeFor('componentType')}
            />
          </div>
          {this.errorsFor('componentType').map((message, index) => (
            <div key={index} className={s.errorSpan}>
              {message}
            </div>
          ))}
        </FormGroup>
        <FormGroup>
          <Label className={s.formSectionLabel}>
            {t('datasets.new.taxonomy-label')}
          </Label>
          <BaseInput
            type="select"
            name="taxonomyId"
            dataElmId="new_dataset_taxonomy_select"
            value={fields.taxonomyId}
            onChange={this.handleChangeFor('taxonomyId')}
            options={taxonomyOptions}
            disabled={taxonomyOptions.length === 0}
          />
          {this.errorsFor('taxonomyId').map((message, index) => (
            <div key={index} className={s.errorSpan}>
              {message}
            </div>
          ))}
        </FormGroup>
        {this.shouldShowModelOptions() && (
          <FormGroup>
            <Label className={s.formSectionLabel}>
              {t('datasets.new.model-label')}
            </Label>
            <BaseInput
              type="select"
              name="suggestionModelId"
              dataElmId="new_dataset_model_select"
              value={fields.suggestionModelId}
              onChange={this.handleChangeFor('suggestionModelId')}
              options={modelOptions}
              disabled={modelOptions.length <= 1}
            />
          </FormGroup>
        )}
        <FormGroup className={s.description}>
          <Label className={s.formSectionLabel}>
            {t('datasets.new.description-label')}
          </Label>
          <BaseInput
            type="textarea"
            name="description"
            id="dataset-description"
            dataElmId="dataset_description_textarea"
            value={this.state.fields.description}
            onChange={this.handleChangeFor('description')}
          />
        </FormGroup>
        <BaseButton
          type="submit"
          onClick={this.onSubmit}
          className={'m-t-25'}
          color="primary"
          size="lg"
          block
          data-elm-id={'create_dataset_button'}>
          {t('datasets.new.create-button-label')}
        </BaseButton>
      </form>
    );
  }
}

export default translate('translations')(withStyles(s)(withRouter(DatasetNew)));
