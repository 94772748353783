import React from "react";
import { NavLink } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import classNames from "classnames";
import s from "./styles.scss";

interface Props {
  t: (str: string) => string;
  fullWidth?: boolean;
  compact?: boolean;
  bright?: boolean;
  borderTop?: boolean;
  className?: string;
  includeHelper?: boolean;
  navClassName?: string;
}

class Footer extends React.Component<Props> {
  static defaultProps = {
    fullWidth: true,
    compact: false,
    bright: false,
    borderTop: true,
    className: null,
    navClassName: null,
    includeHelper: true,
  };

  render() {
    return (
      <footer
        data-elm-id="layout_footer"
        className={classNames("w-100", this.props.className)}
      >
        <Content {...this.props} />
      </footer>
    );
  }
}

const Content = (props: Props) => {
  const {
    t,
    fullWidth,
    compact,
    bright,
    borderTop,
    includeHelper,
    navClassName,
  } = props as Props;
  return (
    <nav
      data-elm-id="layout_footer_nav"
      className={classNames(
        "d-flex align-items-center",
        s.nav,
        {
          [s.compact]: compact,
          [s.bright]: bright,
          [s.borderTop]: borderTop,
          "p-l-35 p-r-35": fullWidth,
          container: !fullWidth,
        },
        navClassName
      )}
    >
      <NavLink
        data-elm-id="layout_footer_nav_opt1"
        target="_blank"
        className={s.link}
        to="/"
      >
        {t("footer.option1")}
      </NavLink>

      <NavLink
        data-elm-id="layout_footer_nav_opt2"
        target="_blank"
        className={s.link}
        to="http://www.akorda.com/terms-of-use"
      >
        {t("footer.option2")}
      </NavLink>

      <NavLink
        data-elm-id="layout_footer_nav_opt3"
        target="_blank"
        className={classNames(s.link)}
        to="http://www.akorda.com/privacy-policy"
      >
        {t("footer.option3")}
      </NavLink>

      {includeHelper && <div className="flex-spring" />}
      {includeHelper && (
        <NavLink
          data-elm-id="layout_footer_nav_opt4"
          target="_blank"
          className={s.link}
          to="http://www.akorda.com/contact"
        >
          {t("globals.help-option")}
        </NavLink>
      )}
    </nav>
  );
};

export const FooterContent = withStyles(s)(Content);
export const TestableFooter = withStyles(s)(Footer);
export default withStyles(s)(Footer);
