import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import _ from 'lodash';

import s from './styles.scss';
import SvgSpinner from '../../../../../../toolkit/svgSpinner';
import Message from '../../../../../../toolkit/message';
import { Label } from '../../../../../../types-business/Label';

interface Props {
  labels: Label[];
  hasError: boolean;
  isLoading: boolean;
  t: (key: string) => string;
}

export class DocumentViewSentenceDetails extends Component<Props> {
  render() {
    const { labels, isLoading, hasError, t } = this.props;

    if (hasError) {
      return (
        <Message message={t('sentence-details.load-error')} type="error" />
      );
    }

    if (isLoading) {
      return (
        <div className={`${s.loading}`}>
          <SvgSpinner />
        </div>
      );
    }

    if (!labels.length) {
      return <Message message={t('sentence-details.no-content')} type="info" />;
    }
    return (
      <section className={s.sentenceLabels}>
        <ul className={s.labels}>
          {labels.map((label, index) => (
            <li
              key={index}
              data-testid={`label-${label.id}`}
              className={s.labelItem}>
              <div className={s.labelDetail}>
                <span className={s.labelValue}>{label.taxonomyValue.value}</span>
                <span className={s.valueCertainty}>{`${Math.ceil(
                  label.certainty * 100
                )}%`}</span>
              </div>
              <div className={s.metadata}>
                <span className={s.label}>Source</span>
                <span className={s.value}>{label.source}</span>
              </div>
              {label.probabilities.length && (
                <div>
                  <span className={s.label}>Probabilities</span>
                  <ul className={s.probabilities}>
                    {label.probabilities.map((probability, index) => (
                      <li key={index}>
                        <span className={s.label}>{probability.label}</span>
                        <span className={s.value}>{`${Math.ceil(
                          probability.score * 100
                        )}%`}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </section>
    );
  }
}

export default translate('translations')(
  withStyles(s)(DocumentViewSentenceDetails)
);
