import { gql } from 'react-apollo';

export default gql`
  query GetModels($page: Int! = 0, $size: Int! = 20, $sort: String, $where: String) {
    getModels(page: $page, size: $size, sort: $sort, where: $where) {
      data {
        id
        name
        datasetId
        taxonomyId
        pipelineId
        published
        pipelineName
        status
        taxonomy {
          value
        }
        dataset {
          id
          name
        }
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
      }
    }
  }
`;
