import { gql } from 'react-apollo';

export default gql`
  mutation($input: TrainModelInput!) {
    trainModel(input: $input) {
      data_bucket
      experiment_name
      metric_bucket
      result_bucket
    }
  }
`;
