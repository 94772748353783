import { toast } from "react-toastify";

export const notify = (message: string, toastOptions: any = {}): void => {
  toast(message, toastOptions);
};

export const notifySuccess = (
  message: string,
  toastOptions: any = {}
): void => {
  notify(message, { ...toastOptions, className: "toastSuccess" });
};

export const notifyFailure = (
  message: string,
  toastOptions: any = {}
): void => {
  notify(message, { ...toastOptions, className: "toastError" });
};

export const notifyWarning = (
  message: string,
  toastOptions: any = {}
): void => {
  notify(message, { ...toastOptions, className: "toastWarning" });
};

export default {
  notify,
  notifySuccess,
  notifyFailure,
  notifyWarning,
};
