exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._1rZYH{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#005899;height:80px;width:80px;border:1px solid #005899;border-radius:45px}._2btuL{font-size:24px;font-size:1.5rem}._1k6tO{text-align:center;font-weight:400;font-size:12.8px;font-size:.8rem}", ""]);

// exports
exports.locals = {
	"itemCountContainer": "_1rZYH",
	"itemCount": "_2btuL",
	"countLabel": "_1k6tO"
};