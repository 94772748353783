exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._18evd{border:1px dashed #c9ccd4;border-radius:4px;background-color:#f6f8fa}._18evd,.wXCI6{height:280px;width:348px}.wXCI6{display:table-cell;vertical-align:middle;text-align:center;position:relative}._1TJeY{display:inline-block}._16QsS{height:80px}._1guyI,._16QsS{width:64px;padding-bottom:20px}._1guyI{height:60px}._1Y7Gn{color:#1c1c43;margin-bottom:0}._1Y7Gn,._27cnx{height:20px;width:100%;font-family:Lato;font-size:14px;line-height:20px}._27cnx{color:#0174ca}._27cnx:hover{cursor:pointer;cursor:hand}._3CFey{position:absolute;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);bottom:10px}.pZUVr{font-size:60px;color:#0174ca;padding-bottom:10px}._2DHFP{color:#e3495d;padding-bottom:20px}._2DHFP,._3BeET{font-family:Lato-Bold,Montserrat-Light,HelveticaNeue-Light,Segoe UI,sans-serif;font-size:14px;line-height:20px}._3BeET{color:#1c1c43;margin-bottom:0;max-width:340px;word-wrap:break-word}._1SRnf{color:#8b91a3;font-family:Lato-Bold,Montserrat-Light,HelveticaNeue-Light,Segoe UI,sans-serif;font-size:14px;line-height:20px}._3t54_{width:60px;height:60px}", ""]);

// exports
exports.locals = {
	"containerStyle": "_18evd",
	"cn": "wXCI6",
	"inner": "_1TJeY",
	"imgDocument": "_16QsS",
	"imgUpload": "_1guyI",
	"dragLabel": "_1Y7Gn",
	"chooseFileLabel": "_27cnx",
	"bottomLabel": "_3CFey",
	"icon": "pZUVr",
	"errorSpan": "_2DHFP",
	"fileName": "_3BeET",
	"fileSize": "_1SRnf",
	"spinnerSizeStyle": "_3t54_"
};