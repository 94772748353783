import { gql } from 'react-apollo';

export default gql`
  mutation($datasetId: String!, $ids: [String]!) {
    deleteDatasetItems(datasetId: $datasetId, ids: $ids) {
      data {
        ids
      }
    }
  }
`;
