import React, { Component } from 'react';
import { translate } from 'react-i18next';
import ReactTable from 'react-table';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import columns from './_table/columns';
import Message from '../../../../../toolkit/message';
import s from './styles.scss';
import SvgSpinner from '../../../../../toolkit/svgSpinner';

interface Props {
  items: any[];
  isLoading: boolean;
  hasError: boolean;
  t: (key: string, options?: object) => string;
}

export class DatasetAddItemsPreview extends Component<Props> {
  render() {
    const { t, items, hasError, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className={`${s.loadingItems}`}>
          <SvgSpinner />
        </div>
      );
    }
    if (hasError) {
      return <Message message={t('errors.data-load')} type="error" />;
    }
    if (!items.length) {
      return (
        <div className={s.noItemsMessage}>
          <Message
            message={t('dataset-add-items.no-items-in-preview-message')}
            type="info"
          />
        </div>
      );
    }
    return (
      <div className={s.previewResults}>
        <div className={s.countInformation}>
          <h4>{t('dataset-add-items.foundCount', { count: items.length })}</h4>
        </div>
        <ReactTable
          className="default-akorda-table"
          columns={columns(this.props)}
          data={items}
          manual
          loading={isLoading}
          page={0}
          pageSize={items.length}
          pages={1}
          sortable={false}
          showPageJump={false}
          showPageSizeOptions={false}
          style={{ height: '100%' }}
          keyField="id"
        />
      </div>
    );
  }
}

export default translate('translations')(withStyles(s)(DatasetAddItemsPreview));
