import { gql } from 'react-apollo';

export default gql`
  query GetModel($modelId: String! = "") {
    getModel(modelId: $modelId) {
      componentType,
      dataset,
      datasetId,
      id,
      metrics,
      modelUri,
      name,
      parameters,
      pipelineId,
      pipelineName,
      publicDatasetId,
      published,
      resultsFolder,
      status,
      taxonomy,
      taxonomyId,
    }
  }
`;
