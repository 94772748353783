exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._8FQLM{position:absolute;top:0;right:0;left:0;bottom:0;background:hsla(0,0%,100%,.5);display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;z-index:1}._8FQLM ._67R0R{font-family:Lato,Montserrat-Light,HelveticaNeue-Light,Segoe UI,sans-serif;margin-top:10px;font-size:16px}._1aAnD{-webkit-animation:_3HmVD 1s linear infinite;animation:_3HmVD 1s linear infinite;width:60px}._1aAnD._3s8dM{width:24px}@-webkit-keyframes _3HmVD{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes _3HmVD{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"spinnerContainer": "_8FQLM",
	"message": "_67R0R",
	"spinner": "_1aAnD",
	"rotating": "_3HmVD",
	"small": "_3s8dM"
};