import React, { Component } from 'react';
import { History } from 'history';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import BaseButton from '../../../../../toolkit/baseButton';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { matchPath } from 'react-router-dom';

interface Props {
  location: Location;
  history: History;
  t: (str: string) => string;
}

class CollectionListToolbar extends Component<Props> {
  render() {
    const { history, t, location } = this.props;
    const isShowingNewCollectionSidebar = !!matchPath(location.pathname, {
      path: '/collections/new',
      exact: true
    });
    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem active>
              {t('collections.page-title')}
            </BreadcrumbItem>
          </Breadcrumb>
        </section>
        <section className="controls-zone">
          <BaseButton
            onClick={() => history.push('/collections/new')}
            size="sm"
            color="primary"
            disabled={isShowingNewCollectionSidebar}
            data-elm-id={'create_collection_button'}>
            {t('collections.new-collection-button-label')}
          </BaseButton>
        </section>
      </>
    );
  }
}

export default withRouter(translate('translations')(CollectionListToolbar));
