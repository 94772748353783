import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select, { components } from 'react-select';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ClassNames from 'classnames';
import s from './styles.scss';
import filter from './filter';
import Highlighter from 'react-highlight-words';
import memoize from 'memoize-one';

type SelectOption = {
  label: string;
  value: string | number;
};

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataElmId: string;
  onChange: (string: string) => void;
  onBlur: any;
  errors: Array<string>;
  options: SelectOption[];
  disabled: boolean;
  innerRef: (input: HTMLInputElement) => void;
  inputClass?: string;
  formGroupClass?: string;
  size?: string;
  highlightValue?: string;
  onInputChange: (text: string) => void;
  menuPlacment: string; // bottom, top, auto
}

type State = {
  filterText: string;
};

class BaseSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      filterText: ''
    };
  }

  static defaultProps = {
    onInputChange: () => {},
    errors: []
  };

  filterOptions = memoize(filter);

  private handleInputChange = (filterText: string): void => {
    const { onInputChange } = this.props;
    this.setState({ filterText });
    onInputChange(filterText);
  };

  private HighlightedOption = (props): JSX.Element => {
    const { Option } = components;
    const { children } = props;
    const { filterText } = this.state;

    return (
      <Option {...props}>
        <span className={s.optionLabel}>
          <Highlighter searchWords={[filterText]} textToHighlight={children} />
        </span>
      </Option>
    );
  };

  render() {
    // prettier-ignore
    const {
      id,
      name,
      value,
      label,
      type,
      placeholder,
      dataElmId,
      children,
      options,
      errors,
      innerRef,
      inputClass,
      formGroupClass,
      size,
      highlightValue,
      onInputChange,
      menuPlacment,
      ...rest
    } = this.props;

    const { filterText } = this.state;
    const filteredOptions = this.filterOptions(options, filterText);

    return (
      <FormGroup className={formGroupClass || s.formGroup}>
        {label && (
          <Label
            data-elm-id={`${dataElmId}_label`}
            className={s.label}
            for={id}>
            {label}
          </Label>
        )}
        <Select
          name={name}
          id={id}
          classNamePrefix='akorda-select'
          placeholder={placeholder}
          className={ClassNames(s.input, inputClass, s.inputSelect)}
          value={value}
          size={size || 'md'}
          data-elm-id={dataElmId}
          onChange={this.props.onChange}
          options={filteredOptions}
          ref={innerRef}
          filterOption={() => true}
          components={{ Option: this.HighlightedOption }}
          onInputChange={this.handleInputChange}
          menuPlacement={menuPlacment || 'bottom'}
          {...rest}
        />

        {errors &&
          errors.map((message, index) => {
            return (
              <span
                data-elm-id={`${dataElmId}_error_message`}
                key={index}
                className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(BaseSelect);
