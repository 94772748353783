exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._1LVzs{position:relative;display:inline-block;width:36px;height:20px}._1LVzs input{display:none}._2Dzcj{cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#c9ccd4;border-radius:20px}._2Dzcj,._2Dzcj:before{position:absolute;-webkit-transition:.4s;-o-transition:.4s;transition:.4s}._2Dzcj:before{content:\"\";height:16px;width:16px;left:2px;bottom:2px;background-color:#fff;border-radius:50%}input:checked+._2Dzcj{background-color:#51af46}input:focus+._2Dzcj{-webkit-box-shadow:0 0 1px #51af46;box-shadow:0 0 1px #51af46}input:checked+._2Dzcj:before{-webkit-transform:translateX(16px);-ms-transform:translateX(16px);transform:translateX(16px)}.qu4pn{margin-bottom:8px;margin-bottom:.5rem;padding-right:10px}", ""]);

// exports
exports.locals = {
	"switch": "_1LVzs",
	"slider": "_2Dzcj",
	"label": "qu4pn"
};