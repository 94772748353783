import { Link } from "react-router-dom";
import React from 'react';
import { ComponentType } from "../../review/item/component";
import ToggleableText from './toggleableText';

export default props => {
  const { t, datasetInfo: { componentType = '' } } = props;

  return [
    {
      Header: t('dataset-items.columns.label'),
      accessor: 'labels.value',
      style: { whiteSpace: 'normal', fontWeight: 'bold' },
      maxWidth: 200,
      Cell: row => {
        const datasetItemInfo = row.original
        const labels = datasetItemInfo.labels;
        return labels.length
          ? labels[0].value
          : t('dataset-add-items.no-label-found');
      }
    },
    {
      Header: t('dataset-items.columns.text'),
      accessor: 'text',
      Cell: row => {
        return <ToggleableText text={row.value} />
      }
    },{
      Header: '',
      maxWidth: 180,
      Cell: row => {
        const {collectionId = null, documentId = null} = row.original;
        const isDocument = componentType === ComponentType.Document
        if(isDocument) {return (
          <Link to={`/collections/${collectionId}/document/${documentId}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{float: 'right', marginRight: '2rem'}}
            >
            {t('dataset-items.openDoc')}
          </Link>

        )} else {
          return null;
        }
      },
      style: { whiteSpace: 'normal' }
    },
  ];
};
