import React, { Component } from 'react';
import Reform from '@franleplant/reform';
import Form from 'react-jsonschema-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import s from './styles.scss';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import BaseInput from '../../../../toolkit/baseInput';
import BaseSelect from '../../../../toolkit/baseSelect';
import BaseButton from '../../../../toolkit/baseButton';
import {
  TrainModelRequest,
  TrainingPipeline
} from '../../../../types-business/Model';
import get from 'lodash/get';

type Props = {
  isLoadingFormData: boolean;
  trainingPipelines: TrainingPipeline[];
  datasets: Array<{ value: string; label: string, taxonomyId: string }>;
  groundTruthDatasets: Array<{ value: string; label: string, taxonomyId: string }>;
  requestDatasetsForModel: () => void;
  requestTrainingPipelines: () => void;
  t: (key: string) => string;
  trainModel: (model: TrainModelRequest) => void;
};

type State = {
  fields: TrainModelRequest;
  errors: any;
};

class ModelNew extends Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        modelName: '',
        seed: undefined,
        trainSize: undefined,
        datasetId: '',
        groundTruthId: '',
        pipelineId: '',
        parameters: undefined,
        textSource: 'text'
      },
      errors: {}
    };
  }

  validationRules = {
    modelName: { required: true, maxLength: 75 },
    datasetId: { required: true },
    trainSize: {
      required: true,
      positiveFraction: value =>
        parseFloat(value) >= 1 || parseFloat(value) <= 0
    },
    seed: {
      required: true,
      positiveInteger: value =>
        !Number.isInteger(parseFloat(value)) || parseFloat(value) <= 0
    },
    pipelineId: { required: true },
    parameters: { required: true }
  };

  validationMessages = {
    required: () => this.props.t('validations.required'),
    default: (_ruleKey, _ruleValue, fieldName) =>
      `The value for field ${fieldName} is invalid`
  };

  componentDidMount() {
    this.props.requestTrainingPipelines();
    this.props.requestDatasetsForModel();
  }

  errorsFor(fieldName) {
    return this.re.mapFieldErrors(fieldName);
  }

  handleSelectionFor = fieldName => {
    return selectedOption => {
      const value = selectedOption.value;
      let groundTruthId = fieldName === 'groundTruthId' ? value : this.state.fields.groundTruthId;
      if (fieldName === 'datasetId') {
        groundTruthId = ''; // reset the ground truth dropdown when changing the dataset
      }
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value,
          groundTruthId,
          parameters:
            fieldName === 'pipelineId'
              ? this.getPipeline(value).defaultParameters
              : state.fields.parameters
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  handleChangeFor = fieldName => {
    return event => {
      const value = get(event, 'target.value', '').trim();
      // empty values should always be represented as empty string (no extra whitespace)
      const hasEmptyValue = value === '';
      let fieldValue = value;

      if (!hasEmptyValue && fieldName === 'trainSize') {
        fieldValue = parseFloat(fieldValue);
      }

      if (!hasEmptyValue && fieldName === 'seed') {
        fieldValue = parseInt(fieldValue);
      }

      this.setState(state => {
        const fields = { ...state.fields, [fieldName]: fieldValue };
        return { ...state, error: {}, fields };
      });

      this.re.validateField(fieldName, value);
    };
  };

  handlePipelineParametersFormChange = data => {
    this.setState(state => {
      const fields = { ...state.fields, parameters: data.formData };
      return { ...state, error: {}, fields };
    });
  };

  getSelectedDataset = () => {
    const selectedDatasetId = this.state.fields.datasetId;
    return this.props.datasets.find((dataset) => dataset.value === selectedDatasetId) || null;
  }

  getGroundTruthOptions = () => {
    const selectedDataset: any = this.getSelectedDataset();
    if (!selectedDataset) {
      return [];
    }
    // only show (as options) the ground truth datasets that share the same taxonomy as the selected data set.
    return this.props.groundTruthDatasets.filter((dataset) => dataset.taxonomyId === selectedDataset.taxonomyId);
  }

  getSelectedGroundTruth = () => {
    const selectedGroundTruthId = this.state.fields.groundTruthId;
    return this.props.groundTruthDatasets.find((dataset) => dataset.value === selectedGroundTruthId) || null;
  }

  getPipelineOptions = () => {
    const pipelines = Immutable.asMutable(this.props.trainingPipelines);
    return pipelines.map(pipe => ({
      value: pipe.id,
      label: pipe.name
    }));
  };

  getSelectedPipeline = () => {
    const { trainingPipelines } = this.props;
    const { fields } = this.state;
    return _.find(trainingPipelines, ['id', fields.pipelineId]);
  };

  getPipeline = (id: string) => {
    const { trainingPipelines } = this.props;
    return _.find(trainingPipelines, ['id', id]);
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      const { trainModel } = this.props;
      const { fields } = this.state;

      const trainParams: TrainModelRequest = {
        modelName: fields.modelName,
        datasetId: fields.datasetId,
        groundTruthId: fields.groundTruthId,
        seed: fields.seed,
        trainSize: fields.trainSize,
        pipelineId: fields.pipelineId,
        parameters: fields.parameters,
        textSource: 'text'
      };
      trainModel.call(this, trainParams);
    }
  };

  render() {
    const { t, isLoadingFormData } = this.props;
    const selectedPipeline = this.getSelectedPipeline();
    const { fields } = this.state;
    return (
      <div className={s.newModelForm}>
        <BaseInput
          name="modelName"
          type="text"
          label={t('models.new.name-label')}
          placeholder={t('models.new.name-placeholder')}
          dataElmId="model_name"
          onChange={this.handleChangeFor('modelName')}
          errors={this.errorsFor('modelName')}
        />

        <BaseSelect
          name="datasetId"
          label={t('models.new.dataset-label')}
          value={this.getSelectedDataset()}
          placeholder={t('models.new.dataset-placeholder')}
          options={this.props.datasets}
          onChange={this.handleSelectionFor('datasetId')}
          errors={this.errorsFor('datasetId')}
          isLoading={isLoadingFormData}
        />

        <BaseSelect
          name="groundTruthId"
          value={this.getSelectedGroundTruth()}
          label={t('models.new.ground-truth-label')}
          placeholder={t('models.new.ground-truth-placeholder')}
          options={this.getGroundTruthOptions()}
          onChange={this.handleSelectionFor('groundTruthId')}
          errors={this.errorsFor('groundTruthId')}
          isLoading={isLoadingFormData}
          isDisabled={!this.state.fields.datasetId}
        />

        <BaseInput
          name="trainSize"
          type="number"
          min="0"
          max="1"
          label={t('models.new.train-size-label')}
          placeholder={t('models.new.train-size-placeholder')}
          dataElmId="model_train_size"
          onChange={this.handleChangeFor('trainSize')}
          errors={this.errorsFor('trainSize')}
        />

        <BaseInput
          name="seed"
          type="number"
          min="1"
          label={t('models.new.seed-label')}
          placeholder={t('models.new.seed-placeholder')}
          dataElmId="model_seed"
          onChange={this.handleChangeFor('seed')}
          errors={this.errorsFor('seed')}
        />

        <BaseSelect
          name="pipelineId"
          label={t('models.new.pipeline-label')}
          placeholder={t('models.new.pipeline-placeholder')}
          options={this.getPipelineOptions()}
          onChange={this.handleSelectionFor('pipelineId')}
          errors={this.errorsFor('pipelineId')}
          menuPlacement="auto"
          isLoading={isLoadingFormData}
        />

        {!!selectedPipeline && (
          <>
            <span className={s.pipelineHeading}>
              {t('models.new.parameters')}
            </span>
            <Form
              schema={selectedPipeline.parameterSchema}
              formData={fields.parameters}
              onChange={this.handlePipelineParametersFormChange}
              liveValidate={true}
            />
          </>
        )}

        <BaseButton
          type="submit"
          onClick={this.handleSubmit}
          className={'m-t-25'}
          color="primary"
          size="lg"
          block
          data-elm-id={'create_model_button'}>
          {t('models.new.create-button-label')}
        </BaseButton>
      </div>
    );
  }
}

export default translate('translations')(withStyles(s)(ModelNew));
