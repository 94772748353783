import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './styles.scss';

interface Props {
  className: string;
  count: number;
  label: string;
}

export class Count extends Component<Props> {
  render() {
    const { count = 0, label = 'Items', className } = this.props;
    return (
      <div className={classNames("item-count", className)}>
        <div className={`${s.itemCountContainer} item-count-container`}>
          <span className={`${s.itemCount} count-value`}>{count}</span>
        </div>
        <div className={`${s.countLabel} count-label`}>{label}</div>
      </div>
    );
  }
}

export default withStyles(s)(Count);
