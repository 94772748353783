exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2Slm{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._2Slm span{font-style:italic}._3Q3t3{padding:10px 20px}._3Q3t3 ._13C9P{border-bottom:1px solid #c9ccd4;margin-bottom:10px}._3Q3t3 ._13C9P .ES4aP{margin:10px;height:25px;min-width:unset;font-size:10px;padding:5px 10px;float:right}._3Q3t3 ._2HQEf h3{font-weight:700;font-size:16px;font-size:1rem;text-transform:uppercase}._3Q3t3 ._2HQEf ul{padding:0;display:table;width:100%}._3Q3t3 ._2HQEf ul li{list-style:none;display:table-row}._3Q3t3 ._2HQEf ul li .nWJLu{display:table-cell;font-size:11.2px;font-size:.7rem;font-weight:700;text-transform:uppercase}._3Q3t3 ._2HQEf ul li .wSYxH{display:table-cell;font-size:12.8px;font-size:.8rem}._3Q3t3 ._1legJ{display:block;margin-bottom:10px}._3Q3t3 ._2vBhk{height:32px;min-width:160px;font-size:12px;text-transform:uppercase}.gMw1O{margin:20px;border-top:2px solid #e4e5e9}", ""]);

// exports
exports.locals = {
	"loading": "_2Slm",
	"datasetDetails": "_3Q3t3",
	"datasetDetailsReviewProgress": "_13C9P",
	"goToReviewButton": "ES4aP",
	"metadata": "_2HQEf",
	"metadataName": "nWJLu",
	"metadataValue": "wSYxH",
	"datasetButton": "_1legJ",
	"downloadButton": "_2vBhk",
	"separator": "gMw1O"
};