exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._35Cgq{height:42px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;text-align:center;border-top:1px solid #e2e2e2}._35Cgq button.disabled{border-color:#8b91a3;color:#8b91a3}._35Cgq ._2go__{color:#1c1c43}._35Cgq ._2go__ input{width:48px;width:3rem;margin:0 10px}._35Cgq ._2r-w7,._35Cgq ._3HshP{-ms-flex:0 0 auto;flex:0 0 auto}", ""]);

// exports
exports.locals = {
	"pagination": "_35Cgq",
	"info": "_2go__",
	"previous": "_3HshP",
	"next": "_2r-w7"
};