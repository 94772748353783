import { gql } from 'react-apollo';

export default gql`
  query GetCollections($page: Int! = 0, $size: Int! = 20) {
    getCollections(page: $page, size: $size) {
      data {
        id
        name
        documentCount
      }
      meta {
        totalItems
        pagination {
          page
          pageSize
          isFirstPage
          isLastPage
        }
      }
    }
  }
`;
