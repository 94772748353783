// The URL for the Akorda BFF server is baked into the webpack bundle
// @ts-ignore
const BASE_URL = process.env.AKORDA_BFF_URL || "";

export default {
  BASE_URL,
  DEAD_SESSION_MSG: 'INVALID_TOKEN',
  MISSING_SESSION_MSG: 'TOKEN_MISSING',
  ROLES: {
    ADMIN: 'ADMIN',
    DATA_MANAGER: "DATA_MANAGER",
    DATA_REVIEWER: "DATA_REVIEWER",
  },
  VERIFY_USER_PAGE_TYPE: 'activate',
  CHANGE_PASSWORD_PAGE_TYPE: 'change',
  STATUS_MAP: {
    ACTIVE: 'user.active-status-label',
    PENDING: 'user.pending-status-label',
    SUSPENDED: 'user.suspended-status-label'
  },
  SPACE_UNIT: 5,
  CONFIG_COLORS: {
    PROGRESS_BAR_SUCCESS: '#51AF46'
  },
  ERRORS: {
    NO_CONTENT: 'No Content'
  },
  AKORDA_WEBSITE: 'https://akorda.com/',
  ACTION_TOKEN_TYPES: {
    CLAUSE: 'CLAUSE'
  },
  VOID_DATA: {
    USER_CONTEXT: {
      company: {
        companyId: 0,
        companyName: 'companyName'
      },
      groups: [],
      userId: 0,
      email: 'email',
      profileId: 'profileId',
      firstName: 'firstName',
      lastName: 'lastName',
      latestLoginDate: 'latestLoginDate',
      latestPasswordChangeDate: 'latestPasswordChangeDate',
      verificationDate: 'verificationDate',
      createdDate: 'createdDate',
      status: 'status'
    }
  },
  TAXONOMY: {
    NORM_TYPE_ID: '1d751448-60f8-4822-96d8-5000caaa53ba',
    DOCUMENT_TYPE_ID: '8bbd0858-f3a5-428e-8a41-708dd23fd264',
    LEGAL_ENTITY_ID: 'c0d08427-c281-49de-a0f4-1e44c2158062',
    LEGAL_CONCEPT_ID: 'fa6bd26c-870d-431a-95bd-0cb9fbc0affe'
  },
  DATASET_PUBLISH_STATUS: {
    PUBLISHED: 'PUBLISHED',
    NOT_PUBLISHED: 'NOT_PUBLISHED',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED'
  },
  TOAST_DEFAULT_TIME: 5000,
  AUTO_SAVE_TIME: 5,
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
