exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2MpM4{-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._2MpM4,main{height:100%;display:-ms-flexbox;display:flex}._3_Y6C{-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:center;justify-content:center;height:auto}._3_Y6C>header{font-weight:700;padding:10px 0;border-bottom:1px dashed #8b91a3;margin-bottom:10px;width:90%;text-align:center}", ""]);

// exports
exports.locals = {
	"loading-overlay": "_2MpM4",
	"compare": "_3_Y6C"
};