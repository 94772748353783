import React, { Component } from 'react';
import { History } from 'history';
import { translate } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

interface Props {
  location: Location;
  history: History;
  model1: any;
  model2: any;
  t: (str: string) => string;
}

class ModelCompareToolbar extends Component<Props> {
  render() {
    const { t, model1, model2 } = this.props;
    return (
      <section className="crumb-zone">
        <Breadcrumb className="toolbar-breadcrumb">
          <BreadcrumbItem >
            <Link to="/models">{t('models.page-title')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{model1.name} vs {model2.name}</BreadcrumbItem>
        </Breadcrumb>
      </section>
    );
  }
}

export default translate('translations')(ModelCompareToolbar);
