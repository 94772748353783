exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2U-Ah{margin:30px}", ""]);

// exports
exports.locals = {
	"fileUpload": "_2U-Ah"
};