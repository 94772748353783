import React, { Component } from 'react';
import Reform from '@franleplant/reform';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  FormText
} from 'reactstrap';
import BaseInput from '../../../../toolkit/baseInput';
import BaseButton from '../../../../toolkit/baseButton';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { DatasetAddItemsPreviewContainer } from './preview';
import s from './styles.scss';
import { AddItemsQuery } from '../../../../types-business/Dataset';

interface Props {
  datasetId: string;
  hasItemsToAdd: boolean;
  onCancel: () => void;
  onAddItems: (datasetId: string, fields: any) => void;
  onPreview: (datasetId: string, fields: any) => void;
  t: (key: string) => string;
}

interface State {
  fields: AddItemsQuery;
  errors: any;
}

export class DatasetAddItemsModal extends Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        collectionId: '',
        documentId: '',
        restrictions: '',
        textRestrictions: '',
        limit: '',
        offset: ''
      },
      errors: {}
    };
  }

  validationRules = {};

  validationMessages = {
    required: () => this.props.t('validations.required'),
    default: (_ruleKey, _ruleValue, fieldName) =>
      `The value for field ${fieldName} is invalid`
  };

  handleChangeFor = fieldName => {
    return event => {
      const value = event.target.value;

      // empty values should always be represented as empty string (no extra whitespace)
      const hasEmptyValue = value.trim() === '';
      const fieldValue = hasEmptyValue ? '' : value;

      this.setState(state => {
        const fields = { ...state.fields, [fieldName]: fieldValue };
        return { ...state, error: {}, fields };
      });

      this.re.validateField(fieldName, value);
    };
  };

  handleCancel() {
    const { onCancel } = this.props;
    onCancel();
  }

  addItems = () => {
    const { onAddItems, datasetId } = this.props;
    const { fields } = this.state;
    onAddItems(datasetId, fields);
  };

  showPreview = () => {
    const { onPreview, datasetId } = this.props;
    const { fields } = this.state;
    onPreview(datasetId, fields);
  };

  hasMinimumSearchCriteria() {
    const { fields } = this.state;
    return (
      fields.restrictions ||
      fields.collectionId ||
      fields.documentId ||
      fields.textRestrictions
    );
  }

  render() {
    const { t, hasItemsToAdd } = this.props;
    return (
      <Modal
        backdrop="static"
        size="xl"
        isOpen={true}
        fade={true}
        toggle={this.handleCancel.bind(this)}
        className={s.addItemsModal}>
        <ModalHeader toggle={this.handleCancel.bind(this)}>
          {t('dataset-add-items.add-to-dataset')}
        </ModalHeader>
        <ModalBody>
          <>
            <div className={s.addItemsForm}>
              <div className={s.inlineForm}>
                <BaseInput
                  name="collectionId"
                  type="text"
                  label={t('dataset-add-items.collection-id')}
                  value={this.state.fields.collectionId}
                  disabled={this.state.fields.documentId !== ''}
                  placeholder={t('dataset-add-items.collection-id-placeholder')}
                  dataElmId="add_items_collectionid_textinput"
                  onChange={this.handleChangeFor('collectionId')}
                />
                <BaseInput
                  name="documentId"
                  type="text"
                  label={t('dataset-add-items.document-id')}
                  value={this.state.fields.documentId}
                  disabled={this.state.fields.collectionId !== ''}
                  placeholder={t('dataset-add-items.document-id-placeholder')}
                  dataElmId="add_items_documentid_textinput"
                  onChange={this.handleChangeFor('documentId')}
                />
              </div>
              <FormGroup className="add-items-restrictions">
                <div className={s.restrictions}>
                  <BaseInput
                    type="textarea"
                    name="restrictions"
                    label={t('dataset-add-items.field-restrictions')}
                    id="add-items-restrictions"
                    placeholder={t(
                      'dataset-add-items.field-restrictions-placeholder'
                    )}
                    dataElmId="add_items_restrictions_textarea"
                    value={this.state.fields.restrictions}
                    disabled={this.state.fields.textRestrictions !== ''}
                    onChange={this.handleChangeFor('restrictions')}
                  />
                  <BaseInput
                    type="textarea"
                    name="textRestrictions"
                    label={t('dataset-add-items.text-restrictions')}
                    id="add-items-text-restrictions"
                    placeholder={t(
                      'dataset-add-items.text-restrictions-placeholder'
                    )}
                    dataElmId="add_items_text_restrictions_textarea"
                    value={this.state.fields.textRestrictions}
                    disabled={this.state.fields.restrictions !== ''}
                    onChange={this.handleChangeFor('textRestrictions')}
                  />
                </div>
              </FormGroup>
              <div className={s.inlineForm}>
                <BaseInput
                  name="limit"
                  type="number"
                  label={t('dataset-add-items.limit')}
                  value={this.state.fields.limit}
                  placeholder={t('dataset-add-items.limit-placeholder')}
                  dataElmId="add_items_limit_textinput"
                  onChange={this.handleChangeFor('limit')}
                />
                <BaseInput
                  name="offset"
                  type="number"
                  label={t('dataset-add-items.offset')}
                  value={this.state.fields.offset}
                  placeholder={t('dataset-add-items.offset-placeholder')}
                  dataElmId="add_items_offset_textinput"
                  onChange={this.handleChangeFor('offset')}
                />
              </div>
              <BaseButton
                type="submit"
                onClick={this.showPreview}
                color="primary"
                outline
                size="sm"
                disabled={!this.hasMinimumSearchCriteria()}
                data-elm-id={'test_add_items_query_button'}>
                {t('dataset-add-items.preview')}
              </BaseButton>
              {!this.hasMinimumSearchCriteria() && (
                <FormText>
                  {t('dataset-add-items.no-restrictions-message')}
                </FormText>
              )}
            </div>
            <DatasetAddItemsPreviewContainer />
          </>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleCancel.bind(this)}>
            {t('globals.cancel-button')}
          </Button>
          <Button
            color="primary"
            disabled={!hasItemsToAdd}
            onClick={this.addItems}>
            {t('dataset-add-items.add-to-dataset')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withStyles(s)(DatasetAddItemsModal);
