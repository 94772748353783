import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { NavLink, withRouter } from "react-router-dom";
import { MutationFunc, compose } from "react-apollo";
import { Card, Alert } from "reactstrap";
import { translate } from "react-i18next";
import Reform from "@franleplant/reform";

import BaseInput from "../../../toolkit/baseInput";
import BaseButton from "../../../toolkit/baseButton";
import logoUrl from "../../../media/img/akorda-white-text@2x2.png";
import s from "./styles.scss";
import { login } from "../../mutations/authMutations";
import { Profile } from "../../../types-business/Profile";
import { getErrorMsgFromIQueryError, hasDataScienceApplicationAccess } from "../../../utils/helpers";
import SvgSpinner from "../../../toolkit/svgSpinner";

interface Props {
  t: (str: string, vars?: any) => string;
  history: any;
  login: MutationFunc<{
    login: {
      userId: number;
      profile: Profile;
    };
  }>;
  fields?: {
    email: string;
    password: string;
  };
  onRef: (ref: any) => void;
}

interface State {
  fields: {
    email: string;
    password: string;
  };
  errors?: any;
  message?: string;
  loading: boolean;
}

class Login extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  static defaultProps = {
    fields: {
      email: "",
      password: "",
    },
    onRef: () => {},
  };

  state = {
    fields: this.props.fields,
    errors: {},
    message: null,
    loading: false,
  };

  validationRules = {
    email: { required: true },
    password: { required: true },
  };

  validationMessages = {
    required: _ => this.props.t("validations.required"),
    email: _ => this.props.t("validations.valid-email"),
    minLength: ruleValue =>
      this.props.t("validations.min-string-length", { length: ruleValue }),
    maxLength: ruleValue =>
      this.props.t("validations.max-string-length", { length: ruleValue }),
    default: _ => this.props.t("validations.invalid"),
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value,
        };

        return { ...state, error: {}, fields, message: null };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onSubmit = async e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.setState({ loading: true, message: null });
      try {
        const response: any = await this.props.login({
          variables: {
            username: this.state.fields.email,
            password: this.state.fields.password,
          },
        });
        const roles = response.data.login.groups;
        const hasAccess = hasDataScienceApplicationAccess(roles);
        if (hasAccess) {
          this.props.history.push("/");
        } else {
          this.setState({
            loading: false,
            message: this.props.t('errors.no-access')
          });
        }
      } catch (e) {
        let errMsg = "errors.auth-error";

        switch (getErrorMsgFromIQueryError(e)) {
          case "Bad credentials":
            errMsg = "errors.bad-credentials";
            break;
          case "User account is locked":
            errMsg = "errors.account-locked";
            break;
        }

        this.setState({
          loading: false,
          message: this.props.t(errMsg),
        });
      }
    }
  };

  render() {
    const { t } = this.props;

    return (
      <section className="d-flex flex-column align-items-center">
        <img
          data-elm-id={"user_login_akorda_image"}
          className={`${s.logo} m-t-80 m-b-75`}
          src={logoUrl}
        />

        <Card className={`${s.formContainer} p-l-95 p-r-95 p-t-40 p-b-40`}>
          <h1
            className={`${s.title} m-b-30 text-center`}
            data-elm-id={"user_login_title"}
          >
            {t("login.title")}
          </h1>
          <form onSubmit={this.onSubmit}>
            <BaseInput
              label={t("globals.email-label")}
              name="email"
              type="email"
              id="userEmail"
              placeholder={t("login.email-placeholder")}
              value={this.state.fields.email}
              dataElmId={"user_login_email"}
              onChange={this.onChangeFactory("email")}
              errors={this.re.mapFieldErrors("email")}
            />

            <div className="position-relative">
              <BaseInput
                label={t("globals.password-label")}
                name="password"
                type="password"
                id="password"
                placeholder={t("login.password-placeholder")}
                value={this.state.fields.password}
                dataElmId={"user_login_password"}
                onChange={this.onChangeFactory("password")}
                errors={this.re.mapFieldErrors("password")}
              />
              <NavLink
                to="/forgot-password"
                data-elm-id={"user_login_forgot_password"}
                className={s.forgotLink}
              >
                {t("login.forgot-password")}
              </NavLink>
            </div>

            {this.state.message && (
              <Alert
                color="danger"
                data-elm-id={"user_login_error_message"}
                className={"m-b-0 m-t-40"}
              >
                {this.state.message}
              </Alert>
            )}

            <BaseButton
              type="submit"
              className="m-t-40"
              onClick={this.onSubmit}
              disabled={this.state.loading}
              size="lg"
              color="primary"
              block
              data-elm-id={"user_login_sign_in"}
            >
              {t("login.sign-in")}
            </BaseButton>
          </form>

          {!this.state.message && this.state.loading && <SvgSpinner modal />}
        </Card>
      </section>
    );
  }
}

export const TestableLoginPage = withStyles(s)(Login);
export default compose(login())(
  translate("translations")(withRouter(withStyles(s)(Login)))
);
