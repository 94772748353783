import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ClassNames from "classnames";
import s from "./styles.scss";

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataElmId: string;
  onChange: (string: string) => void;
  onBlur: any;
  errors: Array<string>;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  disabled: boolean;
  innerRef: (input: HTMLInputElement) => void;
  inputClass?: string;
  formGroupClass?: string;
  size?: string;
}

class BaseInput extends React.Component<Props, void> {
  static defaultProps = {
    errors: [],
  };

  render() {
    const {
      id,
      name,
      value,
      label,
      type,
      placeholder,
      dataElmId,
      children,
      options,
      errors,
      innerRef,
      inputClass,
      formGroupClass,
      size,
      ...rest
    } = this.props;

    return (
      <FormGroup className={formGroupClass || s.formGroup}>
        {label && (
          <Label
            data-elm-id={`${dataElmId}_label`}
            className={s.label}
            for={id}
          >
            {label}
          </Label>
        )}
        <Input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          className={ClassNames(s.input, inputClass, {
            [s.inputSelect]: type === "select",
          })}
          value={value}
          bsSize={size || "md"}
          data-elm-id={dataElmId}
          onChange={this.props.onChange}
          innerRef={innerRef}
          {...rest}
        >
          {type === "select"
            ? options &&
              options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                );
              })
            : null}
        </Input>
        {children}
        {errors &&
          errors.map((message, index) => {
            return (
              <span
                data-elm-id={`${dataElmId}_error_message`}
                key={index}
                className={s.errorSpan}
              >
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(BaseInput);
