import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import s from './styles.scss';
import Mark from 'mark.js';

interface Props {
  id: string;
  isActive: boolean;
  labels: any[];
  text: string;
  onFocus: (sentenceId: string) => void;
}

export class DocumentViewSentence extends Component<Props> {
  private sentenceTextRef;
  private markInstance;

  componentDidUpdate() {
    const { isActive } = this.props;

    if (isActive) {
      const node = ReactDOM.findDOMNode(this) as Element;
      node.scrollIntoView({ behavior: 'smooth' });
      this.highlightMatches();
    } else {
      if (this.markInstance) {
        this.markInstance.unmark();
      }
    }
  }

  highlightMatches(): void {
    const { labels, text } = this.props;
    this.markInstance = this.markInstance || new Mark(this.sentenceTextRef);

    function highlight(): void {
      if (labels && labels.length) {
        labels.forEach(label => {
          if (label.startPosition && label.endPosition) {
            const matchedText = text.substring(
              label.startPosition,
              label.endPosition
            );

            this.markInstance.mark(matchedText, { separateWordSearch: false });
          }
        });
      }
    }
    this.markInstance.unmark({
      done: highlight.bind(this)
    });
  }

  handleSentenceClick() {
    this.props.onFocus(this.props.id);
  }

  render() {
    const { text, isActive } = this.props;
    return (
      <div
        data-testid="document-view-sentence"
        ref={el => (this.sentenceTextRef = el)}
        onClick={this.handleSentenceClick.bind(this)}
        className={classNames(s.sentence, {
          [s.isActive]: isActive
        })}>
        {text}
      </div>
    );
  }
}

export default withStyles(s)(DocumentViewSentence);
