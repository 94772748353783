import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import {
  DATASET_ITEMS_REQUEST,
  DATASET_ITEMS_TOGGLE_SELECT_ALL,
  DATASET_ITEM_TOGGLE_SELECT,
  DATASET_DETAILS_REQUEST,
  DATASET_PUBLISH,
  DATASET_ITEMS_DELETE,
  DATASET_ADD_ITEMS_TEST_QUERY,
  DATASET_ADD_ITEMS,
  DATASET_CLEAR_ITEMS_TO_ADD,
  DATASET_REVIEW_ADD_LABEL,
  DATASET_REVIEW_REVERT_LABEL,
  DATASET_REVIEW_FOCUS_NEXT,
  DATASET_REVIEW_FOCUS_PREVIOUS,
  DATASET_REVIEW_FOCUS,
  DATASET_REVIEW_FOCUS_LAST_IN_PAGE,
  DATASET_DISTRIBUTION_REQUEST,
  DATASET_USER_REVIEW_PROGRESS_REQUEST,
  DATASET_SENTENCE_CONTEXT_REQUEST,
  DATASET_UNPUBLISH,
  DATASET_DOWNLOAD_FILE,
  DATASET_TOGGLE_LABEL_REVIEW_MODE
} from '../../actions/types';
import _ from 'lodash';

const intialState = {
  // dataset items
  items: [],
  // fetch status for items: loading, error, completed
  itemsStatus: null,
  // the ids of the selected items
  selectedItems: [],
  // whether select all is active
  hasSelectAll: false,
  // page the user is currently on (zero-based index)
  currentPage: 0,
  // number of items to display on each page
  pageSize: 20,
  // total items
  totalItems: 0,
  // whether we are on the last page
  isLastPage: true,
  // whether we are on the first page
  isFirstPage: true,
  // total number of pages
  totalPages: 0,
  // whether a transaction (create/delete, e.g.) is underway
  isTransactionUnderway: false,
  // details (metadata) for dataset
  info: null,
  // fetch status of dataset info
  infoStatus: null,
  // items to add to dataset
  itemsToAdd: [],
  // status of query finding items that match search: loading, error, completed
  itemsToAddStatus: null,
  // ids of components whose suggestions have been accepted
  acceptedSuggestions: [],
  // ids of components whose suggestions have been rejected
  rejectedSuggestions: [],
  // the id of the item in focus
  focusItemId: null,
  // recent manual selections
  recentSelections: [],
  // whether an add label operation is underway
  isAddLabelUnderway: false,
  // distribution info for training datasets
  distribution: [],
  // distribution load status: loading, error, completed
  distributionStatus: null,
  // sort option for distribution
  distributionSortBy: 'count',
  // my (user) reviewed items
  reviewedItemCount: null,
  // status of fetch of user review information: loading, error, completed
  userReviewProgressStatus: null,
  // information about the context (preceding and succeeding lines) to a sentence
  sentencesContext: {},
  // whether to use the label review mode for datasets (where the user sees all labels, not just hers)
  isInLabelReviewMode: false
};

export default handleActions(
  {
    [DATASET_ITEMS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('itemsStatus', 'loading'),
        failure: s => s.set('itemsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getDatasetItems;
          return s.merge({
            itemsStatus: 'completed',
            items: result.data,
            totalItems: result.meta.totalItems,
            pageSize: result.meta.pagination.pageSize,
            currentPage: result.meta.pagination.page,
            isFirstPage: result.meta.pagination.isFirstPage,
            isLastPage: result.meta.pagination.isLastPage,
            focusItemId: result.data.length ? result.data[0].id : null
          });
        }
      });
    },
    [DATASET_ADD_ITEMS_TEST_QUERY]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('itemsToAddStatus', 'loading'),
        failure: s => s.set('itemsToAddStatus', 'error'),
        success: s => {
          const result = action.payload.data.getAddItemsQueryResults;
          return s.merge({
            itemsToAddStatus: 'completed',
            itemsToAdd: result.data
          });
        }
      });
    },
    [DATASET_ADD_ITEMS]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s =>
          s.merge({
            isTransactionUnderway: false,
            itemsToAdd: [],
            itemsToAddStatus: null
          })
      });
    },
    [DATASET_DETAILS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s =>
          s.merge({
            acceptedSuggestions: [],
            rejectedSuggestions: [],
            infoStatus: 'loading',
            info: null,
            recentSelections: []
          }),
        failure: s => s.set('infoStatus', 'error'),
        success: s => {
          const result = action.payload.data.getDataset;
          return s.merge({
            infoStatus: 'completed',
            info: result.data
          });
        }
      });
    },
    [DATASET_DISTRIBUTION_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('distributionStatus', 'loading'),
        failure: s => s.set('distributionStatus', 'error'),
        success: s => {
          const { data, datasetId } = action.payload;
          const result = data.getDatasetDistribution;
          return s.merge({
            distributionStatus: 'completed',
            distribution: {
              ...s.distribution,
              [datasetId]: result.data
            }
          });
        }
      });
    },
    [DATASET_PUBLISH]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          const result = action.payload.data.publishDataset;
          return s.merge({
            isTransactionUnderway: false,
            info: {
              ...s.info,
              ...result.data
            }
          });
        }
      });
    },
    [DATASET_UNPUBLISH]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          const result = action.payload.data.unpublishDataset;
          return s.merge({
            isTransactionUnderway: false,
            info: {
              ...s.info,
              ...result.data
            }
          });
        }
      });
    },
    [DATASET_DOWNLOAD_FILE]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => s.set('isTransactionUnderway', false)
      });
    },
    [DATASET_ITEMS_DELETE]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          const deletedIds = action.payload.data.deleteDatasetItems.data.ids;
          const updatedItems = s.items.filter(
            item => !deletedIds.includes(item.id)
          );
          return s.merge({
            isTransactionUnderway: false,
            items: updatedItems,
            hasSelectAll: false, // if this was true, it needs to be false after deletion
            selectedItems: []
          });
        }
      });
    },
    [DATASET_ITEM_TOGGLE_SELECT]: (state, { payload }) => {
      const { selectedItems } = state;
      const updatedItems = selectedItems.includes(payload)
        ? _.without(selectedItems, payload)
        : [...selectedItems, payload];

      return state.set('selectedItems', updatedItems);
    },
    [DATASET_ITEMS_TOGGLE_SELECT_ALL]: state => {
      const { items, hasSelectAll } = state;
      let selections;
      if (!hasSelectAll) {
        selections = items.map(item => item.id);
      } else {
        selections = [];
      }
      return state.merge({
        selectedItems: selections,
        hasSelectAll: !hasSelectAll
      });
    },
    [DATASET_CLEAR_ITEMS_TO_ADD]: state => {
      return state.merge({
        itemsToAdd: [],
        itemsToAddStatus: null
      });
    },
    [DATASET_REVIEW_ADD_LABEL]: (state, action) => {
      return handle(state, action, {
        start: s =>
          s.merge({
            focusItemId: action.payload.componentId,
            isAddLabelUnderway: true
          }),
        failure: s => s.set('isAddLabelUnderway', false),
        success: s => {
          const result = action.payload.data.addLabel.data;
          return s.merge({
            isAddLabelUnderway: false,
            recentSelections: _.uniq([
              {
                label: result.taxonomyValue,
                value: result.taxonomyValueId
              },
              ...s.recentSelections
            ]),
            // update the items with the response labels
            items: s.items.map(item => {
              if (item.id === result.componentId) {
                return item.set('labels', [
                  ...item.labels,
                  {
                    id: result.labelId,
                    score: result.score,
                    value: result.taxonomyValue,
                    taxonomyValueId: result.taxonomyValueId,
                    source: {
                      id: result.createdBy || 'me',
                      type: 'MANUAL'
                    },
                    startPosition: result.startPosition,
                    endPosition: result.endPosition
                  }
                ]);
              } else {
                return item;
              }
            })
          });
        }
      });
    },
    [DATASET_REVIEW_REVERT_LABEL]: (state, action) => {
      return handle(state, action, {
        success: s => {
          const labelId = action.payload.data.revertLabel.labelId;
          return s.merge({
            items: s.items.map(item => {
              return item.set(
                'labels',
                item.labels.filter(label => label.id !== labelId)
              );
            })
          });
        }
      });
    },
    [DATASET_USER_REVIEW_PROGRESS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => {
          return s.set('userReviewProgressStatus', 'loading');
        },
        failure: s => {
          return s.set('userReviewProgressStatus', 'error');
        },
        success: s => {
          const result = action.payload.data.getUserReviewProgress.data;
          return s.merge({
            userReviewProgressStatus: 'completed',
            reviewedItemCount: result.reviewed
          });
        }
      });
    },
    [DATASET_REVIEW_FOCUS]: (state, action) => {
      if (action.payload) {
        return state.set('focusItemId', action.payload);
      }
      return state;
    },
    [DATASET_REVIEW_FOCUS_NEXT]: state => {
      const { items, focusItemId } = state;
      const currentFocusIndex = _.findIndex(items, { id: focusItemId });
      if (currentFocusIndex >= 0 && currentFocusIndex + 1 < items.length) {
        return state.set('focusItemId', items[currentFocusIndex + 1].id);
      } else {
        return state;
      }
    },
    [DATASET_REVIEW_FOCUS_PREVIOUS]: state => {
      const { items, focusItemId } = state;
      const currentFocusIndex = _.findIndex(items, { id: focusItemId });
      if (currentFocusIndex > 0 && currentFocusIndex - 1 >= 0) {
        return state.set('focusItemId', items[currentFocusIndex - 1].id);
      } else {
        return state;
      }
    },
    [DATASET_REVIEW_FOCUS_LAST_IN_PAGE]: state => {
      const { items } = state;
      if (items.length) {
        return state.set('focusItemId', items[items.length - 1].id);
      } else {
        return state;
      }
    },
    [DATASET_TOGGLE_LABEL_REVIEW_MODE]: state => {
      return state.set('isInLabelReviewMode', !state.isInLabelReviewMode);
    },
    [DATASET_SENTENCE_CONTEXT_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => {
          const { sentenceId } = action.payload;
          return s.merge({
            sentencesContext: {
              ...s.sentencesContext,
              [sentenceId]: {
                sentenceContextStatus: 'loading'
              }
            }
          });
        },
        success: s => {
          const {
            predecessorGroups,
            sentenceId,
            successorGroups
          } = action.payload.data;
          return s.merge({
            sentencesContext: {
              ...s.sentencesContext,
              [sentenceId]: {
                predecessorGroups,
                successorGroups,
                sentenceContextStatus: 'complete'
              }
            }
          });
        }
      });
    }
  },
  Immutable.from(intialState)
);
