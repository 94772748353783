exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1oflU{min-height:70px;padding-right:15px}", ""]);

// exports
exports.locals = {
	"reviewProgress": "_1oflU"
};