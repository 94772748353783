import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import {
  DOCUMENT_REQUEST,
  DOCUMENT_VIEW_LABEL_SELECT,
  DOCUMENT_VIEW_SENTENCE_LABELS_REQUEST,
  DOCUMENT_VIEW_CLEAR_SELECTED_LABEL,
  DOCUMENT_LABELS_REQUEST
} from '../../actions/types';
import _ from 'lodash';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

const intialState = {
  documentStatus: null,
  documentName: null,
  sentences: [],
  collectionName: null,
  labelMap: null,
  selectedLabelValue: null,
  selectedLabelSentenceIds: [],
  currentSelectedLabelSentenceId: null,
  focusSentenceId: null,
  focusSentenceLabels: [],
  focusSentenceLabelsStatus: null
};

const createLabelMap = labels => {
  const taxonomies = labels.reduce(
    (acc, labelList) => {
      const taxonomies = ['legalConcepts', 'legalEntities', 'sentenceNorms'];
      taxonomies.forEach(key => {
        const values = acc[key].values;
        const labelInfo = labelList[key];
        labelInfo.forEach(label => {
          const value = get(label, 'taxonomyValue.value', 'unknown');
          if (!values[value]) {
            values[value] = [];
          }
          const sentences = values[value];
          sentences.push(label.sentenceId);
          values[value] = uniq(sentences);
        });
      });
      return acc;
    },
    {
      legalConcepts: {
        taxonomyId: 'legalConcepts',
        values: {}
      },
      legalEntities: {
        taxonomyId: 'legalEntities',
        values: {}
      },
      sentenceNorms: {
        taxonomyId: 'sentenceNorms',
        values: {}
      }
    }
  );
  return taxonomies;
};

export default handleActions(
  {
    [DOCUMENT_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s =>
          s.merge({
            documentStatus: 'loading',
            selectedLabelValue: null,
            selectedLabelSentenceIds: [],
            currentSelectedLabelSentenceId: null,
            focusSentenceLabels: [],
            focusSentenceId: null
          }),
        failure: s => s.set('documentStatus', 'error'),
        success: s => {
          return s.merge({
            documentStatus: 'completed',
            sentences: get(action, 'payload.data.getDocument.data.sentences', []),
            documentName: get(action, 'payload.data.getDocument.data.name', ''),
            collectionName: get(action, 'payload.data.getDocument.meta.collection.name', '')
          });
        }
      });
    },
    [DOCUMENT_LABELS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s =>
          s.merge({ labelMap: null }),
        success: s => {
          const labels = get(action, 'payload.data.getDocumentLabels', []);
          const labelMap = createLabelMap(labels);
          return s.merge({ labelMap });
        }
      });
    },
    [DOCUMENT_VIEW_LABEL_SELECT]: (state, { payload }) => {
      const { taxonomyId, value } = payload;
      const taxonomy = state.labelMap[taxonomyId];
      const sentences = taxonomy.values[value];
      const [firstSentenceId] = sentences;
      return state.merge({
        selectedLabelValue: value,
        selectedLabelSentenceIds: sentences,
        focusSentenceId: firstSentenceId
      });
    },
    [DOCUMENT_VIEW_CLEAR_SELECTED_LABEL]: state => {
      return state.merge({
        selectedLabelValue: null,
        selectedLabelSentenceIds: []
      });
    },
    [DOCUMENT_VIEW_SENTENCE_LABELS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s =>
          s.merge({
            focusSentenceId: action.payload,
            focusSentenceLabelsStatus: 'loading',
            focusSentenceLabels: []
          }),
        failure: s => s.set('focusSentenceLabelsStatus', 'error'),
        success: s => {
          return s.merge({
            focusSentenceLabelsStatus: 'completed',
            focusSentenceLabels: get(action, 'payload.data.getSentenceLabels.data', [])
          });
        }
      });
    }
  },
  Immutable.from(intialState)
);
