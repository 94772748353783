import { gql } from 'react-apollo';

export default gql`
  query GetDocumentLabels($documentId: ID!) {
    getDocumentLabels(documentId: $documentId) {
      legalConcepts {
        sentenceId,
        taxonomyValue {
          value
        }
        source
        startPosition
        endPosition
        score
        certainty
      }
      legalEntities {
        sentenceId
        taxonomyValue {
          value
        }
        source
        startPosition
        endPosition
        score
        certainty
      }
      sentenceNorms {
        sentenceId
        taxonomyValue {
          value
        }
        source
        startPosition
        endPosition
        score
        certainty
      }
    }
  }
`;
