import React, { Component } from 'react';
import classNames from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './styles.scss';
import { translate } from 'react-i18next';

interface Props {
  t: (key: string) => string;
  status: 'ACCEPTED' | 'REJECTED' | 'UNREVIEWED';
  onClick: () => void;
}

interface State {
  hovered: boolean;
}

const UNREVIEWED_ICON = 'help_outline';
const ACCEPTED_ICON = 'check_circle_outline';
const REJECTED_ICON = 'block';

export class DatasetReviewItemStatus extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  isReviewed(): boolean {
    return this.props.status === 'ACCEPTED' || this.props.status === 'REJECTED';
  }

  renderReviewedStatus(status) {
    const { onClick, t } = this.props;
    const { hovered } = this.state;
    const iconName = status === 'ACCEPTED' ? ACCEPTED_ICON : REJECTED_ICON;

    return (
      <div className={classNames('status-icon')}>
        <i
          onClick={onClick.bind(this)}
          onMouseOver={() => this.setState({ hovered: true })}
          onMouseOut={() => this.setState({ hovered: false })}
          onMouseLeave={() => this.setState({ hovered: false })}
          className={classNames('material-icons', s[status.toLowerCase()], {
            [s.revert]: hovered
          })}>
          {hovered ? UNREVIEWED_ICON : iconName}
        </i>
        {hovered && (
          <span className={s.revertText}>
            {t('dataset-review.revert-label')}
          </span>
        )}
      </div>
    );
  }

  renderUnreviewedStatus() {
    return (
      <div className={classNames('status-icon')}>
        <i className={classNames('material-icons', s.unknown)}>
          {UNREVIEWED_ICON}
        </i>
      </div>
    );
  }

  render() {
    return (
      <div className={s.status}>
        {this.isReviewed()
          ? this.renderReviewedStatus(this.props.status)
          : this.renderUnreviewedStatus()}
      </div>
    );
  }
}

export default translate('translations')(
  withStyles(s)(DatasetReviewItemStatus)
);
