import IAction from '../../../types/IAction';
import {
  MODELS_REQUEST,
  MODELS_TRAINING_PIPELINES_REQUEST,
  MODELS_DATASETS_REQUEST,
  MODEL_TRAIN,
  MODEL_DETAIL_REQUEST,
  MODEL_TOGGLE_SELECT,
  MODEL_CLEAN_SELECT,
  MODEL_TAXONOMY_VALUES_REQUEST
} from '../types';
import {
  fetchModels,
  fetchTrainingPipelines,
  fetchDatasetsForModel,
  trainModel as train,
  fetchModel
} from '../../api';
import { NOOP_CALLBACKS } from './util';
import { TrainModelRequest } from '../../../types-business/Model';
import { fetchTaxonomyValues } from '../../api';

export function requestModels(page: number = 0, size: number = 20, sort: string): IAction {
  return {
    type: MODELS_REQUEST,
    promise: fetchModels(page, size, sort)
  };
}

export function requestModel(modelId: String, callbacks = NOOP_CALLBACKS): IAction {

  const { onSuccess, onFailure } = callbacks;
  return {
    type: MODEL_DETAIL_REQUEST,
    promise: fetchModel(modelId),
    meta: { onSuccess, onFailure }
  };
}
export function requestTaxonomyValues(callbacks = NOOP_CALLBACKS):IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: MODEL_TAXONOMY_VALUES_REQUEST,
    promise: fetchTaxonomyValues(),
    meta: { onSuccess, onFailure }
  };
}
export function requestTrainingPipelines(): IAction {
  return {
    type: MODELS_TRAINING_PIPELINES_REQUEST,
    promise: fetchTrainingPipelines()
  };
}

export function requestDatasetsForModel(): IAction {
  return {
    type: MODELS_DATASETS_REQUEST,
    promise: fetchDatasetsForModel()
  };
}

export function trainModel(
  model: TrainModelRequest,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: MODEL_TRAIN,
    promise: train(model),
    meta: { onSuccess, onFailure }
  };
}
export function toggleModelSelection(
  id: string,
  taxonomyId: string
): IAction {
  return {
    type: MODEL_TOGGLE_SELECT,
    payload: {
      id,
      taxonomyId
    }
  };
}
export function cleanModelSelection(): IAction {
  return {
    type: MODEL_CLEAN_SELECT
  };
}

