import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";

interface State {
  currentPage: string;
  redirectToLogin: boolean;
}

const initialState: State = {
  currentPage: null,
  redirectToLogin: false,
};

export default function navigationReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case actionTypes.RESET_APP: {
      return initialState;
    }
    default: {
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status === 401
      ) {
        return {
          ...state,
          redirectToLogin: true,
        };
      }
      return {
        ...state,
        redirectToLogin: false,
      };
    }
  }
}

export { initialState };
