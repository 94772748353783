import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import { Badge } from 'reactstrap';

import s from './styles.scss';
import { Distribution } from '../../../../types-business/Dataset';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import Message from '../../../../toolkit/message';

type Props = {
  distribution: Distribution;
  withMetrics: boolean;
  hasError: boolean;
  isLoading: boolean;
  t: (key: string) => string;
};
const areMetricsAvailable = (item) => {
  return item.recall !== undefined &&
    item.support !== undefined &&
    item.precision !== undefined &&
    item["f1-score"] !== undefined
}
class DatasetDistribution extends Component<Props> {
  renderLoading() {
    return (
      <div
        className="d-flex h-100 justify-content-center w-100 align-items-center"
        data-testid="distribution-is-loading">
        <SvgSpinner />
      </div>
    );
  }

  renderError() {
    const { t } = this.props;
    return (
      <Message
        message={t('dataset-info.distribution.data-load-error')}
        type="error"
        testId="distribution-load-error"
      />
    );
  }

  renderGraph() {
    const { t, distribution, withMetrics } = this.props;
    const { totalCount, items } = distribution;
    return (
      <div className={`${s.distribution}`} data-testid="dataset-distribution">
        <h3 data-testid="distribution-title">
          {t('dataset-info.distribution.title')}
        </h3>
        <ul className={s.barChart} data-testid="distribution-chart">
          {items.map(item => (
            <li
              key={item.taxonomyValueId}
              className={`${s.bar} ${withMetrics? s.withMetrics : '' }`}
              data-testid={`distribution-item-${item.taxonomyValueId}`}>
              <div
                className={s.barContent}
                style={{
                  width: `${totalCount ? (item.count / totalCount) * 100 : 1}%`
                }}>
                {item.taxonomyValue}{' '}
                <Badge className={s.itemCount} color="primary">
                  {item.count}
                </Badge>
              </div>
              { areMetricsAvailable(item) &&
                <article className={s.metrics}>
                  <section className={s.metricItem}>
                    <span>{ t('dataset-info.recall') }:</span>
                    {item.recall.toFixed(2)}
                  </section>
                  <section className={s.metricItem}>
                    <span>{ t('dataset-info.support') }:</span>
                    {item.support.toFixed(0)}
                  </section>
                  {
                    !([null, undefined].includes(item['training-support'])) &&
                    <section className={s.metricItem}>
                      <span>{ t('dataset-info.trainingsupport') }:</span>
                      {item['training-support'].toFixed(0)}
                    </section>
                  }
                  <section className={s.metricItem}>
                    <span>{ t('dataset-info.f1Score') }:</span>
                    {item["f1-score"].toFixed(2)}
                  </section>
                  <section className={s.metricItem}>
                    <span>{ t('dataset-info.precision') }:</span>
                    {item.precision.toFixed(2)}
                  </section>
                </article>
              }
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { hasError, isLoading } = this.props;
    if (isLoading) {
      return this.renderLoading();
    } else if (hasError) {
      return this.renderError();
    } else {
      return this.renderGraph();
    }
  }
}

export default translate('translations')(withStyles(s)(DatasetDistribution));
