import {
  getDatasetsForModel,
  getModels,
  getTrainingPipelines
} from './queries';
import { trainModel as train } from './mutations';
import apolloClient from '../../utils/apolloClient';
import { TrainModelRequest } from '../../types-business/Model';
import Axios from 'axios';
import constants from '../../utils/constants';

const fetchPolicy = 'network-only';

export const fetchDatasetsForModel = (): Promise<object> => {
  return apolloClient.query({
    query: getDatasetsForModel,
    fetchPolicy
  });
};

export const fetchModels = (
  page: number = 0,
  size: number = 20,
  sort: string
): Promise<object> => {
  const variables = { page, size, sort };
  return apolloClient.query({ query: getModels, variables, fetchPolicy });
};

export const fetchModel = (
  modelId: String
): Promise<object> => {
  return Axios.get(`${constants.BASE_URL}/api/models/${modelId}`,{
    withCredentials: true
  });
};

export const fetchTrainingPipelines = (): Promise<object> => {
  return apolloClient.query({ query: getTrainingPipelines, fetchPolicy });
};

export const trainModel = (model: TrainModelRequest): Promise<object> => {
  const { parameters, ...trainModelOptions } = model;
  const input = {
    ...trainModelOptions,
    parameters: JSON.stringify(parameters)
  };
  const variables = {
    input
  };

  const mutation = train;
  return apolloClient.mutate({ mutation, variables });
};
export const fetchTaxonomyValues = (): Promise<object> => {
  return Axios.get(`${constants.BASE_URL}/api/taxonomy-values?size=200`, {
    withCredentials: true
  });
};
export default {
  fetchDatasetsForModel,
  fetchModels,
  fetchTrainingPipelines
};
