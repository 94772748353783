import IAction from '../../../types/IAction';
import { TAXONOMIES_REQUEST, DATASET_MODELS_REQUEST } from '../types';
import { fetchTaxonomies, fetchModels } from '../../api';

export function requestTaxonomies(): IAction {
  return {
    type: TAXONOMIES_REQUEST,
    promise: fetchTaxonomies()
  };
}

export function requestModels(page: number = 0, size: number = 20, sort: string = 'createdDate,DESC', where?): IAction {
  return {
    type: DATASET_MODELS_REQUEST,
    promise: fetchModels(page, size, sort, where)
  };
}
