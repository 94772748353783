import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withApollo, gql } from "react-apollo";
import { connect } from "react-redux";
import { pureMe } from "../_Login/queries/authQueries";
import * as loginActions from "../_Login/actions";
import { hasDataScienceApplicationAccess, hasDataManagerRole } from './helpers';

class PublicRoute extends Route<any> {
  state = {
    loading: true,
    success: false,
    userContext: null,
  };

  async componentDidMount() {
    try {
      const { data } = await this.props.client.query({ query: pureMe() });
      const hasAccess = hasDataScienceApplicationAccess(data.me.groups);
      if (hasAccess) {
        this.props.setUserContext(data.me);
        this.setState({ success: true, loading: false, userContext: data.me });
      } else {
        this.setState({ success: false, loading: false, userContext: null });
        // force logout
        await this.props.client.mutate({
          mutation: gql`
            mutation {
              logout
            }
          `,
        });
        this.props.client.resetStore().catch(() => {});
        this.props.setUserContext(null);
      }
    } catch (e) {
      this.props.setUserContext(null);
      this.setState({ success: false, loading: false, userContext: null });
    }
  }

  render() {
    const { component: Component, client, ...rest } = this.props;
    if (this.state.loading) {
      return <div />;
    } else {
      if (this.state.success) {
        const pathname = hasDataManagerRole(this.state.userContext.groups) ? '/collections' : '/datasets';
        return (
          <Route
            {...rest}
            render={props => (
              <Redirect
                to={{ pathname, state: { from: props.location } }}
              />
            )}
          />
        );
      } else {
        return <Route {...rest} render={props => <Component {...props} />} />;
      }
    }
  }
}

const mapDispatchToProps = dispatch => ({
  setUserContext: (userContext = null) => {
    dispatch(loginActions.setUserContext(userContext));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withApollo(PublicRoute));
