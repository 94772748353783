import { connect } from 'react-redux';

import ModelCompare from './component';
import { isLoading, getSelectedModels } from '../../../reducers/models/selectors';

const mapStateToProps = (state, ownProps) => {
  const params = ownProps.location &&
    ownProps.location.search;
  const stringSelectedModels = params.replace(/%22/g,'"').replace('?selectedModels=',"");
  const selectedFromState = getSelectedModels(state)
  const selectedModels =  selectedFromState.length === 2
    ? selectedFromState
    : JSON.parse(stringSelectedModels);
  return {
    isLoading: isLoading(state),
    selectedModels
  }
};

export default connect(
  mapStateToProps
)(ModelCompare);
