import axios from 'axios';
import {
  getDocument,
  getDocuments,
  getCollections,
  getSentenceLabels,
  getDocumentLabels
} from './queries';
import { createCollection } from './mutations';
import apolloClient from '../../utils/apolloClient';
import constants from '../../utils/constants';

const fetchPolicy = 'network-only';

export const fetchCollections = (
  page: number = 0,
  size: number = 20
): Promise<object> => {
  const variables = { page, size };
  return apolloClient.query({ query: getCollections, variables, fetchPolicy });
};

export const fetchDocument = (
  collectionId: string,
  documentId: string
): Promise<object> => {
  const query = getDocument;
  const variables = { collectionId, documentId };
  return apolloClient.query({ query, variables, fetchPolicy });
};

export const fetchDocumentLabels = (documentId): Promise<object> => {
  const query = getDocumentLabels;
  const variables = { documentId };
  return apolloClient.query({ query, variables, fetchPolicy });
}

export const fetchDocuments = (
  collectionId: string,
  page: number = 0,
  size: number = 20,
  sort: string = 'createdDate,DESC'
): Promise<object> => {
  const query = getDocuments;
  const variables = { collectionId, page, size, sort };
  return apolloClient.query({ query, variables, fetchPolicy });
};

export const newCollection = (
  collectionName: string,
  companyId: number
): Promise<object> => {
  const mutation = createCollection;
  const variables = { collectionName, companyId };
  return apolloClient.mutate({ mutation, variables });
};

export const uploadDocument = (collectionId, file, documentTypeId) => {
  const data = new FormData();
  data.append('file', file);
  if (documentTypeId) {
    data.append('documentTypeId', documentTypeId);
  }
  const config = {
    withCredentials: true
  };
  return axios.post(
    `${constants.BASE_URL}/api/collections/${collectionId}/documents/upload`,
    data,
    config
  );
};

export const fetchSentenceLabels = (sentenceId: string): Promise<object> => {
  const variables = { sentenceId };
  return apolloClient.query({
    query: getSentenceLabels,
    variables,
    fetchPolicy
  });
};

export default {
  fetchCollections,
  fetchDocument,
  fetchDocuments,
  newCollection,
  uploadDocument
};
