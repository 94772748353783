import { connect } from 'react-redux';
import { requestDocument, requestDocumentLabels, focusSentence } from '../../../actions/creators';
import {
  getSentences,
  isLoading,
  hasError,
  getFocusSentenceId,
  getFocusSentenceLabels
} from '../../../reducers/document/selectors';
import DocumentView from './component';

const mapStateToProps = state => {
  return {
    sentences: getSentences(state),
    isLoading: isLoading(state),
    hasError: hasError(state),
    focusSentenceId: getFocusSentenceId(state),
    focusSentenceLabels: getFocusSentenceLabels(state)
  };
};

const mapDispatchToProps = {
  requestDocument,
  requestDocumentLabels,
  focusSentence
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentView);
