exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Ncku,.av3m5{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._1Ncku span,.av3m5 span{font-style:italic}.MP443{height:calc(100% - 50px);margin-bottom:6px}.Zo1iA .item-count-container{height:30px;width:30px}.Zo1iA .count-value{font-size:9px}.Zo1iA .count-label{display:none}", ""]);

// exports
exports.locals = {
	"noItemsMessage": "av3m5",
	"loadingCollections": "_1Ncku",
	"collectionTable": "MP443",
	"itemCount": "Zo1iA"
};