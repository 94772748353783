import React, { Component } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './styles.scss';
import { translate } from 'react-i18next';
import BaseSelect from '../../../../../toolkit/baseSelect';
import { DatasetDetails } from '../../../../../types-business/Dataset';
import DatasetReviewItemStatus from './status';
import SvgSpinner from '../../../../../toolkit/svgSpinner';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import DatasetReviewItemText from './text';

type Status = 'ACCEPTED' | 'REJECTED' | 'UNREVIEWED';

export const SUB_SENTENCE_LABEL_COLORS = [
  '#FF4136',
  '#FF851B',
  '#85144b',
  '#F012BE',
  '#9ec73d',
  '#1c1c43',
  '#292d78',
  '#005899',
  '#51af46',
  '#4c5670',
  '#8b91a3',
  '#976700',
  '#f3b83b',
  '#0174ca',
];

const request = {
  loading: 'loading',
  error: 'error',
  complete: 'complete',
}
export const ComponentType = {
  Sentence: 'SENTENCE',
  Document: 'DOCUMENT'
};
const getContextText = (context) => {
  return context.reduce((str, group) => {
    return str + group.members.reduce((acc, member) => {
      return `${acc} ${member.text}\n`
    }, '');
  }, '');
}
interface Props {
  focusItem: (id: string) => void;
  accept: (
    datasetId: string,
    componentId: string,
    taxonomyValue: string,
    start: number,
    end: number
  ) => void;
  reject: (
    datasetId: string,
    componentId: string,
    taxonomyValue: string
  ) => void;
  revert: (labelId: string, taxonomyValue: string, isInLabelReviewMode: boolean) => void;
  t: (key: string) => string;
  requestSentenceContext: (sentenceId: string) => void;
  componentType: string;
  datasetId: string;
  id: string;
  isInLabelReviewMode: boolean;
  isDatasetInfoLoading: boolean;
  isAddLabelUnderway: boolean;
  labels: [{
    id: string;
    source: {
      id: string;
      type: string;
    };
    score: number;
    taxonomyId: string;
    value: string;
    taxonomyValueId: string;
    startPosition?: number,
    endPosition?: number
  }];
  myUserId: number;
  text: string;
  focusItemId: string;
  datsetItemInfo: any;
  collectionId: string;
  documentId: string;
  acceptedSuggestions: string[];
  rejectedSuggestions: string[];
  datasetInfo: DatasetDetails;
  selectOptions: Array<{
    label: string;
    value: string;
  }>;
  predecessor: any;
  successor: any;
  status: string;
}

interface State {
  displayContext: boolean;
  addClassificationVisible: boolean;
  selection: any;
  deleting: {[id: string]: boolean};
}
export class DatasetReviewItem extends Component<Props, State> {
  private reactSelect = null;
  private selectionDebounced = null;
  constructor(props) {
    super(props);
    this.state = {
      displayContext: false,
      addClassificationVisible: true,
      selection: null,
      deleting: {}
    }

    this.selectionDebounced = debounce(this.textSelectionHandler, 300);
  }
  componentDidMount() {
    document.addEventListener("selectionchange", this.selectionDebounced);
  }
  componentDidUpdate() {
    if (this.isFocusItem()) {
      const node = ReactDOM.findDOMNode(this) as Element;
      node.scrollIntoView({ behavior: 'smooth' });
      if (this.reactSelect && !this.hasSelection()) {
        const { select } = this.reactSelect;
        select.focus(); // ensure that the in-focus item has the dropdown focused
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('selectionchange', this.selectionDebounced);
  }

  textSelectionHandler = () => {
    if (this.isFocusItem()) {
      const selection = document.getSelection();
      if (!selection.isCollapsed &&
        selection.anchorNode.parentElement.id === this.props.id) {
        const isSelectionAllInSameNode = selection.anchorNode === selection.focusNode;
        if (isSelectionAllInSameNode) {
          const range = selection.getRangeAt(0);
          this.setState({
            selection: {
              text: selection.toString(),
              start: range.startOffset,
              end: range.endOffset
            }
          });
        }
      }
    }
  }

  toggleAddButton = () => {
    this.setState({addClassificationVisible: !this.state.addClassificationVisible});
  }
  handleAccept() {
    const { accept, datasetId, id, labels } = this.props;
    let start = null;
    let end = null;
    const label = labels[0];
    const taxonomyValueId = label && label.taxonomyValueId;
    accept(datasetId, id, taxonomyValueId, start, end);
  }

  handleChoose = selectedOption => {
    const { id, datasetId, accept } = this.props;
    const { selection } = this.state;
    let start = null;
    let end = null;
    if (!!selection) {
      start = selection.start;
      end = selection.end;
    }
    accept(datasetId, id, selectedOption.value, start, end);
    this.setState({
      addClassificationVisible: true,
      selection: null
    });
  };

  handleReject() {
    const { reject, datasetId, id, labels } = this.props;
    const label = labels[0];
    reject(datasetId, id, label.taxonomyValueId);
  }

  handleRevert = () => {
    const { labels, revert, isInLabelReviewMode } = this.props;
    labels.map(label => {
      revert(label.id, label.taxonomyValueId, isInLabelReviewMode);
    });
  };

  focus = () => {
    const { focusItem, id } = this.props;
    if (!this.isFocusItem()) {
      focusItem(id);
      if (this.state.selection) {
        this.setState({ selection: null });
      }
    }
  };

  isFocusItem() {
    const { id, focusItemId } = this.props;
    return id === focusItemId;
  }

  hasSelection() {
    return !!this.state.selection && !!this.state.selection.toString();
  }

  isAddLabelUnderway() {
    return this.isFocusItem() && this.props.isAddLabelUnderway;
  }

  isAccepted() {
    const { id, acceptedSuggestions } = this.props;
    return acceptedSuggestions.includes(id);
  }

  isRejected() {
    const { id, rejectedSuggestions } = this.props;
    return rejectedSuggestions.includes(id);
  }

  isUnreviewed() {
    return !this.isAccepted() && !this.isRejected();
  }

  hasLabel() {
    const { labels } = this.props;
    return labels.length > 0;
  }

  getStatus(): Status {
    return this.isAccepted()
      ? 'ACCEPTED'
      : this.isRejected()
        ? 'REJECTED'
        : 'UNREVIEWED';
  }

  renderStatus() {
    return (
      <DatasetReviewItemStatus
        status={this.getStatus()}
        onClick={this.handleRevert.bind(this)}
      />
    );
  }

  renderAcceptRejectButtons() {
    if (this.isAddLabelUnderway()) {
      return (
        <div className={classNames(s.buttonWrapper, s.loading)}>
          <SvgSpinner small />
        </div>
      );
    } else {
      return (
        <div className={s.buttonWrapper}>
          <i
            className={classNames(s.rejectButton, 'material-icons')}
            onClick={this.handleReject.bind(this)}>
            close
          </i>
          <i
            className={classNames(s.acceptButton, 'material-icons')}
            onClick={this.handleAccept.bind(this)}>
            check
          </i>
        </div>
      );
    }
  }

  renderValueSelection() {
    const { selectOptions, isDatasetInfoLoading, labels } = this.props;
    const labelMap = labels.map(label => label.value);
    const filteredSelectOptions = selectOptions && selectOptions.filter(({label}) =>
      !labelMap.includes(label)
    );
    return (
      <div className={s.labelWrapper}>
        <div className={s.selectDropdown}>
          <BaseSelect
            options={filteredSelectOptions}
            onChange={this.handleChoose}
            innerRef={ref => {
              this.reactSelect = ref;
            }}
            isLoading={isDatasetInfoLoading}
            onFocus={this.focus.bind(this)}
          />
        </div>
      </div>
    );
  }
  toggleContext = () => {
    const {requestSentenceContext, id, predecessor, successor} = this.props;
    if(!predecessor && !successor) {
      requestSentenceContext(id);
    }
    this.setState(prevState => {
      return {
        ...prevState,
        displayContext: !this.state.displayContext
      }
    })
  }
  renderLabelControl() {
    const { labels, t, id, revert, isInLabelReviewMode, myUserId } = this.props;

    if (!this.hasLabel()) {
      return this.renderValueSelection();
    } else {
      let partialLabelCount = 0;
      return labels.map((label) => {
        // If we're showing all labels (in data manager review mode), determine which labels are "my" labels
        const isMyLabel = isInLabelReviewMode && (myUserId === parseInt(label.source.id));
        let labelColor = isMyLabel ? '#51af46' : 'inherit';
        if (!!label.endPosition) {
          partialLabelCount++;
          if (label.source.type === 'MANUAL') {
            labelColor = SUB_SENTENCE_LABEL_COLORS[partialLabelCount - 1];
          }
        }
        const labelValue = (label && label.value) || '';
        if(this.isRejected()) {
          return (
            <div key={`${label.value}-${id}`}>
              {this.renderValueSelection()}
              <div className={s.failedLabel}>
                <b>{t('dataset-items.classifiedAs')}</b> {labelValue}
              </div>
            </div>
          );
        }
        else {
          return (
            <div key={`${label.id}-${labelValue}`} className={s.labelWrapperExt}>
              <div className={s.label}>
                {!this.isUnreviewed() &&
                  <button
                    className='revert-classification'
                    title="Delete label"
                    onClick={() => {
                      if(!this.state.deleting[label.id]){
                        revert(label.id, label.taxonomyValueId, isInLabelReviewMode)
                      }
                      this.setState({deleting: {
                        [label.id]: true
                      }})
                      return null;
                    }}>
                      <i className={`far fa-times-circle ui-grey2-color`} />
                  </button>}
                <span
                  style={{ color: labelColor }}>{labelValue}</span>
                {isInLabelReviewMode && label.score < 0 && (
                  // show a rejected icon next to the label while in label review mode if the label is a rejction type (score = -1)
                  <i title="Rejection (score is -1)" className={classNames('material-icons', s.labelRejectionIcon, s.labelIcon)}>block</i>
                )}
                {isInLabelReviewMode && label.score > 0 && (
                  // show a rejected icon next to the label while in label review mode if the label is a rejction type (score = -1)
                  <i title="Accpeted (score is 1)" className={classNames('material-icons', s.labelAcceptedIcon, s.labelIcon)}>check_circle_outline</i>
                )}
                {isMyLabel && (
                  <i title="My Label" className={classNames(`far fa-user`, s.myLabel)} />
                )}
              </div>
              {this.isUnreviewed() && this.renderAcceptRejectButtons()}
            </div>
          );
        }
      });
    }
  }
  renderAddClassification() {
    const { addClassificationVisible } = this.state;
    const { t } = this.props;
    return (
      <>
        {!this.isUnreviewed() && !this.isRejected() &&
          <>
            { addClassificationVisible && (
              <div className={s.addAdditionalCategory}>
                <button
                  className={classNames('btn btn-outline-primary', s.addButton)}
                  onClick={this.toggleAddButton}>
                  {t('dataset-items.addAnotherLabel')}
                </button>
              </div>
            )}
            { !addClassificationVisible &&
              this.renderValueSelection()
            }
          </>
        }
      </>
    );
  }
  render() {
    const {
      text,
      t,
      collectionId,
      id,
      documentId,
      componentType,
      predecessor,
      successor,
      status,
      labels
    } = this.props;
    const {
      displayContext
    } = this.state;
    const isDocument = componentType === ComponentType.Document;
    const isSentence = componentType === ComponentType.Sentence;
    return (
      <div
        onClick={this.focus}
        className={classNames(s.datasetReviewItem, {
          [s.hasFocus]: this.isFocusItem()
        })}>
        {this.renderStatus()}
        <div className={s.sentenceContainer}>
          { displayContext && predecessor &&
            <section className={s.context}>
              {getContextText(predecessor)}
            </section> }

            <DatasetReviewItemText id={id} text={text} labels={labels} />

          { displayContext && successor &&
            <section className={s.context}>
              {getContextText(successor)}
            </section>}
          {isDocument && (
            <Link
              to={`/collections/${collectionId}/document/${documentId}`}
              target="_blank"
              rel="noopener noreferrer">
              {t('dataset-items.openDoc')}
            </Link>
          )}
          { isSentence &&
            <a
              className={s.toggleContext}
              href='javascript:void(0)'
              onClick={this.toggleContext}
            >
              {
                t(displayContext
                  ? 'dataset-items.hideContext'
                  : 'dataset-items.viewContext')}
            </a>
          }
          { this.isFocusItem() && !!this.state.selection && (
            <div className={s.selection}>
              <button
                className='remove-selection'
                title="Remove selection"
                onClick={() => this.setState({ selection: null })}>
                  <i className={`far fa-times-circle ui-grey2-color`} />
              </button>
              <span className={s.selectionLabel}>Selection:</span>
              <span className={s.selectionValue}>{this.state.selection.text}</span>
            </div>
          )}
        </div>
        <div className={s.labelWrapper}>
          {this.renderLabelControl()}
          { this.renderAddClassification() }
        </div>
        {status === request.loading &&
          <div className={s.loading}>{t('dataset-items.loading')}</div>
        }
      </div>
    );
  }
}

export default translate('translations')(withStyles(s)(DatasetReviewItem));
