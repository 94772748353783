import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Layout from '../../../_Home/components/layout';
import { DatasetReviewContainer } from '../../components/dataset/review';
import { DatasetReviewToolbarContainer } from '../../components/dataset/review/toolbar';
import TransactionOverlay from '../../../toolkit/transactionOverlay';
import { Location } from 'history';
import { isTransactionUnderway } from '../../reducers/dataset/selectors';
import { getMyUserId } from '../../../_Login/reducers/selectors';
import s from './styles.scss';

interface Props {
  location: Location;
  datasetId: string;
  isTransactionUnderway: boolean;
  userId: number;
  t: (key: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state),
  userId: getMyUserId(state)
});

class DatasetsPage extends Component<Props> {
  render() {
    const { t, isTransactionUnderway } = this.props;
    return (
      <>
        <Layout
          layoutClass={s.datasetReviewPage}
          title={t('dataset-review.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<DatasetReviewToolbarContainer {...this.props} />}
          content={<DatasetReviewContainer {...this.props} />}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(
  connect(mapStateToProps)(withStyles(s)(DatasetsPage))
);
