import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import normalizeCss from "normalize.css";
import bootstrapCss from "bootstrap/dist/css/bootstrap.css";
import SweetProgress from "react-sweet-progress/lib/style.css";
import Fragment from "../fragment";
import s from "./styles.scss";

class LayoutStyles extends React.Component<any> {
  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default withStyles(normalizeCss, bootstrapCss, SweetProgress, s)(
  LayoutStyles
);
