import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle  } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import Moment from 'react-moment';
import { withRouter } from 'react-router';
import { History } from 'history';
import { Row, Col, Container } from 'reactstrap';
import get from 'lodash/get';

import s from './styles.scss';
import BaseButton from '../../../../toolkit/baseButton';
import {
  DatasetDetails as IDatasetDetails,
  Distribution
} from '../../../../types-business/Dataset';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import DatasetDistribution from '../distribution';
import Progress from '../../../../toolkit/progress';
import constants from '../../../../utils/constants';
import { downloadFile } from '../../../../utils/helpers';
import { notifyFailure } from '../../../../utils/notifications';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

interface Props {
  datasetId: string;
  datasetInfo: IDatasetDetails;
  distribution: Distribution;
  hasDistributionError: boolean;
  history: History;
  isDistributionLoading: boolean;
  isLoading: boolean;
  userId: number;
  reviewedItemCount: number;
  totalItemCount: number;
  isPublishable: boolean;
  downloadDatasetFile: (datasetId: string, fileName: string, onSuccess, onFailure) => void;
  publishDataset: (datasetId: string) => void;
  requestDataset: (datasetId: string) => Promise<any>;
  requestDistribution: (datasetId: string) => void;
  requestUserReviewProgress: (datasetId: string, userId: number) => void;
  t: (str: string, vars?: any) => string;
  unpublishDataset: (datasetId: string) => void;
}

class DatasetDetails extends Component<Props> {
  componentWillMount = async () => {
    const {
      requestDataset,
      requestDistribution,
      requestUserReviewProgress,
      datasetId,
      userId
    } = this.props;

    requestDataset(datasetId).then((info) => {
      const dataset = get(info, 'payload.data.getDataset.data', null);
      if (!!dataset.itemCount) {
        if (dataset.type === 'REVIEW') {
          requestUserReviewProgress(datasetId, userId)
        }
        if (['TRAINING', 'GROUND_TRUTH'].includes(dataset.type)) {
          requestDistribution(datasetId);
        }
      }
    });
  };

  handlePublish = () => {
    const { datasetInfo, publishDataset } = this.props;
    publishDataset.call(this, datasetInfo.id);
  }

  handleUnpublish = () => {
    const { datasetInfo, unpublishDataset } = this.props;
    unpublishDataset.call(this, datasetInfo.id);
  }

  handleDownload = (fileName) => {
    return () => {
      const { datasetId, t, downloadDatasetFile} = this.props;
      const onSuccess = (response) => {
        downloadFile(response.data, `${datasetId}_${fileName}.csv`);
      };
      const onFailure = (err) => {
        notifyFailure(get(err, 'response.data.message', '') || t('dataset-info.download-dataset-error'));
      }
      downloadDatasetFile(datasetId, fileName, onSuccess, onFailure)
    };
  }

  isReviewable() {
    return this.props.datasetInfo.type === 'REVIEW';
  }

  isPublished() {
    const { PUBLISHED } = constants.DATASET_PUBLISH_STATUS;
    return this.props.datasetInfo.publishingStatus === PUBLISHED;
  }

  showDatasetDistribution() {
    const {
      datasetInfo: { type, itemCount }
    } = this.props;
    return !!itemCount && ['TRAINING', 'GROUND_TRUTH'].includes(type);
  }

  // TODO: Create an abstract metadata listing component for this, or at least use <Row/> and <Column/> here for layout.
  renderMetadata() {
    const { t, datasetInfo } = this.props;
    return (
      <ul>
        <li>
          <span className={s.metadataName} data-test-id="name-label">
            {t('dataset-info.name')}
          </span>
          <span className={s.metadataValue} data-test-id="name-value">
            {datasetInfo.name}
          </span>
        </li>
        <li>
          <span className={s.metadataName} data-test-id="type-label">
            {t('dataset-info.type')}
          </span>
          <span className={s.metadataValue} data-test-id="type-value">
            {t(`datasets.types.${datasetInfo.type}`)}
          </span>
        </li>
        <li>
          <span className={s.metadataName} data-test-id="item-count-label">
            {t('dataset-info.itemCount')}
          </span>
          <span className={s.metadataValue} data-test-id="item-count-value">
            {datasetInfo.itemCount}
          </span>
        </li>
        <li>
          <span className={s.metadataName} data-test-id="taxonomy-label">
            {t('dataset-info.taxonomy')}
          </span>
          <span className={s.metadataValue} data-test-id="taxonomy-value">
            {t(`taxonomies.types.${datasetInfo.taxonomy.value}`)}
          </span>
        </li>
        <li>
          <span className={s.metadataName} data-test-id="createdOn-label">
            {t('dataset-info.createdOn')}
          </span>
          <span className={s.metadataValue} data-test-id="createdOn-value">
            <Moment unix format="YYYY/MM/DD">
              {datasetInfo.createdDate}
            </Moment>
          </span>
        </li>
        {!this.isReviewable() && (
          <li>
            <span className={s.metadataName} data-test-id="published-label">
              {t('dataset-info.isPublished')}
            </span>
            <span className={s.metadataValue} data-test-id="published-value">
              {t(`dataset-info.${datasetInfo.publishingStatus}`)}
            </span>
          </li>
        )}
      </ul>
    );
  }

  renderModels() {
    const { t, datasetInfo } = this.props;
    return !!datasetInfo.trainedModels.length ? (
      <>
        <div className={s.separator} />
        <section className={s.metadata}>
          <h3>{t('dataset-info.associated-models')}</h3>
          <ul>
            {datasetInfo.trainedModels.map((model, index) => (
              <li key={index}>
                <a href={`/model/${model.id}`}>{model.name}</a>
              </li>
            ))}
          </ul>
        </section>
      </>
    ) : null;
  }

  renderDistribution() {
    if (this.showDatasetDistribution()) {
      const {
        distribution,
        isDistributionLoading,
        hasDistributionError
      } = this.props;
      return (
        <>
          <div className={s.separator} />
          <DatasetDistribution
            distribution={distribution}
            isLoading={isDistributionLoading}
            hasError={hasDistributionError}
          />
        </>
      );
    } else {
      return null;
    }
  }

  renderProgress() {
    const {
      t,
      datasetInfo,
      history,
      reviewedItemCount,
      totalItemCount
    } = this.props;
    return !this.isReviewable() ? null : (
      <div className={s.datasetDetailsReviewProgress}>
        <Container fluid>
          <Row data-elm-id="progress_container">
            <Progress
              completedItemCount={reviewedItemCount}
              totalItemCount={totalItemCount}
            />
          </Row>
          <Row>
            <Col auto="true" />
            <Col xs="3" className="p-r-0">
              <BaseButton
                onClick={() =>
                  history.push(`/datasets/${datasetInfo.id}/review`)
                }
                size="sm"
                color="success"
                className={s.goToReviewButton}
                data-elm-id={'review_dataset_button'}
                disabled={datasetInfo.itemCount === 0}>
                {t('dataset-items.review-button')}
              </BaseButton>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderDownloadButton() {
    const { t, datasetInfo } = this.props;

    const fileNames = get(datasetInfo, 'fileNames', []);

    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle className={classNames(s.downloadButton, s.datasetButton)} caret disabled={isEmpty(fileNames)}>
          {t('dataset-info.download-button-label')}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{t('dataset-info.download-choose-a-file')}</DropdownItem>
          { fileNames.map((fileName, index) => (<DropdownItem key={index} onClick={this.handleDownload(fileName)}>{fileName}</DropdownItem>)) }
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  render() {
    const { datasetInfo, isLoading, t, isPublishable } = this.props;

    if (isLoading) {
      return (
        <div className={`${s.loading}`}>
          <SvgSpinner />
        </div>
      );
    }

    return (
      datasetInfo && (
        <div className={s.datasetDetails}>
          {this.renderProgress()}
          <section className={s.metadata}>
            <h3>{t('dataset-info.details')}</h3>
            {this.renderMetadata()}
            {isPublishable && (
              <BaseButton
                onClick={this.handlePublish}
                size="sm"
                color="success"
                data-elm-id={'publish_training_dataset_button'}
                disabled={datasetInfo.itemCount === 0}>
                {t('dataset-items.publish-button')}
              </BaseButton>
            )}
            {this.isPublished() && (
              <>
                <BaseButton
                  className={s.datasetButton}
                  onClick={this.handleUnpublish}
                  size="sm"
                  color="warning"
                  data-elm-id={'unpublish_training_dataset_button'}>
                  {t('dataset-items.unpublish-button')}
                </BaseButton>

                {this.renderDownloadButton()}
              </>
            )}
          </section>

          {this.renderModels()}
          {this.renderDistribution()}
        </div>
      )
    );
  }
}

export default withRouter(
  translate('translations')(withStyles(s)(DatasetDetails))
);
