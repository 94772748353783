exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._5alqc{width:100%;height:100%;padding:10px;overflow:hidden}._5alqc._2zx5w{width:90%;height:auto;overflow:unset}._5alqc ._2VC1X{text-transform:uppercase}._5alqc .qeS9B{padding:16px 24px;padding:1rem 1.5rem;height:100%;width:50%}._5alqc .qeS9B._2zx5w{width:90%;height:auto}._5alqc ._3abzQ{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:stretch;align-items:stretch;height:100%}._5alqc .-cMhF{padding:16px 24px;padding:1rem 1.5rem;margin-bottom:16px;margin-bottom:1rem}._5alqc .-cMhF table{margin-left:16px;margin-left:1rem}._5alqc .-cMhF table td{padding:4px 16px;padding:.25rem 1rem;width:160px;text-align:left}._5alqc ._2xRKA{font-weight:700}._5alqc ._3cC1m{color:#8b91a3;font-size:12px}._5alqc ._3UBO5{color:#1c1c43}", ""]);

// exports
exports.locals = {
	"metrics": "_5alqc",
	"compare": "_2zx5w",
	"metricsHeader": "_2VC1X",
	"metricsList": "qeS9B",
	"metricsList_container": "_3abzQ",
	"metricsList_item": "-cMhF",
	"metricsList__itemHeader": "_2xRKA",
	"metricsList__itemLabel": "_3cC1m",
	"metricsList__itemValue": "_3UBO5"
};