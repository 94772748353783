import IAction from '../../../types/IAction';
import {
  DATASET_REVIEW_FOCUS_NEXT,
  DATASET_REVIEW_FOCUS_PREVIOUS,
  DATASET_REVIEW_FOCUS,
  DATASET_REVIEW_FOCUS_LAST_IN_PAGE,
  DATASET_REVIEW_ADD_LABEL,
  DATASET_REVIEW_REVERT_LABEL,
  DATASET_TOGGLE_LABEL_REVIEW_MODE
} from '../types';
import { addLabel, revertLabel as deleteLabel } from '../../api';
import { NOOP_CALLBACKS } from './util';
import { IActionThunk } from '../../../types/IActionThunk';
import {
  isItemInFocusFirstOnPage,
  isItemInFocusLastOnPage,
  isFirstPage,
  isLastPage,
  getCurrentPage,
  getFocusItemId,
  getReviewItems
} from '../../reducers/dataset/selectors';
import { History } from 'history';

export function focusNextReviewItem(history: History): IActionThunk {
  return (dispatch, getState) => {
    const state = getState();
    const isLastOnPage = isItemInFocusLastOnPage(state);
    const hasMorePages = !isLastPage(state);
    const focusedItemId = getFocusItemId(state);
    const focusedItem = getReviewItems(state)
      .filter(item => item.id === focusedItemId)[0];
    if (isLastOnPage && hasMorePages) {
      history.push(`?page=${getCurrentPage(state) + 1}&size=20`);
    }
    if(focusedItem.labels.length < 2){
      dispatch({
        type: DATASET_REVIEW_FOCUS_NEXT
      });
    }
  };
}

export function focusPreviousReviewItem(history: History): IActionThunk {
  return (dispatch, getState) => {
    const state = getState();
    const isFirstOnPage = isItemInFocusFirstOnPage(state);
    const hasPreviousPages = !isFirstPage(state);

    // if we're on the first item in the page, go to the previous page
    if (isFirstOnPage && hasPreviousPages) {
      history.push(`?page=${getCurrentPage(state) - 1}&size=20`, {
        focusLastItemInPage: true
      });
    } else {
      // otherwise, focus on the previous item
      dispatch({
        type: DATASET_REVIEW_FOCUS_PREVIOUS
      });
    }
  };
}

export function focusItem(id: string): IAction {
  return {
    type: DATASET_REVIEW_FOCUS,
    payload: id
  };
}

export function focusLastItemInPage(): IAction {
  return {
    type: DATASET_REVIEW_FOCUS_LAST_IN_PAGE
  };
}

export function acceptLabelSuggestion(
  datasetId,
  componentId,
  taxonomyValueId,
  start,
  end,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  const isNegative = false;
  return {
    type: DATASET_REVIEW_ADD_LABEL,
    payload: { componentId },
    promise: addLabel(datasetId, componentId, taxonomyValueId, isNegative, start, end),
    meta: { onSuccess, onFailure }
  };
}

export function rejectLabelSuggestion(
  datasetId,
  componentId,
  taxonomyValueId,
  callbacks = NOOP_CALLBACKS
) {
  const { onSuccess, onFailure } = callbacks;
  const isNegative = true;
  return {
    type: DATASET_REVIEW_ADD_LABEL,
    payload: { componentId },
    promise: addLabel(datasetId, componentId, taxonomyValueId, isNegative),
    meta: { onSuccess, onFailure }
  };
}

export function revertLabel(
  labelId,
  taxonomyValueId,
  callbacks = NOOP_CALLBACKS
) {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_REVIEW_REVERT_LABEL,
    promise: deleteLabel(labelId, taxonomyValueId),
    meta: { onSuccess, onFailure }
  };
}

export function toggleLabelReviewMode() {
  return {
    type: DATASET_TOGGLE_LABEL_REVIEW_MODE
  };
}