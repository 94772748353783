import { connect } from 'react-redux';

import CollectionNew from './component';
import { createCollection } from '../../../actions/creators';
import { showToastSuccess } from '../../../../_Home/actions';
import { getMyCompanyId } from '../../../../_Login/reducers/selectors';

const mapStateToProps = state => ({
  companyId: getMyCompanyId(state)
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: (fields, props) => {
    const { collectionName } = fields;
    const { history, t, companyId } = props;

    dispatch(
      createCollection(collectionName, companyId, {
        onSuccess: () => {
          history.push('/collections');
          dispatch(showToastSuccess(t('collections.create.success')));
        },
        onFailure: () => {
          dispatch(showToastSuccess(t('collections.create.failure')));
        }
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionNew);
