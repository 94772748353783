export default props => {
  const { t } = props;

  return [
    {
      Header: t('dataset-items.columns.label'),
      accessor: 'labels.value',
      style: { whiteSpace: 'normal' },
      maxWidth: 200,
      Cell: row => {
        const labels = row.original.labels;
        return labels.length
          ? labels[0].value
          : t('dataset-add-items.no-label-found');
      }
    },
    {
      Header: t('dataset-items.columns.text'),
      accessor: 'text',
      style: { whiteSpace: 'normal' }
    }
  ];
};
