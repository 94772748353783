import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import SortBy from '../../../../../toolkit/sortBy/sortBy';

export default props => {
  const { t, collectionId, location, onSortBy } = props;
  return [
    {
      Header: _ => {
        return <SortBy
          field='name'
          fieldText={t('documents.fields.name')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'name',
      Cell: row => {
        return (
          <Link to={`/collections/${collectionId}/document/${row.original.id}`}>
            {row.value}
          </Link>
        );
      }
    },
    {
      Header: t('documents.fields.pipeline'),
      accessor: 'pipelineDocumentId',
      maxWidth: 200,
    },
    {
      Header: t('documents.fields.createdBy'),
      accessor: 'createdBy',
      maxWidth: 100,
    },
    {
      Header: _ => {
        return <SortBy
          field='createdDate'
          fieldText={t('documents.fields.date')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'createdDate',
      maxWidth: 200,
      Cell: row => {
        return (
          <div>{moment(row.original.createdDate).format("MMM DD YYYY, h:mm a")}</div>
        );
      }
    }
  ];
};
