exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._25xQN{height:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#aa1e30}._25xQN i{vertical-align:middle;margin-right:10px}._7rzWc{height:100%;color:#1c1c43}._2-D3t ._7rzWc,._2-D3t i{color:#aa1e30}.YoJWF i{color:#0174ca}._2kau- i{color:#ff8c00}", ""]);

// exports
exports.locals = {
	"message": "_25xQN",
	"text": "_7rzWc",
	"error": "_2-D3t",
	"info": "YoJWF",
	"warning": "_2kau-"
};