import IAction from '../../../types/IAction';
import {
  DATASET_ADD_ITEMS,
  DATA_SET_CREATE,
  DATA_SETS_REQUEST,
  DATASET_DETAILS_REQUEST,
  DATASET_ITEMS_REQUEST,
  DATASET_ITEMS_DELETE,
  DATASET_ITEM_TOGGLE_SELECT,
  DATASET_ITEMS_TOGGLE_SELECT_ALL,
  DATASET_PUBLISH,
  DATASET_ADD_ITEMS_TEST_QUERY,
  DATASET_CLEAR_ITEMS_TO_ADD,
  DATASET_DISTRIBUTION_REQUEST,
  DATASET_USER_REVIEW_PROGRESS_REQUEST,
  DATASET_SENTENCE_CONTEXT_REQUEST,
  DATASET_UNPUBLISH,
  DATASET_DOWNLOAD_FILE,
} from '../types';
import {
  addItems,
  fetchAddItemsQueryResults,
  fetchDatasets,
  fetchDataset,
  fetchDatasetItems,
  newDataset,
  removeDatasetItems,
  publishDataset as publish,
  unpublishDataset as unpublish,
  fetchDistribution,
  fetchUserReviewProgress,
  fetchSentenceContext,
  downloadDatasetFile
} from '../../api';
import { NOOP_CALLBACKS } from './util';
import { CreateDatasetRequest } from '../../../types-business/Dataset';

export function createDataset(
  dataset: CreateDatasetRequest,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATA_SET_CREATE,
    promise: newDataset(dataset),
    meta: { onSuccess, onFailure }
  };
}

export function requestDatasets(page: number = 0, size: number = 20, sort?: string): IAction {
  return {
    type: DATA_SETS_REQUEST,
    promise: fetchDatasets(page, size, sort)
  };
}


export function requestDataset(datasetId: string): IAction {
  return {
    type: DATASET_DETAILS_REQUEST,
    promise: fetchDataset(datasetId)
  };
}

export const requestSentenceContext = (
  sentenceId: string,
  callbacks = NOOP_CALLBACKS
): IAction => {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_SENTENCE_CONTEXT_REQUEST,
    payload: {sentenceId},
    promise: fetchSentenceContext(sentenceId),
    meta: {
      onSuccess,
      onFailure,
    }
  };
}

export function requestDistribution(datasetId: string): IAction {
  const distributionRequest = new Promise(
    async (resolve, reject) => {
      try{
        fetchDistribution(datasetId).then((response: any) => {
          const { data } = response;
          resolve({data, datasetId})
        })
      } catch (e) {
        reject(e)
      }
    });
  return {
    type: DATASET_DISTRIBUTION_REQUEST,
    promise: distributionRequest,
  };
}

export function requestDatasetItems(
  datasetId: string,
  allLabels: boolean = false,
  page: number = 0,
  size: number = 20,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_ITEMS_REQUEST,
    promise: fetchDatasetItems(datasetId, allLabels, page, size),
    meta: {
      onSuccess,
      onFailure
    }
  };
}

export function deleteDatasetItems(
  datasetId: string,
  ids: string[],
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_ITEMS_DELETE,
    promise: removeDatasetItems(datasetId, ids),
    meta: {
      onSuccess,
      onFailure
    }
  };
}

export function toggleSelectDatasetItem(itemId: string): IAction {
  return {
    type: DATASET_ITEM_TOGGLE_SELECT,
    payload: itemId
  };
}

export function toggleSelectAllDatasetItems(): IAction {
  return {
    type: DATASET_ITEMS_TOGGLE_SELECT_ALL
  };
}

export function publishDataset(
  datasetId: string,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_PUBLISH,
    promise: publish(datasetId),
    meta: { onSuccess, onFailure }
  };
}

export function unpublishDataset(
  datasetId: string,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_UNPUBLISH,
    promise: unpublish(datasetId),
    meta: { onSuccess, onFailure }
  };
}

export function requestAddItemsQueryResults(
  datasetId: string,
  collectionId: string,
  documentId: string,
  restrictions: string,
  textRestrictions: string,
  limit: string,
  offset: string,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_ADD_ITEMS_TEST_QUERY,
    promise: fetchAddItemsQueryResults(
      datasetId,
      collectionId,
      documentId,
      restrictions,
      textRestrictions,
      limit,
      offset
    ),
    meta: { onSuccess, onFailure }
  };
}

export function addItemsToDataset(
  datasetId: string,
  collectionId: string,
  documentId: string,
  restrictions: string,
  textRestrictions: string,
  limit: string,
  offset: string,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_ADD_ITEMS,
    promise: addItems(
      datasetId,
      collectionId,
      documentId,
      restrictions,
      textRestrictions,
      limit,
      offset
    ),
    meta: { onSuccess, onFailure }
  };
}

export function clearItemsToAdd(): IAction {
  return {
    type: DATASET_CLEAR_ITEMS_TO_ADD
  };
}

export function requestUserReviewProgress(
  datasetId: string,
  userId: number
): IAction {
  const promise = fetchUserReviewProgress(datasetId, userId);
  const type = DATASET_USER_REVIEW_PROGRESS_REQUEST;
  return {
    type,
    promise
  };
}

export function downloadDataset(datasetId: string, fileName: string, callbacks = NOOP_CALLBACKS): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DATASET_DOWNLOAD_FILE,
    promise: downloadDatasetFile(datasetId, fileName),
    meta: {
      onSuccess,
      onFailure
    }
  }
}