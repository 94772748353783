import React from 'react';
import { Link } from 'react-router-dom';
import Count from '../../../../../toolkit/card/count';

export default props => {
  const { t, styles } = props;

  return [
    {
      Header: t('collections.page-title'),
      accessor: 'name',
      Cell: row => {
        return (
          <>
            <Link to={`/collections/${row.original.id}/documents`}>{row.original.name}</Link>
          </>
        );
      }
    },
    {
      Header: t('datasets.fields.count'),
      style: {
        textAlign: 'center',
      },
      Cell: row => {
        return (
          <Count
            className={styles.itemCount}
            count={row.original.documentCount}
            label={t(`componentTypes.${row.original.componentType}`)}
          />
        );
      },
      maxWidth: 150,
    }
  ];
};
