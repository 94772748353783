import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../utils/privateRoute';
import PublicRoute from '../utils/publicRoute';
import LoginPage from '../_Login/containers/login';
import NotFound from '../_Home/containers/not-found';
import CollectionsPage from '../_Collections/pages/collections';
import DocumentsPage from '../_Collections/pages/documents';
import DocumentPage from '../_Collections/pages/document';
import DatasetsPage from '../_Datasets/pages/datasets';
import DatasetDetailsPage from '../_Datasets/pages/dataset-details';
import DatasetReviewPage from '../_Datasets/pages/dataset-review';
import ModelsPage from '../_Models/pages/models';
import ModelComparePage from '../_Models/pages/model-compare';
import ModelDetailsPage from '../_Models/pages/model-details';
import constants from '../utils/constants';

const { ROLES } = constants;

export const Routes = () => (
  <Switch>
    <PublicRoute path="/login" component={LoginPage} />
    <PrivateRoute
      exact
      path="/collections/:collectionId/document/:documentId"
      component={DocumentPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER
      ]}
    />
    <PrivateRoute
      path="/collections/:collectionId/documents"
      component={DocumentsPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER
      ]}
    />
    <PrivateRoute
      path="/collections"
      component={CollectionsPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER
      ]}
    />
    <PrivateRoute
      path="/datasets/:datasetId/items"
      component={DatasetDetailsPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER,
        ROLES.DATA_REVIEWER
      ]}
    />
    <PrivateRoute
      path="/datasets/:datasetId/review"
      component={DatasetReviewPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER,
        ROLES.DATA_REVIEWER
      ]}
    />
    <PrivateRoute
      path="/datasets"
      component={DatasetsPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER,
        ROLES.DATA_REVIEWER
      ]}
    />
    <PrivateRoute
      path="/models"
      component={ModelsPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER
      ]}
    />
    <PrivateRoute
      path="/model/compare"
      exact
      component={ModelComparePage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER
      ]}
    />
    <PrivateRoute
      path="/model/:modelId"
      exact
      component={ModelDetailsPage}
      reservedRoles={[
        ROLES.ADMIN,
        ROLES.DATA_MANAGER
      ]}
    />
    <Redirect exact from="/" to="/login" />
    <PrivateRoute path="*" component={NotFound} />
  </Switch>
);
