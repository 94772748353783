import { connect } from 'react-redux';

import { getCollectionName } from '../../../../reducers/documents/selectors';
import DocumentListToolbar from './component';

const mapStateToProps = state => ({
  collectionName: getCollectionName(state)
});

export default connect(mapStateToProps)(DocumentListToolbar);
