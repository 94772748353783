import { combineReducers } from 'redux';
import { reducer as forms } from 'redux-form';
import modals from '../_Home/reducers/modals';
import login from '../_Login/reducers/login';
import navigation from '../_Home/reducers/navigation';
import modelReducers from '../_Models/reducers';
import collectionReducers from '../_Collections/reducers';
import datasetReducers from '../_Datasets/reducers';

const rootStore = combineReducers({
  ...modelReducers,
  ...collectionReducers,
  ...datasetReducers,
  forms,
  login,
  modals,
  navigation
});

export default rootStore;
