import React, { Component } from 'react';
import { History } from 'history';
import { translate } from 'react-i18next';
import BaseButton from '../../../../../toolkit/baseButton';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, matchPath } from 'react-router-dom';

interface Props {
  collectionName: string;
  collectionId: string;
  location: Location;
  history: History;
  t: (str: string) => string;
}

class DocumentListToolbar extends Component<Props> {
  render() {
    const { history, t, collectionId, collectionName, location } = this.props;
    const isShowingUploadSidebar = !!matchPath(location.pathname, {
      path: '/collections/:collectionId/documents/upload',
      exact: true
    });

    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem>
              <Link to="/collections">{t('collections.page-title')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{collectionName}</BreadcrumbItem>
          </Breadcrumb>
        </section>
        <section className="controls-zone">
          <BaseButton
            onClick={() =>
              history.push(`/collections/${collectionId}/documents/upload`)
            }
            size="sm"
            color="primary"
            disabled={isShowingUploadSidebar}
            data-elm-id={'upload_document_button'}>
            {t('documents.upload-button-label')}
          </BaseButton>
        </section>
      </>
    );
  }
}

export default translate('translations')(DocumentListToolbar);
