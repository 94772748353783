exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._1NuKp,._3xLxQ{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._1NuKp span,._3xLxQ span{font-style:italic}.KvsuH{height:calc(100% - 82px)}.KvsuH .rt-thead{display:none}._2Oe9z{height:30px;background-color:#f6f8fa;margin:5px;border:1px solid #edf0f2;border-radius:4px;font-size:12.8px;font-size:.8rem;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._3i_rk{margin-left:23px;margin-bottom:0;padding-top:4px}._3Q6Lz{margin-top:2px;margin-right:10px;color:#e3495d;cursor:pointer}._2riCM{color:#c9ccd4;cursor:default}", ""]);

// exports
exports.locals = {
	"noItemsMessage": "_1NuKp",
	"loadingItems": "_3xLxQ",
	"itemsTable": "KvsuH",
	"controls": "_2Oe9z",
	"selectAll": "_3i_rk",
	"deleteButton": "_3Q6Lz",
	"disabled": "_2riCM"
};