import { connect } from 'react-redux';
import { getDatasetInfo, isPublished } from '../../../../reducers/dataset/selectors';
import DatasetItemsToolbar from './component';
import { getMyRoles } from '../../../../../_Login/reducers/selectors';

const mapStateToProps = state => ({
  datasetInfo: getDatasetInfo(state),
  isPublished: isPublished(state),
  userRoles: getMyRoles(state)
});

export default connect(mapStateToProps)(DatasetItemsToolbar);
