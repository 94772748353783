import { connect } from 'react-redux';
import {
  getFocusSentenceLabels,
  isFocusSentenceLabelsLoading,
  hasFocusSentenceLabelsError
} from '../../../../../reducers/document/selectors';

import DocumentViewSentenceDetails from './component';

const mapStateToProps = state => ({
  labels: getFocusSentenceLabels(state),
  isLoading: isFocusSentenceLabelsLoading(state),
  hasError: hasFocusSentenceLabelsError(state)
});

export default connect(mapStateToProps)(DocumentViewSentenceDetails);
