import React, { Component } from 'react';
import { ListGroupItem, Badge } from 'reactstrap';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import s from './styles.scss';

interface Props {
  taxonomyId: string;
  value: string;
  isSelected: boolean;
  focusSentenceId: string;
  nextMatchingSentenceId: string;
  previousMatchingSentenceId: string;
  currentSelectedLabelSentenceIdPosition: number;
  select: (taxonomyId: string, labelValue: string) => void;
  requestSentenceLabels: (sentenceId: string) => void;
}

export class Label extends Component<Props> {
  handleClick() {
    const { select, taxonomyId, value } = this.props;
    select(taxonomyId, value);
  }

  previousLabelInstance(e) {
    e.preventDefault();
    e.stopPropagation();
    const { previousMatchingSentenceId, requestSentenceLabels } = this.props;
    if (previousMatchingSentenceId) {
      requestSentenceLabels(previousMatchingSentenceId);
    }
  }

  nextLabelInstance(e) {
    e.preventDefault();
    e.stopPropagation();
    const { nextMatchingSentenceId, requestSentenceLabels } = this.props;
    if (nextMatchingSentenceId) {
      requestSentenceLabels(nextMatchingSentenceId);
    }
  }

  renderNavigationControls() {
    if (this.props.isSelected) {
      const {
        nextMatchingSentenceId,
        previousMatchingSentenceId,
        currentSelectedLabelSentenceIdPosition
      } = this.props;

      return (
        <span className={s.navigationControls}>
          {
            <i
              className={classNames('material-icons', {
                [s.isDisabled]: !previousMatchingSentenceId
              })}
              onClick={this.previousLabelInstance.bind(this)}>
              navigate_before
            </i>
          }
          <Badge color="success">
            {currentSelectedLabelSentenceIdPosition}
          </Badge>
          {
            <i
              className={classNames('material-icons', {
                [s.isDisabled]: !nextMatchingSentenceId
              })}
              onClick={this.nextLabelInstance.bind(this)}>
              navigate_next
            </i>
          }
        </span>
      );
    } else {
      return null;
    }
  }
  render() {
    const { value, isSelected } = this.props;
    return (
      <ListGroupItem
        tag="a"
        onClick={this.handleClick.bind(this)}
        className={classNames(s.listItem, {
          [s.isSelected]: isSelected
        })}>
        <span className={s.labelValue}>{value}</span>
        {this.renderNavigationControls()}
      </ListGroupItem>
    );
  }
}

export default withStyles(s)(Label);
