import React, { Component } from 'react';
import Document from '../../../../types-business/Document';
import { translate } from 'react-i18next';
import ReactTable from 'react-table';
import ReactTableStyles from 'react-table/react-table.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Message from '../../../../toolkit/message';
import { History, Location } from 'history';

import columns from './_table/columns';
import ReactTableStylesOverride from '../../../../scss/_react-table.scss';
import { getPaginationData } from '../../../../utils/helpers';
import Pagination from '../../../../toolkit/pagination';

interface Props {
  documents: Document[];
  collectionId: string;
  isLoading: boolean;
  hasError: boolean;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  location: Location;
  history: History;
  t: (key: string) => string;
  requestDocuments: (
    page?: number,
    size?: number,
    sort?: string,
  ) => void;
}

class DocumentList extends Component<Props> {
  componentWillMount() {
    const { requestDocuments, location } = this.props;
    const {page, size, sort} = getPaginationData(location)
    requestDocuments(page, size, sort || 'createdDate,DESC');
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { requestDocuments, location } = this.props;
      const {page, size, sort} = getPaginationData(location)
      requestDocuments(page, size, sort);
    }
  }
  onSortBy = (field: string, direction?: string) => {
    const { location } = this.props;
    const {page} = getPaginationData(location)
    const sortString = direction ? `&sort=${field},${direction}` : '';
    this.props.history.push(`?page=${page + 1}${ sortString }`);
  }
  handlePageChange = page => {
    const { location } = this.props;
    const { sort } = getPaginationData(location);
    const sortString = sort ? `&sort=${sort}` : '';
    this.props.history.push(`?page=${page + 1}${ sortString }`);
  };

  render() {
    const {
      documents,
      isLoading,
      hasError,
      totalPages,
      pageSize,
      location,
      t
    } = this.props;
    const { page } = getPaginationData(location);
    if (hasError) {
      return <Message message={t('errors.data-load')} type="error" />;
    }

    return (
      <>
        <ReactTable
          className="default-akorda-table"
          columns={columns({onSortBy: this.onSortBy, ...this.props})}
          data={documents}
          loading={isLoading}
          loadingText={t('documents.loading')}
          manual
          noDataText={t('documents.no-data')}
          pageSize={pageSize}
          sortable={false}
          showPageJump={false}
          showPageSizeOptions={false}
          showPaginationBottom={false}
          style={{ height: 'calc(100% - 50px)' }}
          onPageChange={this.handlePageChange}
        />
        <Pagination
          pageNumber={page}
          pageSize={pageSize}
          totalPages={totalPages}
          onPrevious={this.handlePageChange}
          onNext={this.handlePageChange}
        />
      </>
    );
  }
}

export default translate('translations')(
  withStyles(ReactTableStyles, ReactTableStylesOverride)(DocumentList)
);
