import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import { translate } from 'react-i18next';
import { Row, Col, Container } from 'reactstrap';
import { Progress as SweetProgress } from 'react-sweet-progress';

import s from './styles.scss';

type Props = {
  completedItemCount: number;
  totalItemCount: number;
  t: (key: string) => string;
};

class Progress extends Component<Props> {
  isComplete() {
    return this.props.completedItemCount === this.props.totalItemCount;
  }

  hasItems() {
    return this.props.totalItemCount > 0;
  }

  renderAllDone() {
    return (
      <span className={classNames('bold brand-color-green p-r-5')}>
        {this.props.t('dataset-review.progress.all-done')}!{' '}
      </span>
    );
  }

  renderProgressLabel() {
    const { t, completedItemCount, totalItemCount } = this.props;
    return this.isComplete() && !this.hasItems() ? (
      <span>{t('dataset-review.progress.no-items')}</span>
    ) : (
      <>
        <span className="bold">{completedItemCount}</span> {t('globals.of')}{' '}
        <span className="bold">{totalItemCount}</span>{' '}
        {t('dataset-review.progress.items-processed')}
      </>
    );
  }

  renderProgressHeader() {
    return (
      <div data-testid="progress-status-description">
        {this.isComplete() && this.renderAllDone()}
        {this.renderProgressLabel()}
      </div>
    );
  }

  renderProgressBar() {
    const percentComplete = this.calculatePercentageComplete();
    return (
      <div data-testid={`percent-progress-${percentComplete}`}>
        <SweetProgress
          className={s.progressBar}
          percent={percentComplete}
          status="success"
          theme={{
            success: {
              symbol: ' ',
              color: '#51AF46'
            }
          }}
        />
      </div>
    );
  }

  calculatePercentageComplete() {
    if (this.isComplete()) {
      return 100; // If there are zero total items, consider this 100%
    } else {
      const { completedItemCount, totalItemCount } = this.props;
      return totalItemCount > 0 && totalItemCount >= completedItemCount
        ? (completedItemCount / totalItemCount) * 100
        : 0;
    }
  }

  render() {
    return (
      <Container className={s.progress} data-testid="progress" fluid>
        <Row noGutters>
          <Col
            lg={12}
            className={classNames(s.progressHeader, 'p-0 m-b-10')}
            data-testid="progress-header">
            {this.renderProgressHeader()}
          </Col>
        </Row>
        <Row noGutters>
          <Col lg={12} className="p-0 m-b-10" data-elm-id="progress-bar">
            {this.renderProgressBar()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('translations')(withStyles(s)(Progress));
