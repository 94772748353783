import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';
import { History } from 'history';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem, Container, Row } from 'reactstrap';
import Progress from '../../../../../toolkit/progress';
import s from './styles.scss';
import ToggleSwitch from '../../../../../toolkit/toggleSwitch';

interface Props {
  datasetId: string;
  datasetInfo: any;
  history: History;
  isDataManager: boolean;
  isInLabelReviewMode: boolean;
  location: Location;
  reviewedItemCount: number;
  totalItemCount: number;
  userId: number;
  requestUserReviewProgress: (datasetId: string, userId: number) => void;
  t: (str: string) => string;
  toggleLabelReviewMode: () => void;
}

class DatasetReviewToolbar extends Component<Props> {
  componentWillMount() {
    const { datasetId, requestUserReviewProgress, userId } = this.props;
    requestUserReviewProgress(datasetId, userId);
  }

  handleToggleLabelReviewMode = () => {
    this.props.toggleLabelReviewMode();
  }

  render() {
    const {
      t,
      datasetInfo,
      datasetId,
      isDataManager,
      isInLabelReviewMode,
      reviewedItemCount,
      totalItemCount
    } = this.props;
    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem>
              <Link to="/datasets">{t('datasets.page-title')}</Link>
            </BreadcrumbItem>
            {datasetInfo && (
              <>
                <BreadcrumbItem>
                  <Link to={`/datasets/${datasetId}/items`}>
                    {datasetInfo.name}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {t('dataset-review.review')}
                </BreadcrumbItem>
              </>
            )}
          </Breadcrumb>
        </section>
        <section className="controls-zone">
          {isDataManager && (
            <div className={s.reviewMode}>
              <ToggleSwitch
                label={t('dataset-review.label-review-mode')}
                checked={isInLabelReviewMode}
                onChange={this.handleToggleLabelReviewMode}
              />
            </div>
          )}
          <div className={s.reviewProgress}>
            <Container fluid>
              <Row data-elm-id="progress_container">
                <Progress
                  completedItemCount={reviewedItemCount}
                  totalItemCount={totalItemCount}
                />
              </Row>
            </Container>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(
  translate('translations')(withStyles(s)(DatasetReviewToolbar))
);
