import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import Fragment from '../../../toolkit/fragment';
import { toast, ToastContainer } from 'react-toastify';
import * as actions from '../../actions';
import constants from '../../../utils/constants';

interface Props {
  t: (str: string) => string;
  showErrorModal: boolean;
  errorModalMessage: string;
  toggleErrorModal: (error: string) => void;
  resetToast: () => void;
  resetErrorToast: () => void;
  showToastSuccess: boolean;
  toastSuccessMessage: string;
}

class Modals extends React.Component<Props, void> {
  toastTimeout = constants.TOAST_DEFAULT_TIME;

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.showErrorModal !== nextProps.showErrorModal &&
      nextProps.showErrorModal
    ) {
      toast.dismiss();
      const message =
        nextProps.errorModalMessage || nextProps.t('errors.generic-error');
      toast(message, { className: 'toastError' });
      this.props.resetErrorToast();
    }

    if (
      this.props.showToastSuccess !== nextProps.showToastSuccess &&
      nextProps.showToastSuccess
    ) {
      toast.dismiss();
      const message =
        nextProps.toastSuccessMessage ||
        nextProps.t('globals.operation-success');
      toast(message, { className: 'toastSuccess' });
      this.props.resetToast();
    }
  }

  render() {
    return (
      <Fragment>
        <ToastContainer
          className="toast"
          autoClose={this.toastTimeout}
          hideProgressBar={true}
          position={toast.POSITION.TOP_CENTER}
          data-elm-id="modal_error_toast"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { modals } = state;
  return {
    showErrorModal: modals.showErrorModal,
    errorModalMessage: modals.errorModalMessage,

    showToastSuccess: modals.showToastSuccess,
    toastSuccessMessage: modals.toastSuccessMessage
  };
};

const mapDispatchToProps = dispatch => ({
  toggleErrorModal: (error: string) => {
    dispatch(actions.toggleErrorModal(error));
  },
  resetToast: () => {
    dispatch(actions.resetToast());
  },
  resetErrorToast: () => {
    dispatch(actions.resetErrorToast());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(withStyles()(Modals)));
