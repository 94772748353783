import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Dropzone from "react-dropzone";
import s from "./styles.scss";
import { bytesToSize } from "../../utils/helpers";
import SvgSpinner from "../svgSpinner";
interface Props {
  name: string;
  fileName: string;
  fileSize: number;
  accept: string;
  iconClass: string;
  id: string;
  multiple: boolean;
  placeholder?: string;
  label?: string;
  onDrop: (files: File[], rejected: File[]) => void;
  errors: string[];
  disabled: boolean;
  dataElmId: string;
  uploadImage?: string;
  documentImage?: string;
  dragLabel?: string;
  chooseFileLabel?: string;
  chooseAnotherFileLabel?: string;
  cancelLabel?: string;
  notSupportedLabel?: string;
  isCreatingProject: boolean;
}

interface State {
  isInitialState: boolean;
}

class FileDropZone extends React.Component<Props, State> {
  dropzoneRef;
  state = {
    isInitialState: true,
  };

  renderFileDetails() {
    const {
      fileName,
      fileSize,
      errors,
      notSupportedLabel,
      dataElmId,
    } = this.props;
    if (fileName) {
      return [
        <p
          key="1"
          data-elm-id={`${dataElmId}_file_name`}
          className={s.fileName}
        >
          {fileName}
        </p>,
        <p
          key="2"
          data-elm-id={`${dataElmId}_file_size`}
          className={s.fileSize}
        >
          {bytesToSize(fileSize)}
        </p>,
      ];
    } else if (errors) {
      return (
        <p data-elm-id={`${dataElmId}_error_message`} className={s.errorSpan}>
          {notSupportedLabel || "Not supported type"}
        </p>
      );
    }
    return null;
  }

  renderContent() {
    const {
      iconClass,
      uploadImage,
      documentImage,
      dragLabel,
      chooseFileLabel,
      chooseAnotherFileLabel,
      dataElmId,
    } = this.props;
    if (this.state.isInitialState) {
      return (
        <div className={s.inner}>
          {uploadImage && (
            <img
              className={s.imgUpload}
              src={uploadImage}
              data-elm-id={`${dataElmId}_upload_image`}
            />
          )}
          <p data-elm-id={`${dataElmId}_drag_label`} className={s.dragLabel}>
            {dragLabel || "Drag your file here"}
          </p>
          <p
            data-elm-id={`${dataElmId}_choose_file`}
            onClick={() => this.dropzoneRef.open()}
            className={s.chooseFileLabel}
          >
            {chooseFileLabel || "or choose a file"}
          </p>
        </div>
      );
    } else if (this.props.isCreatingProject) {
      return (
        <div className={s.inner}>
          <SvgSpinner
            dataElmId={`${dataElmId}_loader`}
            className={s.spinnerSizeStyle}
          />
        </div>
      );
    }
    return (
      <div className={s.inner}>
        {!documentImage && (
          <div className={s.icon}>
            <i className={iconClass || "fa fa-file-text-o"} />
          </div>
        )}
        {documentImage && (
          <img
            className={s.imgDocument}
            src={documentImage}
            data-elm-id={`${dataElmId}_document_image`}
          />
        )}
        {this.renderFileDetails()}
        <p
          data-elm-id={`${dataElmId}_choose_file`}
          onClick={() => this.dropzoneRef.open()}
          className={`${s.chooseFileLabel} ${s.bottomLabel}`}
        >
          {chooseAnotherFileLabel || "Choose another file"}
        </p>
      </div>
    );
  }

  onDrop = (files, rejected) => {
    this.setState({ isInitialState: false }, () => {
      this.props.onDrop(files, rejected);
    });
  };

  render() {
    const { accept, name, multiple, dataElmId, isCreatingProject } = this.props;

    return (
      <Dropzone
        name={name}
        className={s.containerStyle}
        accept={accept || "*"}
        multiple={multiple}
        onDrop={this.onDrop}
        data-elm-id={dataElmId}
        ref={node => {
          this.dropzoneRef = node;
        }}
        disableClick={true}
        disabled={isCreatingProject}
      >
        <div className={s.cn}>{this.renderContent()}</div>
      </Dropzone>
    );
  }
}

export default withStyles(s)(FileDropZone);
