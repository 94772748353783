import { createSelector } from 'reselect';
import findIndex from 'lodash/findIndex';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import Immutable from 'seamless-immutable';
import { getModelMetrics, getTaxonomyValues as getAllTaxonomyValues } from '../../../_Models/reducers/models/selectors';
import { getMyRoles } from '../../../_Login/reducers/selectors';
import { hasDataManagerRole } from '../../../utils/helpers';
import constants from '../../../utils/constants';

const datasetState = state => state.dataset;
const datasetDistributionState = (state, props) => {
  const distribution = datasetState(state).distribution || {};
  return distribution[props.datasetId] || [];
};

export const getDatasetItems = createSelector(
  datasetState,
  state => state.items
);


export const isInLabelReviewMode = createSelector(
  datasetState,
  state => state.isInLabelReviewMode
);

export const getReviewItems = createSelector(
  getDatasetItems,
  isInLabelReviewMode,
  (items, isInLabelReview) => {
  if (isInLabelReview) {
    // if we're in label review mode, we want to see all the manual labels without any deduplication
    const remapItems = items.map(item => {
      let manualLabels = [];
      manualLabels = manualLabels.concat((item.labels || []).filter(label => label.source.type === 'MANUAL'));
      return {
        ...item,
        labels: manualLabels
      };
    });
    return remapItems;
  } else {
    // deduplicate and send only the labels needed for the reviewer
    const remapItems = items.map(item => {
      let labels = [];
      const manualLabelsAcceptedSet = new Set();
      const modelLabelsSet = new Set();
      const manualLabelsAcccepted = []
      const manualLabelsRejected = []
      const modelLabels = []
      item.labels.forEach(label => {

        const key = `${label.source.type}-${label.value}`;
        if(
          label.source.type === 'MANUAL'
        ) {
          if(
            label.score > 0 &&
            !manualLabelsAcceptedSet.has(key)
          ) {
            manualLabelsAcccepted.push(label);
            manualLabelsAcceptedSet.add(key)
          } else {
            manualLabelsRejected.push(label);
          }
        } else {
          if(!modelLabelsSet.has(key)) {
            modelLabelsSet.add(key);
            modelLabels.push(label);
          }
        }
      })
      labels = modelLabels;
      labels = manualLabelsRejected.length
        ? manualLabelsRejected
        : labels;
      labels = manualLabelsAcccepted.length
        ? manualLabelsAcccepted
        : labels;
      return {
        ...item,
        labels
      };
    });

    return remapItems;
  }
});

export const getDatasetItemsStatus = createSelector(
  datasetState,
  state => state.itemsStatus
);

export const getSelectedItems = createSelector(
  datasetState,
  state => state.selectedItems
);

export const getCurrentPage = createSelector(
  datasetState,
  state => state.currentPage
);

export const getTotalItems = createSelector(
  datasetState,
  state => state.totalItems
);

export const getPageSize = createSelector(
  datasetState,
  state => state.pageSize
);

export const getTotalPages = createSelector(
  getTotalItems,
  getPageSize,
  (totalItems, pageSize) => Math.ceil(totalItems / pageSize) || 1
);

export const isLastPage = createSelector(
  datasetState,
  state => state.isLastPage
);

export const isFirstPage = createSelector(
  datasetState,
  state => state.isFirstPage
);

export const isLoading = createSelector(
  getDatasetItemsStatus,
  status => status === 'loading'
);

export const hasError = createSelector(
  getDatasetItemsStatus,
  status => status === 'error'
);

export const getDatasetInfo = createSelector(
  datasetState,
  state => state.info || { trainedModels: [], taxonomy: {} }
);

export const getDatasetInfoStatus = createSelector(
  datasetState,
  state => state.infoStatus
);

export const isDatasetInfoLoading = createSelector(
  getDatasetInfoStatus,
  status => status === 'loading'
);

export const hasDatasetInfoError = createSelector(
  getDatasetInfoStatus,
  status => status === 'error'
);

export const hasSelectAll = createSelector(
  datasetState,
  state => state.hasSelectAll
);

export const hasSelections = createSelector(
  getSelectedItems,
  items => items.length > 0
);

export const getItemsToAdd = createSelector(
  datasetState,
  state => state.itemsToAdd
);

export const isItemsToAddLoading = createSelector(
  datasetState,
  state => state.itemsToAddStatus === 'loading'
);

export const hasItemsToAddError = createSelector(
  datasetState,
  state => state.itemsToAddStatus === 'error'
);

export const hasItemsToAdd = createSelector(
  datasetState,
  state => state.itemsToAdd.length > 0
);

export const isTransactionUnderway = createSelector(
  datasetState,
  state => state.isTransactionUnderway
);

export const isAddLabelUnderway = createSelector(
  datasetState,
  state => state.isAddLabelUnderway
);

export const isPublishable = createSelector(
  getDatasetInfo,
  getMyRoles,
  ({ publishingStatus, type }, myRoles) => {
    const { FAILED, NOT_PUBLISHED } = constants.DATASET_PUBLISH_STATUS;
    return (
      hasDataManagerRole(myRoles) &&
      ['TRAINING', 'GROUND_TRUTH'].includes(type) &&
      [FAILED, NOT_PUBLISHED].includes(publishingStatus)
    );
  }
);

export const isPublished = createSelector(
  getDatasetInfo,
  ({ publishingStatus }) => {
    const { IN_PROGRESS, PUBLISHED } = constants.DATASET_PUBLISH_STATUS;
    return [PUBLISHED, IN_PROGRESS].includes(publishingStatus)
  }
);

export const getFocusItemId = createSelector(
  datasetState,
  state => state.focusItemId
);

export const getAcceptedSuggestions = createSelector(
  getReviewItems,
  reviewItems => {
    const manuallyReviewedItems = reviewItems.filter(item => {
      const accepetedLabels = item.labels.filter(label =>
        label.source.type === 'MANUAL' &&
        label.score !== -1)
      return accepetedLabels.length > 0;
    });

    return manuallyReviewedItems.map(item => item.id);
  }
);

export const getRejectedSuggestions = createSelector(
  getReviewItems,
  reviewItems => {
    const manuallyReviewedItems = reviewItems.filter(item => {
      const manualLabels = item.labels.filter(label =>
        label.source.type === 'MANUAL');
      const rejectedLabels = manualLabels.filter(
        label => label.score === -1);
      return rejectedLabels.length && rejectedLabels.length === manualLabels.length;
    });
    return manuallyReviewedItems.map(item => item.id);
  }
);

export const isItemInFocusLastOnPage = createSelector(
  getDatasetItems,
  getFocusItemId,
  (items, focusItemId) => {
    const indexOfFocusItem = findIndex(items, { id: focusItemId });
    return indexOfFocusItem >= 0 && indexOfFocusItem === items.length - 1;
  }
);

export const isItemInFocusFirstOnPage = createSelector(
  getDatasetItems,
  getFocusItemId,
  (items, focusItemId) => {
    return findIndex(items, { id: focusItemId }) === 0;
  }
);

export const getTaxonomyValues = createSelector(getDatasetInfo, info => {
  return info.taxonomy.values || [];
});

export const getRecentSelections = createSelector(
  datasetState,
  state => state.recentSelections
);

export const getReviewSelectOptions = createSelector(
  getTaxonomyValues,
  getRecentSelections,
  (values, recentSelections) => {
    // taxonomy values prepared for `react-select` dropdown
    const options = Immutable.asMutable(values).map(val => ({
      value: val.id,
      label: val.value
    }));

    // recent user selections
    const topFiveRecentSelections = recentSelections.slice(0, 5); // only get the top 3

    // remove duplicates
    return uniqBy([...topFiveRecentSelections, ...options], 'value');
  }
);

// Returns the current sort by property
export const getDistributionSortBy = createSelector(
  datasetState,
  state => state.distributionSortBy
);

// Returns the distribution of the labels within the data set. Since the
// distribution that is stored in state doesn't have a total count value,
// we use reduce here to sum the counts in the array to provide the total.
export const getDistribution = createSelector(
  datasetDistributionState,
  getDistributionSortBy,
  (distribution, sort) => {
    const items = distribution || [];
    const totalCount = items.reduce((result, item) => result + item.count, 0);
    return {
      totalCount,
      items: sortBy(items, sort)
    };
  }
);
export const getDistributionWithMetrics = createSelector(
  getDistribution,
  getModelMetrics,
  getAllTaxonomyValues,
  (distribution, metrics, taxonomyValues) => {
    if (metrics) {
      const actualMetrics =
        (metrics.metrics && (metrics.metrics['classification-report'] || metrics.metrics['test-classification-report'])) ||
        metrics.metrics ||
        {};
      const newDistribution = distribution.items.map((distribution: any) => {
        const taxonomy = taxonomyValues &&
          taxonomyValues[distribution.taxonomyValueId]
        const values = actualMetrics[taxonomy.value] || actualMetrics[taxonomy.categoryValue];
        return {
          ...distribution,
          ...values
        };
      });
      return {
        ...distribution,
        items: newDistribution
      };
    } else {
      return distribution;
    }
  }
);
// Returns the status (loading, error, completed) of the distribution load op
export const getDistributionStatus = createSelector(
  datasetState,
  state => state.distributionStatus
);

// Returns true if the distribution fetch is still loading
export const isDistributionLoading = createSelector(
  getDistributionStatus,
  status => status === 'loading'
);

// Returns true if the distribution fetch encountered an error
export const hasDistributionError = createSelector(
  getDistributionStatus,
  status => status === 'error'
);

export const getReviewedItemCount = createSelector(
  datasetState,
  state => state.reviewedItemCount
);

export const getTotalItemCount = createSelector(
  datasetState,
  state => state.totalItems
);

export const getUserReviewProgressStatus = createSelector(
  datasetState,
  state => state.userReviewProgressStatus
);

export const isLoadingUserProgress = createSelector(
  getUserReviewProgressStatus,
  status => status === 'loading'
);

export const hasUserProgressError = createSelector(
  getUserReviewProgressStatus,
  status => status === 'error'
);

const getDatasetSentenceContext = (state, props) => {
  return (
    (state.dataset &&
      state.dataset.sentencesContext &&
      state.dataset.sentencesContext[props.id]) ||
    {}
  );
};

export const getPredecessor = createSelector(
  getDatasetSentenceContext,
  context => {
    return context.predecessorGroups;
  }
);
export const getSuccessor = createSelector(
  getDatasetSentenceContext,
  context => context.successorGroups
);
export const getContextStatus = createSelector(
  getDatasetSentenceContext,
  context => context.sentenceContextStatus
);

