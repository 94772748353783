export default {
  ERROR_MODAL_TOGGLE: 'ERROR_MODAL_TOGGLE',
  LOGIN_MODAL_TOGGLE: 'LOGIN_MODAL_TOGGLE',
  REGISTER_MODAL_TOGGLE: 'REGISTER_MODAL_TOGGLE',
  GET_ITEM_LIST: 'GET_ITEM_LIST',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SHOW_TOAST_SUCCESS: 'SHOW_TOAST_SUCCESS',
  RESET_TOAST: 'RESET_TOAST',
  RESET_ERROR_TOAST: 'RESET_ERROR_TOAST',
  RESET_APP: 'RESET_APP'
};
