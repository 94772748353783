/// <reference path="typings.d.ts" />

import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import deepForceUpdate from "react-deep-force-update";
import { I18nextProvider } from "react-i18next";
import createFetch from "./utils/createFetch";
import createStore from "./utils/createStore";
import App from "./App";
import ClientRoutes from "./router";
import i18n from "./utils/i18nClient";

const store = createStore(window.__INITIAL_STATE__);
delete window.__INITIAL_STATE__;

// TODO this context is cargo culted from the boilerplate, do we really want this?
// context should be chosen with care, and I don't think that the store should be on the context
//
//
// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles: Array<any>) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  fetch: createFetch(self.fetch, {
    // TODO why app is on window? where is it defined? do we really care about fetch?
    baseUrl: (window as any).App.apiUrl,
  } as any),
  store,
};

const container = document.getElementById("app");
let appInstance;

try {
  appInstance = ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App context={context}>
        <ClientRoutes />
      </App>
    </I18nextProvider>,
    container
  );
} catch (error) {
  if (__DEV__) {
    throw error;
  }
}

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept("./router", () => {
    if (appInstance) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }
  });
}
