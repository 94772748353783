import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { History } from 'history';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import BaseButton from '../../../../../toolkit/baseButton';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { hasDataManagerRole } from '../../../../../utils/helpers';

interface Props {
  datasetId: string;
  location: Location;
  history: History;
  datasetInfo: any;
  isPublished: boolean;
  userRoles: string[];
  t: (str: string) => string;
}

class DatasetItemsToolbar extends Component<Props> {
  render() {
    const { history, t, datasetInfo, datasetId, isPublished, userRoles } = this.props;
    const isDataManager = hasDataManagerRole(userRoles);
    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem>
              <Link to="/datasets">{t('datasets.page-title')}</Link>
            </BreadcrumbItem>
            {datasetInfo && (
              <BreadcrumbItem active>{datasetInfo.name}</BreadcrumbItem>
            )}
          </Breadcrumb>
        </section>
        <section className="controls-zone">
          {isDataManager && datasetInfo && (
            <BaseButton
              onClick={() => history.push(`/datasets/${datasetId}/items/add`)}
              size="sm"
              color="primary"
              data-elm-id={'add_items_to_dataset_button'}
              disabled={isPublished}>
              {t('dataset-items.add-button')}
            </BaseButton>
          )}
        </section>
      </>
    );
  }
}

export default withRouter(translate('translations')(DatasetItemsToolbar));
