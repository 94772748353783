import { connect } from 'react-redux';
import DocumentList from './component';
import {
  getDocuments,
  getTotalPages,
  getCurrentPage,
  getPageSize,
  hasError,
  isLoading
} from '../../../reducers/documents/selectors';
import { requestDocuments } from '../../../actions/creators';

const mapStateToProps = state => ({
  isLoading: isLoading(state),
  hasError: hasError(state),
  documents: getDocuments(state),
  currentPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  totalPages: getTotalPages(state)
});
const mapDispatchToProps = (dispatch, ownProps) => {
  const {collectionId} = ownProps
  return {
    requestDocuments: (page, size, sort) => dispatch(requestDocuments(collectionId, page, size, sort))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentList);
