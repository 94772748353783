import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Login from "../../components/login";
import Layout from "../../../_Home/components/layout";

import * as actions from "../../actions";
import pattern from "../../../media/img/pttrn-blue.png";

import s from "./styles.scss";

interface Props {
  t: (str: string) => string;
  setUserContext: (userContext) => void;
}

class LoginPage extends React.Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <main className={s.root} style={{ backgroundImage: `url(${pattern})` }}>
        <Layout
          title={t("login.title")}
          header={false}
          footer={{ bright: true, borderTop: false }}
          content={<Login setUserContext={this.props.setUserContext} />}
        ></Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setUserContext: (userContext = null) => {
    dispatch(actions.setUserContext(userContext));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(translate("translations")(withStyles(s)(LoginPage)));
