import { connect } from 'react-redux';
import DatasetItems from './component';
import {
  deleteDatasetItems,
  requestDatasetItems,
  toggleSelectDatasetItem,
  toggleSelectAllDatasetItems,
  requestUserReviewProgress,
  requestDataset,
  requestDistribution
} from '../../../actions/creators';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';
import {
  getDatasetItems,
  getCurrentPage,
  getPageSize,
  getSelectedItems,
  getTotalItems,
  getTotalPages,
  hasError,
  isLoading,
  hasSelectAll,
  hasSelections,
  isPublished,
  getDatasetInfo
} from '../../../reducers/dataset/selectors';
import { getMyUserId, getMyRoles } from '../../../../_Login/reducers/selectors';

const mapStateToProps = state => ({
  items: getDatasetItems(state),
  isLoading: isLoading(state),
  currentPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  totalItems: getTotalItems(state),
  totalPages: getTotalPages(state),
  hasError: hasError(state),
  selectedItems: getSelectedItems(state),
  hasSelectAll: hasSelectAll(state),
  datasetInfo: getDatasetInfo(state),
  hasSelections: hasSelections(state),
  isPublished: isPublished(state),
  userId: getMyUserId(state),
  userRoles: getMyRoles(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleSelectDatasetItem: id => dispatch(toggleSelectDatasetItem(id)),
  toggleSelectAllDatasetItems: () => dispatch(toggleSelectAllDatasetItems()),
  requestDatasetItems: (datasetId: string, allLabels: boolean = false, page: number, size: number) =>
    dispatch(requestDatasetItems(datasetId, allLabels, page, size)),
  deleteDatasetItems(datasetId: string, ids: string[]) {
    dispatch(
      deleteDatasetItems(datasetId, ids, {
        onSuccess: () => {
          const { t } = this.props;
          dispatch(showToastSuccess(t('dataset-items.delete.success')));
          dispatch(requestDatasetItems(datasetId));
          dispatch(requestDataset(datasetId));
          dispatch(requestDistribution(datasetId));
          dispatch(requestUserReviewProgress(datasetId, ownProps.userId));
        },
        onFailure: () => {
          const { t } = this.props;
          dispatch(toggleErrorModal(t('dataset-items.delete.failure')));
        }
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetItems);
