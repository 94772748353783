import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Layout from '../../../_Home/components/layout';
import { ModelCompareContainer } from '../../components/model/compare';
import { ModelCompareToolbarContainer as ModelCompareToolbar } from '../../components/model/compare/toolbar';
import { isTransactionUnderway, getSelectedModels } from '../../reducers/models/selectors';
import TransactionOverlay from '../../../toolkit/transactionOverlay';

interface Props {
  isTransactionUnderway: boolean;
  t: (str: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state),
  selectedModels: getSelectedModels(state),
});

class ModelsPage extends Component<Props> {
  render() {
    const { t, isTransactionUnderway } = this.props;
    return (
      <>
        <Layout
          title={t('models.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<ModelCompareToolbar {...this.props} />}
          content={<ModelCompareContainer {...this.props} />}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(connect(mapStateToProps)(ModelsPage));
