import { connect } from 'react-redux';
import DatasetNew from './component';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';
import {
  requestTaxonomies,
  requestModels,
  createDataset
} from '../../../actions/creators';
import { getMyCompanyId, getMyRoles } from '../../../../_Login/reducers/selectors';
import {
  getTaxonomiesAsSelectOptions,
  isLoading,
  hasLoadError
} from '../../../reducers/dictionaries/selectors';
import {
  getModelsAsSelectOptions,
  isModelsLoading,
  hasModelsError
} from '../../../reducers/dictionaries/selectors';
import { CreateDatasetRequest } from '../../../../types-business/Dataset';

const mapStateToProps = state => ({
  myCompanyId: getMyCompanyId(state),
  taxonomies: getTaxonomiesAsSelectOptions(state),
  models: getModelsAsSelectOptions(state),
  isLoading: isLoading(state) || isModelsLoading(state),
  hasLoadError: hasLoadError(state) || hasModelsError(state),
  userRoles: getMyRoles(state)
});

const mapDispatchToProps = dispatch => ({
  requestTaxonomies: () => {
    dispatch(requestTaxonomies());
  },
  requestModels: () => {
    dispatch(requestModels(0, 200, 'name,ASC', 'published:true'));
  },
  handleCreateDataset(dataset: CreateDatasetRequest) {
    dispatch(
      createDataset(dataset, {
        onSuccess: () => {
          const { t, history } = this.props;
          history.push('/datasets');
          dispatch(showToastSuccess(t('datasets.new.success')));
        },
        onFailure: () => {
          const { t } = this.props;
          dispatch(toggleErrorModal(t('datasets.new.failure')));
        }
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetNew);
