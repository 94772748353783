import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { translate } from 'react-i18next';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { compose, MutationFunc, withApollo, ApolloClient } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import s from './styles.scss';
import { UserContext } from '../../../types-business/Profile';
import { logout } from '../../mutations/logoutMutation';
import * as Utils from '../../../utils/helpers';

interface Props {
  t: (str: string) => string;
  history: any;
  location: any;
  client: ApolloClient;
  setUserContext: (userContext: UserContext) => void;
  toggleErrorModal: (error: string) => void;
  userRoles: string[];
  userContext?: UserContext;
  logout: MutationFunc<{
    logout: boolean;
  }>;
  smallHeader: boolean;
  redirectToLogin: boolean;
  resetApp: () => void;
}

interface State {
  isOpen: boolean;
  dropDownOpen: boolean;
}

class Navigation extends React.Component<Props, State> {
  state = {
    isOpen: false,
    dropDownOpen: false
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  toggleDropDown = () => {
    this.setState({
      dropDownOpen: !this.state.dropDownOpen
    });
  };

  async componentWillReceiveProps(nextProps: Props) {
    if (!this.props.redirectToLogin && nextProps.redirectToLogin) {
      await this.props.client.resetStore();
      this.props.history.push('/login');
    }
  }

  onLogout = () => {
    const { logout, setUserContext, history, client, resetApp } = this.props;

    logout({})
      .then(({ data }) => {
        if (data.logout) {
          client.resetStore();
          history.push('/login');
          setUserContext(null);
          resetApp();
        }
      })
      .catch(() => {
        this.props.toggleErrorModal(null);
      });
  };

  render() {
    const { t, smallHeader,  userRoles } = this.props;
    const isDataManager = Utils.hasDataManagerRole(userRoles);

    return (
      <Navbar className="m-0 p-0" color="faded" dark expand="lg">
        {this.props.children}

        <NavbarToggler onClick={this.toggle} className={s.navBarToggler} />
        <Collapse className="row" isOpen={this.state.isOpen} navbar>
          <Nav className="col-lg-12 p-l-15 row m-0" navbar>
            {isDataManager && (<NavItem>
              <NavLink
                to="/collections"
                className={s.navLink}
                activeClassName={
                  Utils.isActiveLocation('/collections', true)
                    ? s.linkActive
                    : ''
                }
                data-elm-id="navigation_collections">
                {t('collections.page-title')}
              </NavLink>
            </NavItem>)}
            <NavItem>
              <NavLink
                to="/datasets"
                className={s.navLink}
                activeClassName={
                  Utils.isActiveLocation('/datasets', true) ? s.linkActive : ''
                }
                data-elm-id="navigation_datasets">
                {t('datasets.page-title')}
              </NavLink>
            </NavItem>
            {isDataManager && (<NavItem>
              <NavLink
                to="/models"
                className={s.navLink}
                activeClassName={
                  Utils.isActiveLocation('/models', true) ? s.linkActive : ''
                }
                data-elm-id="navigation_models">
                {t('models.page-title')}
              </NavLink>
            </NavItem>)}
            {this.props.userContext && (
              <Dropdown
                className={s.dropdown}
                isOpen={this.state.dropDownOpen}
                toggle={this.toggleDropDown}>
                <DropdownToggle
                  data-elm-id="navigation_username"
                  className={`${s.dropdownOption} float-right ${
                    smallHeader ? s.dropdownOptionSmall : ''
                  }`}
                  color="link">
                  {`${this.props.userContext.firstName} ${
                    this.props.userContext.lastName
                  } `}
                  &nbsp;
                  <i className="fa fa-angle-down low-risk-color">&nbsp;</i>
                </DropdownToggle>
                <DropdownMenu className="col-lg-2" right>
                  <Link
                    data-elm-id="navigation_edit_profile"
                    to="/profile/edit">
                    <DropdownItem
                      className={classNames(
                        'p-t-10 p-b-10 low-risk-color',
                        s.dropdownItem
                      )}>
                      {t('navigation.option-profile')}
                    </DropdownItem>
                  </Link>
                  <Link
                    data-elm-id="navigation_help"
                    target="_blank"
                    to="/contact">
                    <DropdownItem
                      className={classNames(
                        'p-t-10 p-b-10  low-risk-color',
                        s.dropdownItem
                      )}>
                      {t('globals.help-option')}
                    </DropdownItem>
                  </Link>
                  <DropdownItem
                    className={classNames(
                      'p-t-10 p-b-10 low-risk-color',
                      s.dropdownItem
                    )}
                    data-elm-id="navigation_logout"
                    onClick={this.onLogout}>
                    {t('navigation.option-logout')}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export const TestableNavigation = withStyles(s)(Navigation);
export default compose(logout())(
  withApollo(withRouter(translate('translations')(withStyles(s)(Navigation))))
);
