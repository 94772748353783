exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3K_XF{width:80%;max-width:80%}._3K_XF .modal-body{overflow-y:auto;max-height:calc(100vh - 200px);min-height:calc(100vh - 200px)}.CO5r7 .add-items-restrictions{margin-bottom:0}.CO5r7 ._3E4zX{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.CO5r7 ._3E4zX>div{width:100%;margin-right:10px}.CO5r7 ._3E4zX>div textarea{height:90px;resize:none}._3Cgaw{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._3Cgaw>div{width:100%;margin-right:10px}", ""]);

// exports
exports.locals = {
	"addItemsModal": "_3K_XF",
	"addItemsForm": "CO5r7",
	"restrictions": "_3E4zX",
	"inlineForm": "_3Cgaw"
};