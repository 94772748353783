import { connect } from 'react-redux';

import DatasetReview from './component';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';
import {
  acceptLabelSuggestion,
  rejectLabelSuggestion,
  focusItem,
  focusLastItemInPage,
  focusNextReviewItem,
  focusPreviousReviewItem,
  requestDataset,
  requestDatasetItems,
  revertLabel,
  requestUserReviewProgress,
} from '../../../actions/creators';
import {
  getReviewItems,
  getCurrentPage,
  getPageSize,
  getTotalItems,
  getTotalPages,
  hasError,
  isDatasetInfoLoading,
  isLoading,
  getDatasetInfo,
  getFocusItemId,
  getAcceptedSuggestions,
  getRejectedSuggestions,
  getReviewSelectOptions,
  isAddLabelUnderway,
  isInLabelReviewMode
} from '../../../reducers/dataset/selectors';
import { getMyUserId } from '../../../../_Login/reducers/selectors';

const handleLabelFailure = dispatch => {
  return error => {
    const defaultErrorMessage =
      'There was a problem processing with your selection';
    if (error.message) {
      const errorJsonString = error.message.substring(
        error.message.indexOf('{'),
        error.message.indexOf('}') + 1
      );
      try {
        const errorObj = JSON.parse(errorJsonString);
        dispatch(toggleErrorModal(errorObj.message));
      } catch (e) {
        dispatch(toggleErrorModal(error.message));
      }
    } else {
      dispatch(toggleErrorModal(defaultErrorMessage));
    }
  };
};

const mapStateToProps = state => ({
  acceptedSuggestions: getAcceptedSuggestions(state),
  rejectedSuggestions: getRejectedSuggestions(state),
  currentPage: getCurrentPage(state),
  datasetInfo: getDatasetInfo(state),
  focusItemId: getFocusItemId(state),
  hasError: hasError(state),
  items: getReviewItems(state),
  isLoading: isLoading(state),
  pageSize: getPageSize(state),
  totalItems: getTotalItems(state),
  totalPages: getTotalPages(state),
  selectOptions: getReviewSelectOptions(state),
  isDatasetInfoLoading: isDatasetInfoLoading(state),
  isAddLabelUnderway: isAddLabelUnderway(state),
  isInLabelReviewMode: isInLabelReviewMode(state),
  myUserId: getMyUserId(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  accept: (datasetId: string, componentId: string, taxonomyValueId: string, start = null, end = null) => {
    const { history } = ownProps;
      dispatch(
        acceptLabelSuggestion(datasetId, componentId, taxonomyValueId, start, end, {
          onSuccess() {
            dispatch(focusNextReviewItem(history));
            dispatch(
              requestUserReviewProgress(ownProps.datasetId, ownProps.userId)
            );
          },
          onFailure: handleLabelFailure(dispatch)
        })
      );
  },
  reject(datasetId: string, componentId: string, taxonomyValueId: string) {
    const { history } = ownProps;
    dispatch(
      rejectLabelSuggestion(datasetId, componentId, taxonomyValueId, {
        onSuccess() {
          dispatch(focusNextReviewItem(history));
          dispatch(
            requestUserReviewProgress(ownProps.datasetId, ownProps.userId)
          );
        },
        onFailure: handleLabelFailure(dispatch)
      })
    );
  },
  revert(labelId: string, taxonomyValueId: string, isInLabelReviewMode: boolean) {
    const onSuccess = () => {
      // only show the revert toastify message if the user is in normal (not label review) mode, since
      // it gets annoying when deleting a large number of labels while in review mode.
      if (!isInLabelReviewMode) {
        const { t } = ownProps;
        dispatch(showToastSuccess(t('dataset-review.revert-succeeded')));
      }
      dispatch(requestUserReviewProgress(ownProps.datasetId, ownProps.userId));
    };
    const onFailure = () => {
      const { t } = ownProps;
      dispatch(toggleErrorModal(t('dataset-review.revert-failed')));
    };
    dispatch(
      revertLabel(labelId, taxonomyValueId, {
        onSuccess,
        onFailure
      })
    );
  },
  focusItem: id => dispatch(focusItem(id)),
  focusLastItemInPage: () => dispatch(focusLastItemInPage()),
  focusNextReviewItem() {
    dispatch(focusNextReviewItem(ownProps.history));
  },
  focusPreviousReviewItem() {
    dispatch(focusPreviousReviewItem(ownProps.history));
  },
  requestDataset: (datasetId: string) => dispatch(requestDataset(datasetId)),
  requestDatasetItems(
    datasetId: string,
    allLabels: boolean,
    page: number,
    size: number,
    callbacks: any = { onSuccess: () => {}, onFailure: () => {} }
  ) {
    dispatch(requestDatasetItems(datasetId, allLabels, page, size, callbacks));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetReview);
