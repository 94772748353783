exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2ehZe{background:#1c1c43;color:#fff}.CBNrP{height:80px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;padding:0}._3tMFy{height:32px;width:153px;margin-right:60px}._3tMFy:hover{cursor:pointer}._13bRe{height:32px}._13bRe ._3tMFy{height:16px;width:76.34px}", ""]);

// exports
exports.locals = {
	"root": "_2ehZe",
	"navBar": "CBNrP",
	"logo": "_3tMFy",
	"smallHeader": "_13bRe"
};