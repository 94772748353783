exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PH8A{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;position:absolute;top:0;bottom:0;left:0;right:0;background-color:#000;opacity:.5;z-index:2000}", ""]);

// exports
exports.locals = {
	"transactionOverlay": "_3PH8A"
};