import React, { Component } from 'react';
import s from './styles.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';

interface Props {
  message: string;
  className?: string;
  type?: string;
  testId?: string;
}

class Message extends Component<Props> {
  render() {
    const { className, message, type, testId } = this.props;
    const iconName = type || 'error';

    return (
      <div className={classNames(s.message, className, s[iconName])}>
        <div>
          <i className={classNames('material-icons')}>{iconName}</i>
          <span data-testid={testId || 'error-message'} className={s.text}>
            {message}
          </span>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Message);
