import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Layout from '../../../_Home/components/layout';
import { isTransactionUnderway } from '../../reducers/models/selectors';
import TransactionOverlay from '../../../toolkit/transactionOverlay';
import { ModelDetailContainer } from '../../components/model/detail';
import { ModelDetailToolbarContainer } from '../../components/model/detail/toolbar';

interface Props {
  isTransactionUnderway: boolean;
  t: (str: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state)
});

class ModelDetailsPage extends Component<Props> {
  render() {
    const { t, isTransactionUnderway } = this.props;
    return (
      <>
        <Layout
          title={t('models.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<ModelDetailToolbarContainer {...this.props}/>}
          content={<ModelDetailContainer {...this.props} />}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(connect(mapStateToProps)(ModelDetailsPage));
