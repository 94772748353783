exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._3oTL3{margin:20px 0}._3oTL3 h5{color:#4c5670;font-size:16px;font-size:1rem;font-weight:700}._3oTL3 ul{margin:0;padding:0 10px 0 0}._3oTL3 ul li{list-style:none}a._3AVZD:not([href]){display:inline-block;position:relative;color:#0174ca;cursor:pointer;padding:3px 10px}a._3AVZD:not([href]) ._3IX-v{width:100%;white-space:nowrap;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis;display:inline-block}a._3AVZD:not([href]):hover{color:#0174ca}a._3AVZD:not([href])._22_dt{background-color:#005899;color:#fff}a._3AVZD:not([href])._22_dt:hover{color:#fff}a._3AVZD:not([href])._22_dt ._3IX-v{width:225px}._2Tjz-{display:inline-block;position:absolute;right:0;top:4px}._2Tjz- .badge{position:relative;bottom:8px}._2Tjz- .material-icons{color:#fff}._2Tjz- .SeWfh{color:#a9a9a9}", ""]);

// exports
exports.locals = {
	"taxonomy": "_3oTL3",
	"listItem": "_3AVZD",
	"labelValue": "_3IX-v",
	"isSelected": "_22_dt",
	"navigationControls": "_2Tjz-",
	"isDisabled": "SeWfh"
};