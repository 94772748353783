import { connect } from 'react-redux';

import {
  getDocumentName,
  getCollectionName
} from '../../../../reducers/document/selectors';
import DocumentViewToolbar from './component';

const mapStateToProps = state => ({
  documentName: getDocumentName(state),
  collectionName: getCollectionName(state)
});

export default connect(mapStateToProps)(DocumentViewToolbar);
