import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import {
  MODELS_REQUEST,
  MODELS_TRAINING_PIPELINES_REQUEST,
  MODELS_DATASETS_REQUEST,
  MODEL_TRAIN,
  MODEL_DETAIL_REQUEST,
  MODEL_TOGGLE_SELECT,
  MODEL_CLEAN_SELECT,
  MODEL_TAXONOMY_VALUES_REQUEST
} from '../../actions/types';

const intialState = {
  // all models
  models: [],
  // fetch status for models: loading, error, completed
  modelsStatus: null,
  // current page number
  currentPage: 0,
  // number of documents to display on each page
  pageSize: 20,
  // total items
  totalItems: 0,
  // indicates whether a transaction is ongoing and screen should be blocked
  isTransactionUnderway: false,
  // list of training pipelines
  trainingPipelines: [],
  // status of training pipelines call: loading, error, completed
  trainingPipelinesStatus: null,
  // list of datasets to use for model creation
  datasets: [],
  // status of datasets call: loading, error, completed
  datasetsStatus: null,
  //Single model
  model: {},
  // fetch status for model: loading, error, completed
  modelStatus: null,
  // ModelCompare selected models
  selectedModels: [],
  selectedTaxonomy: '',
  taxonomiesLoading: false,
  taxonomyValues: null,
};

export default handleActions(
  {
    [MODELS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('modelsStatus', 'loading'),
        failure: s => s.set('modelsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getModels;
          return s.merge({
            modelsStatus: 'completed',
            models: result.data,
            totalItems: result.meta.totalItems,
            collection: result.meta.collection,
            pageSize: result.meta.pagination.pageSize,
            currentPage: result.meta.pagination.page
          });
        }
      });
    },
    [MODEL_DETAIL_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('modelStatus', 'loading'),
        failure: s => s.set('modelStatus', 'error'),
        success: s => {
          const result = action.payload.data;
          return s.merge({
            modelStatus: 'completed',
            model: {
              ...state.model,
              [result.id]: {...result}
            },
          });
        }
      });
    },
    [MODELS_TRAINING_PIPELINES_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('trainingPipelinesStatus', 'loading'),
        failure: s => s.set('trainingPipelinesStatus', 'error'),
        success: s => {
          const result = action.payload.data.getTrainingPipelines;
          return s.merge({
            trainingPipelinesStatus: 'completed',
            trainingPipelines: result.data
          });
        }
      });
    },
    [MODELS_DATASETS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('datasetsStatus', 'loading'),
        failure: s => s.set('datasetsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getPublishedDatasets;
          return s.merge({
            datasetsStatus: 'completed',
            datasets: result.data
          });
        }
      });
    },
    [MODEL_TRAIN]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          return s.merge({
            isTransactionUnderway: false
          });
        }
      });
    },
    [MODEL_TOGGLE_SELECT]: (state, { payload: { id, taxonomyId } }) => {
      const selectedModels = state.selectedModels.includes(id)
        ? state.selectedModels.filter(item => item !== id)
        : [...state.selectedModels, id];
      const selectedTaxonomy = selectedModels.length > 0 ? taxonomyId : ''
      return state.merge({
        selectedModels,
        selectedTaxonomy
      });
    },
    [MODEL_TAXONOMY_VALUES_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('taxonomiesLoading', true),
        failure: s => s.set('taxonomiesLoading', false),
        success: s => {
          const values = action.payload.data.content;
          const taxonomyValues = {};
          values.forEach(item => taxonomyValues[item.id] = item);
          return s.merge({
            taxonomyValues
          });
        }
      });
    },
    [MODEL_CLEAN_SELECT]: (state) => {
      return state.merge({
        selectedModels: [],
        selectedTaxonomy: ''
      });
    }
  },
  Immutable.from(intialState)
);
