import IAction from '../../../types/IAction';
import {
  COLLECTIONS_REQUEST,
  COLLECTION_CREATE,
  DOCUMENTS_REQUEST,
  DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT,
  DOCUMENT_VIEW_LABEL_SELECT,
  DOCUMENT_VIEW_SENTENCE_LABELS_REQUEST,
  DOCUMENT_VIEW_CLEAR_SELECTED_LABEL,
  DOCUMENT_TYPES_REQUEST,
  DOCUMENT_LABELS_REQUEST
} from '../types';
import {
  fetchCollections,
  newCollection,
  fetchDocument,
  fetchDocuments,
  uploadDocument as upload,
  fetchSentenceLabels,
  fetchDocumentLabels
} from '../../api';
import { NOOP_CALLBACKS } from './util';
import { IActionThunk } from '../../../types/IActionThunk';
import { fetchTaxonomyValues } from '../../../_Datasets/api';
import constants from '../../../utils/constants';


export function requestCollections(
  page: number = 0,
  size: number = 20
): IAction {
  return {
    type: COLLECTIONS_REQUEST,
    promise: fetchCollections(page, size)
  };
}

export function createCollection(
  collectionName: string,
  companyId: number,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: COLLECTION_CREATE,
    promise: newCollection(collectionName, companyId),
    meta: { onSuccess, onFailure }
  };
}

export function requestDocument(collectionId, documentId): IAction {
  return {
    type: DOCUMENT_REQUEST,
    promise: fetchDocument(collectionId, documentId)
  };
}

export function requestDocumentLabels(documentId): IAction {
  return {
    type: DOCUMENT_LABELS_REQUEST,
    promise: fetchDocumentLabels(documentId)
  };
}

export function requestDocumentTypes(
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: DOCUMENT_TYPES_REQUEST,
    promise: fetchTaxonomyValues(constants.TAXONOMY.DOCUMENT_TYPE_ID),
    meta: { onSuccess, onFailure }
  }
}
export function requestDocuments(
  collectionId: string,
  page: number = 0,
  size: number = 20,
  sort: string
): IAction {
  return {
    type: DOCUMENTS_REQUEST,
    promise: fetchDocuments(collectionId, page, size, sort)
  };
}

export function uploadDocument(
  collectionId: string,
  file: File,
  documentTypeId: string,
  callbacks = NOOP_CALLBACKS
): IAction {
  const { onSuccess, onFailure } = callbacks;
  return {
    type: UPLOAD_DOCUMENT,
    promise: upload(collectionId, file, documentTypeId),
    meta: { onSuccess, onFailure }
  };
}

export function selectDocumentViewLabel(taxonomyId, value): IActionThunk {
  return (dispatch, getState) => {
    dispatch({
      type: DOCUMENT_VIEW_LABEL_SELECT,
      payload: { taxonomyId, value }
    });

    const state: any = getState();
    const taxonomy = state.document.labelMap[taxonomyId];
    const sentences = taxonomy.values[value];
    const [firstSentenceId] = sentences;
    dispatch(requestSentenceLabels(firstSentenceId));
  };
}

export function requestSentenceLabels(sentenceId: string): IAction {
  return {
    type: DOCUMENT_VIEW_SENTENCE_LABELS_REQUEST,
    payload: sentenceId,
    promise: fetchSentenceLabels(sentenceId)
  };
}

export function focusSentence(sentenceId: string): IActionThunk {
  return dispatch => {
    dispatch(clearSelectedLabel());
    dispatch(requestSentenceLabels(sentenceId));
  };
}

export function clearSelectedLabel(): IAction {
  return {
    type: DOCUMENT_VIEW_CLEAR_SELECTED_LABEL
  };
}
