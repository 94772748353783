import { connect } from 'react-redux';
import DocumentUpload from './component';
import { uploadDocument as actionUpload, requestDocumentTypes } from '../../../actions/creators';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';
import { getDocumentTypes as  getDocumentTypesSelector} from '../../../reducers/collections/selectors';
const mapStateToProps = (state) => {
  return {
    documentTypes: getDocumentTypesSelector(state),
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadDocument: (collectionId, file, documentTypeId) => {
    const { history, t } = ownProps;

    dispatch(
      actionUpload(collectionId, file, documentTypeId, {
        onSuccess: () => {
          history.push(`/collections/${collectionId}/documents`);
          dispatch(
            showToastSuccess(t('documents.upload.upload-succeeded-message'))
          );
        },
        onFailure: () => {
          dispatch(
            toggleErrorModal(t('documents.upload.upload-failed-message'))
          );
        }
      })
    );
  },
  getDocumentTypes: () => {
    const { t } = ownProps;
    dispatch(
      requestDocumentTypes({
        onSuccess: () => {},
        onFailure: () => {
          dispatch(
            toggleErrorModal(t('collections.requestDocumentTypes.failure'))
          );
        }
      })
    )
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentUpload);
