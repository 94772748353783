import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../detail/styles.scss';
import {ModelDetailContainer} from '../detail';
//import { ModelDetailContainer } from '../detail';
interface Props {
  isLoading: boolean;
  selectedModels: string[];
  t: (key: string) => string;
}

class ModelCompare extends Component<Props> {
  render() {
    const { t, isLoading, selectedModels  } = this.props;

    return (
      <main>
        { selectedModels.map(modelId => {
          return (
            <div key={modelId}> 
              <ModelDetailContainer modelId={modelId} compare/>
            </div>
          )
        })

        }
        {isLoading &&
          <article className={s['loading-overlay']}>
            <div>{ t('model.loading') }</div>
          </article>
        }
      </main>
    );
  }
}

export default translate('translations')(
  withStyles(s)(ModelCompare)
);
