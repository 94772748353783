import { connect } from 'react-redux';

import ModelCompareToolbar from './component';
import { getModelDetails } from '../../../../reducers/models/selectors';

const mapStateToProps = (state, ownProps) => {
  const params = ownProps.location &&
    ownProps.location.search;
  const stringSelectedModels = params.replace(/%22/g,'"').replace('?selectedModels=',"");
  const selectedFromState = ownProps.selectedModels;
  const selectedModels =  selectedFromState.length === 2
    ? selectedFromState
    : JSON.parse(stringSelectedModels);
  const [model1Id, model2Id] = selectedModels;
  return {
    model1: getModelDetails(state, {modelId: model1Id}),
    model2: getModelDetails(state, {modelId: model2Id})
  };
 }
export const ModelCompareToolbarContainer = connect(mapStateToProps)(ModelCompareToolbar);
