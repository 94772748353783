import { gql } from 'react-apollo';

export default gql`
  mutation($datasetId: String!) {
    publishDataset(datasetId: $datasetId) {
      data {
        id
        name
        type
        itemCount
        componentType
        createdDate
        taxonomy {
          value
        }
        suggestionModel {
          name
        }
        publishingStatus
        description
      }
    }
  }
`;
