import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Route } from 'react-router-dom';

import Layout from '../../../_Home/components/layout';
import { DatasetItemsContainer } from '../../components/dataset/items';
import { DatasetDetailsContainer } from '../../components/dataset/details';
import { DatasetItemsToolbarContainer } from '../../components/dataset/items/toolbar';
import { DatasetAddItemsContainer } from '../../components/dataset/addItems';
import TransactionOverlay from '../../../toolkit/transactionOverlay';
import { Location } from 'history';
import { isTransactionUnderway } from '../../reducers/dataset/selectors';
import { getMyUserId } from '../../../_Login/reducers/selectors';

interface Props {
  location: Location;
  datasetId: string;
  isTransactionUnderway: boolean;
  userId: number;
  t: (key: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state),
  userId: getMyUserId(state)
});

class DatasetDetailsPage extends Component<Props> {
  render() {
    const { t, isTransactionUnderway } = this.props;
    return (
      <>
        <Layout
          title={t('datasets.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<DatasetItemsToolbarContainer {...this.props} />}
          content={<DatasetItemsContainer {...this.props} />}
          sidebarShowRoute={`/datasets/:datasetId/items`}
          SidebarComponent={DatasetDetailsContainer}
          sidebarProps={this.props}
        />
        <Route
          exact
          path="/datasets/:datasetId/items/add"
          render={() => <DatasetAddItemsContainer {...this.props} />}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(
  connect(mapStateToProps)(DatasetDetailsPage)
);
