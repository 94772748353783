import { gql } from 'react-apollo';

export default gql`
  query GetDatasetDistribution($datasetId: String!) {
    getDatasetDistribution(datasetId: $datasetId) {
      data {
        taxonomyValueId
        taxonomyValue
        count
      }
    }
  }
`;
