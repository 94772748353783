import React, { ComponentClass } from 'react';
import Helmet from 'react-helmet';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import classNames from 'classnames';
import { Route, Link, matchPath } from 'react-router-dom';
import { withRouter, match } from 'react-router';
import { Location, History } from 'history';

import s from './styles.scss';
import LayoutStyles from '../../../toolkit/baseStyles';
import Header from '../header';
import Footer from '../footer';
import Modals from '../../containers/modals';

interface Props {
  location: Location;
  match: match;
  history: History;
  title: string;
  header: boolean;
  modals: boolean;
  footer: any;
  smallHeader: boolean;
  isFluidFooter: boolean;
  t: (str: string) => string;
  fixedHeight: boolean;
  layoutClass: string;

  toolbar: ComponentClass;
  content: ComponentClass;

  sidebarShowRoute: string;
  sidebarCloseRoute: string;
  SidebarComponent: ComponentClass;
  sidebarProps: any;
}

interface State {
  headerHeight: number;
  footerHeight: number;
}

class Layout extends React.Component<Props, State> {
  static defaultProps = {
    title: 'Akorda',
    header: true,
    modals: true,
    smallHeader: false,

    fixedHeight: false,

    footer: {
      fullWidth: true,
      compact: false,
      bright: false,
      borderTop: true,
      className: null
    }
  };

  state = {
    headerHeight: 0,
    footerHeight: 0
  };

  componentDidMount() {
    const headerEl = document.querySelector('[data-elm-id=layout_header]');
    const headerHeight = headerEl ? headerEl.getBoundingClientRect().height : 0;

    const footerEl = document.querySelector('[data-elm-id=layout_footer]');
    const footerHeight = footerEl ? footerEl.getBoundingClientRect().height : 0;

    this.setState({
      headerHeight,
      footerHeight
    });
  }

  hasSidebar() {
    const { location, sidebarShowRoute } = this.props;
    const match = matchPath(location.pathname, {
      path: sidebarShowRoute,
      exact: true
    });
    return !!match;
  }

  renderSidebar() {
    const {
      location,
      sidebarShowRoute,
      sidebarCloseRoute,
      SidebarComponent,
      sidebarProps
    } = this.props;

    // const SidebarComponent = sidebarComponent;

    if (SidebarComponent && sidebarShowRoute && location) {
      return (
        <aside
          className={classNames(s.sidePanel, {
            [s.sidePanelOpen]: this.hasSidebar()
          })}>
          {sidebarCloseRoute && (
            <section className={s.sidebarHeader}>
              <Link className={s.sidePanelCloseButton} to={sidebarCloseRoute}>
                <i className="material-icons">close</i>
              </Link>
            </section>
          )}

          <section className={s.sidePanelContent}>
            <Route
              path={sidebarShowRoute}
              render={() => <SidebarComponent {...sidebarProps} />}
            />
          </section>
        </aside>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      title,
      header,
      footer,
      modals,
      smallHeader,
      fixedHeight,
      layoutClass
    } = this.props;
    const { footerHeight, headerHeight } = this.state;

    const windowHeight = `calc(100vh - ${footerHeight + headerHeight}px)`;
    const fixedHeightStyles: any = {
      height: windowHeight
    };
    const dynamicHeightStyles: any = {
      minHeight: windowHeight
    };
    const sidebar = this.renderSidebar();

    return (
      <LayoutStyles className={classNames(layoutClass)}>
        <Helmet title={title} />
        {header && <Header {...this.props} smallHeader={smallHeader} />}

        <section
          className={classNames(layoutClass)}
          data-elm-id="layout_content"
          style={fixedHeight ? fixedHeightStyles : dynamicHeightStyles}>
          {this.props.toolbar && (
            <section className={s.toolbar}>{this.props.toolbar}</section>
          )}

          <section className={classNames(s.mainContent, 'main-page-content')}>
            <section
              className={classNames(s.primary, 'primary-content', {
                'sidebar-open': this.hasSidebar()
              })}>
              {this.props.content}
            </section>
            {this.props.children}

            {sidebar}
          </section>

          {modals && <Modals />}
        </section>

        {footer && <Footer t={this.props.t} {...footer} />}
      </LayoutStyles>
    );
  }
}

export const TestableLayout = withStyles(s)(Layout);
export default translate('translations')(withStyles(s)(withRouter(Layout)));
