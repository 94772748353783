import React from "react";
import { Container } from "reactstrap";
import { translate } from "react-i18next";

interface Props {
  t: (str: string) => string;
}

class NotFound extends React.Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <Container className="text-center">
        <h1>{t("not-found.title")}</h1>
        <p>{t("not-found.description")}</p>
      </Container>
    );
  }
}

export const TestableNotFoundPage = NotFound;
export default translate("translations")(NotFound);
