import React from "react";
// import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { NavbarBrand } from "reactstrap";
import classNames from "classnames";

import logoUrl from "../../../media/img/akorda-white-text@2x.png";
import Navigation from "../../containers/navigation";
import s from "./styles.scss";

interface Props {
  smallHeader: boolean;
  history: any;
}

class Header extends React.Component<Props, void> {
  render() {
    return (
      <header
        data-elm-id="layout_header"
        className={classNames("container-fluid p-l-35 p-r-35", s.root)}
      >
        <Navigation smallHeader={this.props.smallHeader}>
          <NavbarBrand
            data-elm-id="header_main_logo"
            className={classNames(s.navBar, {
              [s.smallHeader]: this.props.smallHeader,
            })}
          >
            <img
              onClick={() => {
                this.props.history.push("/");
              }}
              src={logoUrl}
              className={s.logo}
            />
          </NavbarBrand>
        </Navigation>
      </header>
    );
  }
}

export const TestableHeader = withStyles(s)(Header);
// export default withRouter(withStyles(s)(Header));
export default withStyles(s)(Header);
