import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Layout from '../../../_Home/components/layout';
import { DocumentViewContainer } from '../../components/document/view';
import { DocumentViewLabelsContainer } from '../../components/document/view/labels';
import { DocumentViewToolbarContainer } from '../../components/document/view/toolbar';
import s from './styles.scss';
import { DocumentViewSentenceDetailsContainer } from '../../components/document/view/sentence/details';
import { hasFocusSentence } from '../../reducers/document/selectors';

const mapStateToProps = state => ({
  hasFocusSentence: hasFocusSentence(state)
});

interface Props {
  collectionId: string;
  documentId: string;
  hasFocusSentence: boolean;
  t: (str: string) => string;
}

class DocumentViewPage extends Component<Props> {
  render() {
    const { t, documentId, collectionId, hasFocusSentence } = this.props;

    return (
      <Layout
        layoutClass={s.documentViewPage}
        title={t('document.page-title')}
        fixedHeight={true}
        footer={false}
        toolbar={<DocumentViewToolbarContainer {...this.props} />}
        content={
          <div
            className={classNames(s.documentView, {
              [s.hasFocusSentence]: hasFocusSentence
            })}>
            <aside className={s.labelList}>
              <DocumentViewLabelsContainer />
            </aside>
            <section className={s.documentContent}>
              <DocumentViewContainer
                documentId={documentId}
                collectionId={collectionId}
              />
            </section>
            <section className={classNames(s.sentenceDetails)}>
              <DocumentViewSentenceDetailsContainer />
            </section>
          </div>
        }
      />
    );
  }
}

export default translate('translations')(
  withStyles(s)(connect(mapStateToProps)(DocumentViewPage))
);
