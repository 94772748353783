import { connect } from 'react-redux';
import DatasetList from './component';
import { requestDatasets } from '../../../actions/creators';
import {
  getDatasets,
  getDatasetsStatus,
  getCurrentPage,
  getPageSize,
  getTotalItems,
  hasError,
} from '../../../reducers/datasets/selectors';

const mapStateToProps = state => ({
  items: getDatasets(state),
  isLoading: getDatasetsStatus(state) === 'loading',
  currentPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  totalItems: getTotalItems(state),
  hasError: hasError(state),
});

const mapDispatchToProps = dispatch => {
  return {
    requestDatasets: (page, size, sort) => dispatch(requestDatasets(page, size, sort)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetList);
