import React, { Component } from 'react';
import s from './styles.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import BaseButton from '../../toolkit/baseButton';
import { translate } from 'react-i18next';
import classNames from 'classnames';

interface Props {
  className: string;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages?: number;
  onPrevious: Function;
  onNext: Function;
  t: (key: string, options?: object) => string;
}
interface State {
  pageNumberInput: string;
  pageNumber: number;
}
class Pagination extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageNumberInput: `${props.pageNumber + 1}`,
      pageNumber: props.pageNumber
    }
  }
  totalPages() {
    const { totalItems, pageSize } = this.props;
    return Math.ceil(totalItems / pageSize);
  }

  hasPrevious() {
    return this.props.pageNumber > 0;
  }

  hasNext() {
    const totalPages = this.props.totalPages || this.totalPages();
    return this.props.pageNumber + 1 < totalPages;
  }
  changePage = () => {
    const {
      onNext,
    } = this.props;
    const {
      pageNumberInput,
    } = this.state;

    const totalPages = this.props.totalPages || this.totalPages();
    let castedValue = +pageNumberInput;
    if(castedValue > totalPages) {
      castedValue = totalPages;
    }
    if(castedValue < 1 || isNaN(castedValue)) {
      castedValue = 1;
    }
    this.setState({pageNumberInput: `${castedValue}`, pageNumber: castedValue - 1});
    onNext(castedValue - 1)
  }
  render() {
    const {
      totalItems,
      t,
      onPrevious,
      onNext,
      className
    } = this.props;
    const {
      pageNumberInput,
      pageNumber
    } = this.state;
    const currentPage = pageNumberInput;
    const totalPages = this.props.totalPages || this.totalPages();

    if (totalItems === 0) {
      return null;
    }
    return (
      <section className={classNames(s.pagination, className)}>
        <div className={s.previous}>
          <BaseButton
            onClick={() => {
              const page = pageNumber - 1;
              this.setState({
                pageNumberInput: `${page + 1}`,
                pageNumber: page
              });
              onPrevious(page)
            }}
            size="sm"
            data-elm-id={'previous_page'}
            outline={true}
            disabled={!this.hasPrevious()}>
            {t('globals.pagination.previous')}
          </BaseButton>
        </div>
        <div className={s.info}>
        {t('globals.pagination.page')}
        <input
          value={currentPage}
          onChange={({target: {value}}) => {
            this.setState({pageNumberInput: value});
          }}
          onBlur={this.changePage}
          onKeyDown={e => {
            if(e.key === 'Enter') {
              this.changePage()
            }
            if(/[0-9]/.test(e.key) ||
              e.key === 'Backspace' ||
              e.key.length > 1
            ) {
                return ;
            }
            e.preventDefault()
            return;
          }}
          />
        {t('globals.pagination.of', {totalPages})}
        </div>
        <div className={s.next}>
          <BaseButton
            onClick={() => {
              const page = pageNumber + 1;
              this.setState({
                pageNumberInput: `${page + 1}`,
                pageNumber: page
              });
              onNext(page)
            }}
            size="sm"
            data-elm-id={'next_page'}
            outline={true}
            disabled={!this.hasNext()}>
            {t('globals.pagination.next')}
          </BaseButton>
        </div>
      </section>
    );
  }
}

export default translate('translations')(withStyles(s)(Pagination));
