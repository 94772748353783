exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._35yr9{margin:10px;padding:3px 3px 3px 5px}._35yr9 mark{margin-left:3px;background-color:#0174ca;border-radius:4px;border:1px solid #005899;color:#fff}._3Lit6{padding-left:5px;line-height:35px;border-left:3px solid #51af46;background-color:#edf0f2;color:#1c1c43}", ""]);

// exports
exports.locals = {
	"sentence": "_35yr9",
	"isActive": "_3Lit6"
};