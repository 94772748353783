exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2kTwc,._3Cimb{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._2kTwc span,._3Cimb span{font-style:italic}._2CwVq{height:100%}._1Midn{height:calc(100% - 50px);margin-bottom:6px}._2VGhK{font-size:16px;font-size:1rem}._2VGhK ul{padding:0;margin:0}._2VGhK li{list-style:none;padding-right:10px}._2VGhK li,._2VGhK li>span{display:inline-block}._2VGhK li ._23uwz{font-weight:bolder;text-transform:uppercase;padding-right:7px;font-size:8px;font-size:.5rem}._2VGhK li ._2llo5{font-size:12.8px;font-size:.8rem;font-weight:lighter}.GtUdz{font-size:14px;color:#1c1c43;-o-text-overflow:ellipsis;text-overflow:ellipsis;width:100%;overflow:hidden}._2aJ8O{text-transform:capitalize}._2pcoQ{margin:0 20px}._24Mzw{font-size:15px;color:#005899;display:inline-block;margin-left:8px;cursor:pointer}._24Mzw:hover{color:#000}.hJfPu{vertical-align:middle}._5hYo6 .item-count-container{height:30px;width:30px}._5hYo6 .count-value{font-size:9px}._5hYo6 .count-label{display:none}", ""]);

// exports
exports.locals = {
	"noItemsMessage": "_2kTwc",
	"loadingDatasets": "_3Cimb",
	"datasets": "_2CwVq",
	"datasetTable": "_1Midn",
	"metadata": "_2VGhK",
	"metaName": "_23uwz",
	"metaValue": "_2llo5",
	"description": "GtUdz",
	"datasetType": "_2aJ8O",
	"dataSetPagination": "_2pcoQ",
	"datasetDescriptionTooltip": "_24Mzw",
	"publishingStatusCell": "hJfPu",
	"itemCount": "_5hYo6"
};