import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, StaticRouter } from "react-router-dom";
import { ApolloClient, ApolloProvider } from "react-apollo";
import { Routes } from "./routes";
import INetwork from "./utils/networkInterface";

const client = new ApolloClient({
  networkInterface: INetwork,
});

const ClientRoutes = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ApolloProvider>
);

const ServerRoutes = ({ context, location }) => (
  <ApolloProvider client={client}>
    <StaticRouter context={context} location={location}>
      <Routes />
    </StaticRouter>
  </ApolloProvider>
);

(ServerRoutes as any).propTypes = {
  location: PropTypes.string.isRequired
};

export default ClientRoutes;
export { ServerRoutes };
