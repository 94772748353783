exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._31xVT .primary-content{padding:0;overflow:hidden}._1cnzq{margin:0;padding:10px;display:-ms-flexbox;display:flex}._1cnzq ._2_Idy{width:calc(100% - 400px)}._1cnzq ._2_Idy,._1cnzq .z9tUd{display:inline-block;-webkit-transition:width .5s;-o-transition:width .5s;transition:width .5s}._1cnzq .z9tUd{width:0;margin:0;overflow:hidden;height:calc(100vh - 120px)}._1cnzq._13pv5 ._2_Idy{width:calc(100% - 800px)}._1cnzq._13pv5 .z9tUd{width:400px;margin:0 10px}._30pX4{display:inline-block;margin:0;width:400px;height:calc(100vh - 140px);vertical-align:top;overflow-y:auto}", ""]);

// exports
exports.locals = {
	"documentViewPage": "_31xVT",
	"documentView": "_1cnzq",
	"documentContent": "_2_Idy",
	"sentenceDetails": "z9tUd",
	"hasFocusSentence": "_13pv5",
	"labelList": "_30pX4"
};