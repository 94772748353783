import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import { COLLECTIONS_REQUEST, COLLECTION_CREATE, DOCUMENT_TYPES_REQUEST } from '../../actions/types';

export const initialState = {
  // all collections
  collections: [],
  // fetch status for document collections: loading, error, completed
  collectionsStatus: null,
  // returns true if a process is on-going (creating or deleting a collection, e.g.)
  isTransactionUnderway: false,
  // All document types available
  documentTypes: [],
  //fetch status for document types: loading, error, completed
  documentTypesStatus: null,
};

export default handleActions(
  {
    [DOCUMENT_TYPES_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('documentTypesStatus', 'loading'),
        failure: s => s.set('documentTypesStatus', 'error'),
        success: s => {
          return s.merge({
            documentTypesStatus: 'completed',
            documentTypes: action.payload.data.getTaxonomyValues.data
          });
        }
      });
    },
    [COLLECTIONS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('collectionsStatus', 'loading'),
        failure: s => s.set('collectionsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getCollections;
          return s.merge({
            collectionsStatus: 'completed',
            collections: result.data,
            totalItems: result.meta.totalItems,
            pageSize: result.meta.pagination.pageSize,
            currentPage: result.meta.pagination.page
          });
        }
      });
    },
    [COLLECTION_CREATE]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          return s.merge({
            isTransactionUnderway: false,
            collections: [
              action.payload.data.createCollection,
              ...s.collections
            ]
          });
        }
      });
    }
  },
  Immutable.from(initialState)
);
