import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Layout from '../../../_Home/components/layout';
import { ModelListContainer } from '../../components/model/list';
import ModelListToolbar from '../../components/model/list/toolbar';
import { ModelNewContainer } from '../../components/model/new';
import { isTransactionUnderway, getSelectedModels } from '../../reducers/models/selectors';
import TransactionOverlay from '../../../toolkit/transactionOverlay';
import { cleanModelSelection } from '../../actions/creators';

interface Props {
  isTransactionUnderway: boolean;
  t: (str: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state),
  selectedModels: getSelectedModels(state),
});

const mapDispatchToProps = {
  cleanModelSelection
}
class ModelsPage extends Component<Props> {
  render() {
    const { t, isTransactionUnderway } = this.props;
    return (
      <>
        <Layout
          title={t('models.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<ModelListToolbar {...this.props} />}
          content={<ModelListContainer {...this.props} />}
          sidebarShowRoute={`/models/new`}
          sidebarCloseRoute={`/models`}
          SidebarComponent={ModelNewContainer}
          sidebarProps={this.props}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(ModelsPage));
