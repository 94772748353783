import { gql } from 'react-apollo';

export default gql`
  query GetDocument($collectionId: ID!, $documentId: ID!) {
    getDocument(collectionId: $collectionId, documentId: $documentId) {
      data {
        name
        sentences {
          id
          text
          labels {
            legalConcepts {
              value
              sentenceId
            }
            legalEntities {
              value
              sentenceId
            }
            sentenceNorms {
              value
              sentenceId
            }
          }
        }
      }
      meta {
        collection {
          name
        }
      }
    }
  }
`;
