import { connect } from 'react-redux';

import DatasetAddItemsModal from './component';
import { hasItemsToAdd } from '../../../reducers/dataset/selectors';
import {
  requestAddItemsQueryResults,
  addItemsToDataset,
  clearItemsToAdd,
  requestDatasetItems,
  requestDataset,
  requestDistribution
} from '../../../actions/creators';
import { toggleErrorModal, showToastSuccess } from '../../../../_Home/actions';
import { AddItemsQuery } from '../../../../types-business/Dataset';

const mapStateToProps = state => ({
  hasItemsToAdd: hasItemsToAdd(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel() {
    const { history, datasetId } = ownProps;
    history.push(`/datasets/${datasetId}/items`);
    dispatch(clearItemsToAdd());
  },
  onAddItems(datasetId, fields: AddItemsQuery) {
    const {
      collectionId,
      documentId,
      restrictions,
      textRestrictions,
      limit,
      offset
    } = fields;
    dispatch(
      addItemsToDataset(
        datasetId,
        collectionId,
        documentId,
        restrictions,
        textRestrictions,
        limit,
        offset,
        {
          onSuccess: () => {
            const { t, history } = ownProps;
            history.push(`/datasets/${datasetId}/items`);
            dispatch(requestDatasetItems(datasetId));
            dispatch(requestDataset(datasetId));
            dispatch(requestDistribution(datasetId));
            dispatch(showToastSuccess(t('dataset-add-items.add-success')));
          },
          onFailure: () => {
            const { t } = ownProps;
            dispatch(toggleErrorModal(t('dataset-add-items.add-failure')));
          }
        }
      )
    );
  },
  onPreview(datasetId, fields: AddItemsQuery) {
    const {
      collectionId,
      documentId,
      restrictions,
      textRestrictions,
      limit,
      offset
    } = fields;
    dispatch(
      requestAddItemsQueryResults(
        datasetId,
        collectionId,
        documentId,
        restrictions,
        textRestrictions,
        limit,
        offset
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetAddItemsModal);
