import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classnames from 'classnames';
import s from './styles.scss';
interface Props {
  t: (str: string) => string;
  parameters: any;
  compare: boolean;
}

class ModelDetailParameters extends Component<Props> {
  render() {
    const { t, parameters = [], compare } = this.props;
    return (
      <aside className={classnames(s.parameters, {[s['compare']]: compare })}>
        <header className={s.parametersHeader}>{t('modelDetails.parameters')}</header>
        <section className={s.parametersContainer}>
          {parameters && parameters.map(([paramName, paramItem], index) => {
            const paramItemMap = Object.entries(paramItem)
            return (
              <div key={`${paramName}-${paramItem}`}>
                {paramItemMap.length === 0 &&
                  <section className={s['parametersList']}>
                    <div className={s['parametersList__itemLabel']}>{paramName}</div>
                    <div className={s['parametersList__itemValue']}>{paramItem}</div>
                  </section>
                }
                {paramItemMap.length > 0 &&
                  <section className={s['parametersList_item']}>
                    <header className={s['parametersList__itemHeader']}> {paramName} </header>
                    <table>
                      <tbody>
                        {paramItemMap.map(([name, value]) => {
                          return (
                          <tr key={`${paramName}-${name}-${index}`}>
                            <td className={s['parametersList__itemLabel']}>{name}</td>
                            <td className={s['parametersList__itemValue']}>{`${value}`}</td>
                          </tr>);
                        })}
                      </tbody>
                    </table>
                  </section>
                }
              </div>
            );
          })}
          {parameters.length === 0 &&
            <section className={s['parametersList_item']}>
              <header className={s['parametersList__itemHeader']}> {t('modelDetails.noParams')} </header>
            </section>
          }
        </section>
      </aside>
    );
  }
}

export default translate('translations')(
  withStyles(s)(ModelDetailParameters)
);
