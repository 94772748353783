import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment-timezone';
import SortBy from '../../../../../toolkit/sortBy/sortBy';
import Count from '../../../../../toolkit/card/count';
import get from 'lodash/get';

export default props => {
  const { t, location, onSortBy, styles } = props;

  return [
    {
      Header: _ => {
        return <SortBy
          field='name'
          fieldText={t('datasets.fields.name')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'name',
      style:{ 'whiteSpace': 'unset'},
      Cell: row => {
        return (
          <div className={styles.datasetTitleCell}>
            <Link className="datasetItemsLink" to={`/datasets/${row.original.id}/items`}>{row.value}</Link>
            {!!row.original.description && (
              <>
                <i id={`tooltip-${row.original.id}`}className={classNames("material-icons", styles.datasetDescriptionTooltip)}>info</i>
                <UncontrolledTooltip placement="top" target={`tooltip-${row.original.id}`}>
                  {row.original.description}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        );
      }
    },
    {
      Header: _ => {
        return <SortBy
          field='type'
          fieldText={t('datasets.fields.type')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'type',
      maxWidth: 130,
      Cell: row => {
        return t(`datasets.types.${row.value}`);
      }
    },
    {
      Header: t('taxonomies.taxonomy'),
      accessor: 'taxonomy.value',
      maxWidth: 200,
      Cell: row => {
        return t(`taxonomies.types.${row.value}`);
      }
    },
    {
      Header: _ => {
        return <SortBy
          field='publishingStatus'
          fieldText={t('datasets.fields.publishingStatus')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'publishingStatus',
      maxWidth: 100,
      style: {
        textAlign: 'center'
      },
      Cell: row => {
        const publishingStatus = row.original.publishingStatus
        return (
          <b className={styles.publishingStatusCell}>
            {publishingStatus === 'PUBLISHED' &&
              <i className='fa fa-check-circle' style={{
                  fontSize: '1.2rem', color: 'green'
              }}></i>
            }
          </b>
        );
      }
    },
    {
      Header: t('datasets.fields.trainedModels'),
      accessor: 'trainedModels',
      maxWidth: 130,
      style: {
        textAlign: 'center'
      },
      Cell: row => {
        return (
          <b className="datasetItemsCount">
            {get(row, 'original.trainedModels', []).length}
          </b>
        );
      }
    },
    // {
    //   Header: t('datasets.fields.createdBy'),
    //   accessor: 'createdBy',
    //   maxWidth: 130,
    // },
    {
      Header: _ => {
        return <SortBy
          field='createdDate'
          fieldText={t('datasets.fields.date')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'createdDate',
      maxWidth: 190,
      minWidth: 135,
      Cell: row => {
        return (
          <div>{moment.unix(row.original.createdDate).format("MMM DD YYYY, h:mm a")}</div>
        );
      }
    },
    {
      Header: t('datasets.fields.model'),
      accessor: 'suggestionModel.name',
      maxWidth: 200,
      Cell: row => {
        return !!row.original.suggestionModel ? (
          <Link to={`/model/${row.original.suggestionModel.id}`}>{row.value}</Link>
        ): null;
      }
    },
    {
      Header: _ => {
        return <div>{t('datasets.fields.count')}</div>
      },
      style: {
        textAlign: 'center',
      },
      Cell: row => {
        return (
          <Count
            className={styles.itemCount}
            count={row.original.itemCount}
            label={t(`componentTypes.${row.original.componentType}`)}
          />
        );
      },
      minWidth: 100,
      maxWidth: 150,
    }
  ];
};
