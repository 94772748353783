import React from "react";
import { Button } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import classNames from "classnames";
import s from "./styles.scss";

interface Props {
  id: string;
  className: string;
  size: string;
  color: string;
  width: string;
  block: boolean;
  onClick: Function;
  dataElmId: string;
  disabled: boolean;
}

class BaseButton extends React.Component<Props> {
  static defaultProps = {
    color: "primary",
    size: "lg",
  };

  render() {
    const { dataElmId, children, size, width, className, ...rest } = this.props;

    return (
      <Button
        data-elm-id={dataElmId}
        data-size={size}
        data-width={width}
        className={classNames(s.root, className)}
        {...rest}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(s)(BaseButton);
