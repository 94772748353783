import React, { Component } from 'react';
import { DatasetItem } from '../../../../types-business/Dataset';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReactTableStyles from 'react-table/react-table.css';
import { translate } from 'react-i18next';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import classNames from 'classnames';
import { FormGroup, Label, Input } from 'reactstrap';
import { History, Location } from 'history';

import columns from './_table/columns';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import Message from '../../../../toolkit/message';
import s from './styles.scss';
import ReactTableStylesOverride from '../../../../scss/_react-table.scss';
import { getPaginationData, hasDataManagerRole } from '../../../../utils/helpers';
import Pagination from '../../../../toolkit/pagination';

const Table = checkboxHOC(ReactTable);

interface Props {
  datasetId: string;
  hasError: boolean;
  items: DatasetItem[];
  isLoading: boolean;
  isPublished: boolean;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  selectedItems: string[];
  hasSelectAll: boolean;
  hasSelections: boolean;
  datasetInfo: any;
  location: Location;
  history: History;
  userRoles: string[];
  requestDatasetItems: (
    datasetId: string,
    allLabels?: boolean,
    page?: number,
    size?: number
  ) => void;
  deleteDatasetItems: (ids: string[]) => void;
  toggleSelectAllDatasetItems: () => void;
  toggleSelectDatasetItem: (itemId: string) => void;
  t: (str: string, vars?: any) => string;
}

export class DatasetItems extends Component<Props> {
  componentWillMount() {
    const { requestDatasetItems, datasetId, location } = this.props;
    const {page, size} = getPaginationData(location);
    const allLabels = false;
    requestDatasetItems(datasetId, allLabels, page, size);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { datasetId, requestDatasetItems, location } = this.props;
      const {page, size} = getPaginationData(location);
      const allLabels = false;
      requestDatasetItems(datasetId, allLabels, page, size);
    }
  }
  handlePageChange = page => {
    this.props.history.push(`?page=${page + 1}`);
  };

  handleDelete() {
    const {
      datasetId,
      deleteDatasetItems,
      hasSelections,
      isPublished,
      selectedItems
    } = this.props;
    if (hasSelections && !isPublished) {
      deleteDatasetItems.call(this, datasetId, selectedItems);
    }
  }

  isSelected(id) {
    return this.props.selectedItems.includes(id);
  }

  render() {
    const {
      hasError,
      items,
      isLoading,
      isPublished,
      pageSize,
      totalPages,
      hasSelectAll,
      hasSelections,
      location,
      t,
      toggleSelectAllDatasetItems,
      toggleSelectDatasetItem,
      userRoles
    } = this.props;
    const isDataManager = hasDataManagerRole(userRoles);
    const { page } = getPaginationData(location)
    if (hasError) {
      return (
        <Message message={t('dataset-items.items-load-error')} type="error" />
      );
    }

    if (isLoading) {
      return (
        <div className={`${s.loadingItems}`}>
          <SvgSpinner />
        </div>
      );
    }

    if (items.length) {
      return (
        <>
          <div className={s.controls}>
            <div>
              <FormGroup check className={s.selectAll}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={hasSelectAll}
                    onChange={toggleSelectAllDatasetItems}
                  />
                  {t('dataset-items.select-all-in-page')}
                </Label>
              </FormGroup>
            </div>
            {isDataManager && (
              <div
                className={classNames(s.deleteButton, {
                  [s.disabled]: !hasSelections || isPublished
                })}>
                <i
                  className="material-icons"
                  onClick={this.handleDelete.bind(this)}>
                  delete
                </i>
              </div>
            )}
          </div>
          <Table
            className={classNames('default-akorda-table', s.itemsTable)}
            columns={columns(this.props)}
            data={items}
            manual
            pageSize={pageSize}
            sortable={false}
            showPageJump={false}
            showPageSizeOptions={false}
            showPaginationBottom={false}
            onPageChange={this.handlePageChange}
            toggleSelection={toggleSelectDatasetItem}
            isSelected={this.isSelected.bind(this)}
            selectType="checkbox"
            keyField="id"
            selectWidth={50}
          />
          <Pagination
            pageNumber={page}
            pageSize={pageSize}
            totalPages={totalPages}
            onPrevious={this.handlePageChange}
            onNext={this.handlePageChange}
          />
        </>
      );
    } else {
      return (
        <Message message={t('dataset-items.no-items-message')} type="info" />
      );
    }
  }
}

export default translate('translations')(
  withStyles(ReactTableStyles, ReactTableStylesOverride, s)(DatasetItems)
);
