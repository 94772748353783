import { connect } from 'react-redux';
import {
  selectDocumentViewLabel,
  requestSentenceLabels
} from '../../../../actions/creators';
import {
  getSelectedLabelValue,
  getFocusSentenceId,
  getCurrentSelectedLabelSentenceIdPosition,
  getNextMatchingSentenceId,
  getPreviousMatchingSentenceId,
  getLabelMap
} from '../../../../reducers/document/selectors';

import DocumentViewLabels from './component';

const mapStateToProps = state => ({
  labelMap: getLabelMap(state),
  focusSentenceId: getFocusSentenceId(state),
  currentSelectedLabelSentenceIdPosition: getCurrentSelectedLabelSentenceIdPosition(
    state
  ),
  selectedLabelValue: getSelectedLabelValue(state),
  nextMatchingSentenceId: getNextMatchingSentenceId(state),
  previousMatchingSentenceId: getPreviousMatchingSentenceId(state)
});

const mapDispatchToProps = {
  selectDocumentViewLabel,
  requestSentenceLabels
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentViewLabels);
