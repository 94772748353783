import { Component } from 'react';
import Reform from '@franleplant/reform';

interface Props {
  t: (str: string, vars?: any) => string;
  className: string;
  handleSubmit: (fields: object, props: object) => void;
  fields?: any;
}

interface State {
  fields: any;
  errors: any;
}

// TODO: i18n translate
// TODO: How do I override or add validation messages without replacing the whole thing? Does it matter?
class Form extends Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  constructor(props) {
    super(props);
    this.state = {
      fields: { ...this.fields() },
      errors: {}
    };
  }

  fields() {
    console.error(
      'You must override the fields method to define the default values and validation states'
    );
    return {};
  }

  validationRules = {};

  validationMessages = {
    required: () => 'This field is required',
    default: (_ruleKey, _ruleValue, fieldName) =>
      `The value for field ${fieldName} is invalid`
  };

  handleChangeFor = fieldName => {
    return event => {
      const value = event.target.value;
      const isEmpty = value.trim() === '';
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: isEmpty ? '' : value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  errorsFor(fieldName) {
    return this.re.mapFieldErrors(fieldName);
  }

  onSubmit = e => {
    e.preventDefault();

    if (this.re.validateFormFromState()) {
      const { handleSubmit } = this.props;
      const { fields } = this.state;
      handleSubmit(fields, this.props);
    }
  };

  handleSubmit() {}
}

export default Form;
