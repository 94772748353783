exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".eXbuE{overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis}.eXbuE._2-CXx{white-space:normal}", ""]);

// exports
exports.locals = {
	"toggleableText": "eXbuE",
	"textExpanded": "_2-CXx"
};