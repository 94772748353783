import React from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './styles.scss';

interface Props {
  checked: boolean;
  label: string;
  onChange: () => void;
}

class ToggleSwitch extends React.Component<Props> {

  render() {
    const { onChange, label } = this.props;

    const switchComponent = (
      <label htmlFor="toggle_switch_input" className={classNames(s.switch)}>
        <input
          id="toggle_switch_input"
          onChange={onChange}
          type="checkbox"
          checked={this.props.checked}
        />
        <span className={classNames(s.slider)} />
      </label>
    );

    return !!label ? (
      <div className="d-flex justify-content-between">
        <span className={s.label}>{label}</span>
        {switchComponent}
      </div>
    ) : (
      switchComponent
    );
  }
}

export default withStyles(s)(ToggleSwitch);
