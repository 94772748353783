exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Hn7q,._3sb_1{min-height:300px;height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._3Hn7q span,._3sb_1 span{font-style:italic}._2mugi ._2UUf6{margin:20px 0}._2mugi ._2UUf6 h4{font-size:20px;font-size:1.25rem}._2mugi .default-akorda-table{margin-top:10px}._2mugi .pagination-bottom{display:none}", ""]);

// exports
exports.locals = {
	"loadingItems": "_3Hn7q",
	"noItemsMessage": "_3sb_1",
	"previewResults": "_2mugi",
	"countInformation": "_2UUf6"
};