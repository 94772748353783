import { gql } from 'react-apollo';

export default gql`
  query GetDataset($datasetId: String!) {
    getDataset(datasetId: $datasetId) {
      data {
        id
        name
        type
        itemCount
        componentType
        createdDate
        taxonomy {
          value
          values {
            id
            value
          }
        }
        suggestionModel {
          name
        }
        publishingStatus
        description
        trainedModels {
          id
          name
        }
        fileNames
      }
    }
  }
`;
