import React from 'react';
import classNames from 'classnames';
import { getPaginationData } from '../../utils/helpers';
import { Location } from 'history';

interface Props {
  field: string;
  fieldText: string;
  location: Location;
  onSortBy: (field,direction) => void;
}
class SortBy extends React.Component<Props> {
  getOrder = () => {
    const {
      location,
      field,
      onSortBy,
    } = this.props;
    let {sort} = getPaginationData(location);
    sort = sort || 'createdDate,DESC';
    let [fieldFromURL, direction] = sort.split(',');
    direction = fieldFromURL === field ? direction : undefined;
    switch(direction){
      case 'DESC':
        direction =  'ASC';
        break;
      default:
        direction = 'DESC';
    }
    onSortBy(field, direction);
  }
  render() {
    const {
      fieldText,
      field
    } = this.props;
    let {sort} = getPaginationData(location);
    sort = sort || 'createdDate,DESC';
    const sortArray = sort.split(',');
    const [fieldFromURL, direction] = sortArray;
    return (
      <p
        onClick={this.getOrder}
        style={{margin: '0', cursor: 'pointer'}}
        >
        {fieldText}
        <i className={classNames('fa',{
          'fa-caret-up': fieldFromURL === field && direction === 'ASC',
          'fa-caret-down': fieldFromURL === field && direction === 'DESC',
          'fa-sort': fieldFromURL !== field || !direction,
        })}
        style={{marginLeft: '5px'}}></i>
      </p>
    );
  }
}

export default SortBy;
