import React from "react";
import { connect } from "react-redux";
import NavigationComponent from "../../components/navigation";
import * as actions from "../../actions";
import * as loginActions from "../../../_Login/actions";
import { UserContext } from "../../../types-business/Profile";
import { getMyRoles } from '../../../_Login/reducers/selectors';

interface Props {
  toggleErrorModal: (error: string) => void;
  userContext: UserContext;
  userRoles: string[];
  setUserContext: (userContext: UserContext) => void;
  smallHeader: boolean;
  redirectToLogin: boolean;
  resetApp: () => void;
}

class Navigation extends React.Component<Props, void> {
  render() {
    return (
      <NavigationComponent
        setUserContext={this.props.setUserContext}
        userRoles={this.props.userRoles}
        userContext={this.props.userContext}
        toggleErrorModal={this.props.toggleErrorModal}
        smallHeader={this.props.smallHeader}
        redirectToLogin={this.props.redirectToLogin}
        resetApp={this.props.resetApp}
      >
        {this.props.children}
      </NavigationComponent>
    );
  }
}

const mapStateToProps = state => {
  return {
    userContext: state.login.userContext,
    userRoles: getMyRoles(state),
    redirectToLogin: state.navigation.redirectToLogin,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleErrorModal: (error: string) => {
    dispatch(actions.toggleErrorModal(error));
  },
  setUserContext: (userContext: UserContext) => {
    dispatch(loginActions.setUserContext(userContext));
  },
  resetApp: () => {
    dispatch(actions.resetApp());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
