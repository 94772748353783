export const DATA_SETS_REQUEST = 'DATA-SCI::DATA_SETS_REQUEST';
export const DATA_SET_CREATE = 'DATA-SCI::DATA_SET_CREATE';
export const TAXONOMIES_REQUEST = 'DATA-SCI::TAXONOMIES_REQUEST';
export const DATASET_DETAILS_REQUEST = 'DATA-SCI::DATASET_DETAILS_REQUEST';
export const DATASET_ITEMS_REQUEST = 'DATA-SCI::DATASET_ITEMS_REQUEST';
export const DATASET_ITEM_TOGGLE_SELECT =
  'DATA-SCI::DATASET_ITEM_TOGGLE_SELECT';
export const DATASET_ITEMS_TOGGLE_SELECT_ALL =
  'DATA-SCI::DATASET_ITEMS_TOGGLE_SELECT_ALL';
export const DATASET_PUBLISH = 'DATA-SCI::DATASET_PUBLISH';
export const DATASET_UNPUBLISH = 'DATA-SCI::DATASET_UNPUBLISH';
export const DATASET_ITEMS_DELETE = 'DATA-SCI::DATASET_ITEMS_DELETE';
export const DATASET_ADD_ITEMS_TEST_QUERY =
  'DATA-SCI::DATASET_ADD_ITEMS_TEST_QUERY';
export const DATASET_CLEAR_ITEMS_TO_ADD =
  'DATA-SCI::DATASET_CLEAR_ITEMS_TO_ADD';
export const DATASET_ADD_ITEMS = 'DATA-SCI::DATASET_ADD_ITEMS';
export const DATASET_REVIEW_ADD_LABEL = 'DATA-SCI::DATASET_REVIEW_ADD_LABEL';
export const DATASET_REVIEW_REVERT_LABEL =
  'DATA-SCI::DATASET_REVIEW_REVERT_LABEL';
export const DATASET_REVIEW_FOCUS = 'DATA-SCI::DATASET_REVIEW_FOCUS';
export const DATASET_REVIEW_FOCUS_NEXT = 'DATA-SCI::DATASET_REVIEW_FOCUS_NEXT';
export const DATASET_REVIEW_FOCUS_PREVIOUS =
  'DATA-SCI::DATASET_REVIEW_FOCUS_PREVIOUS';
export const DATASET_REVIEW_FOCUS_LAST_IN_PAGE =
  'DATA-SCI::DATASET_REVIEW_FOCUS_LAST_IN_PAGE';
export const DATASET_MODELS_REQUEST = 'DATA-SCI::DATASET_MODELS_REQUEST';
export const DATASET_DISTRIBUTION_REQUEST =
  'DATA-SCI::DATASET_DISTRIBUTION_REQUEST';
export const DATASET_USER_REVIEW_PROGRESS_REQUEST =
  'DATA-SCI::DATASET_USER_REVIEW_PROGRESS_REQUEST';
export const DATASET_SENTENCE_CONTEXT_REQUEST =
  'DATA-SCI::DATASET_SENTENCE_CONTEXT_REQUEST';
export const DATASET_DOWNLOAD_FILE = 'DATA-SCI::DATASET_DOWNLOAD_FILE';
export const DATASET_TOGGLE_LABEL_REVIEW_MODE = 'DATA-SCI::DATASET_TOGGLE_LABEL_REVIEW_MODE';
