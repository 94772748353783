import React, { Component } from 'react';
import { ListGroup } from 'reactstrap';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import s from './styles.scss';
import Label from './label';

interface Props {
  labelMap: any;
  currentSelectedLabelSentenceId: number;
  nextMatchingSentenceId: number;
  previousMatchingSentenceId: number;
  selectedLabelValue: string;
  t: (key: string) => string;
  selectDocumentViewLabel: (labelInfo: any) => void;
  requestSenenceLabels: (sentenceId: number) => void;
}

export class DocumentViewLabels extends Component<Props> {
  render() {
    const {
      labelMap,
      t,
      selectDocumentViewLabel,
      selectedLabelValue,
      ...rest
    } = this.props;
    return (
      <>
        {_.values(labelMap).map(taxonomy => {
          const { taxonomyId, values } = taxonomy;
          const keys = _.keys(values);
          return (
            <div className={s.taxonomy} key={taxonomyId}>
              <h5>{t(`taxonomies.${taxonomyId}`)}</h5>

              <ListGroup>
                {keys.map(key => (
                  <Label
                    key={key}
                    taxonomyId={taxonomyId}
                    value={key}
                    select={selectDocumentViewLabel}
                    isSelected={selectedLabelValue === key}
                    {...rest}
                  />
                ))}
              </ListGroup>
            </div>
          );
        })}
      </>
    );
  }
}

export default translate('translations')(withStyles(s)(DocumentViewLabels));
