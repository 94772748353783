import { gql } from 'react-apollo';

export default gql`
  query GetDatasets($page: Int! = 0, $size: Int! = 20, $sort: String = "createdDate,DESC") {
    getDatasets(page: $page, size: $size, sort: $sort) {
      data {
        id
        name
        type
        itemCount
        componentType
        createdDate
        taxonomy {
          value
        }
        suggestionModel {
          id
          name
        }
        description
        publishingStatus
        trainedModels {
          id
        }
        createdDate
        createdBy
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
      }
    }
  }
`;
