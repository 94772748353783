import sortBy from "lodash/sortBy";

type Option = { value: string | number; label: string };
type Options = Option[];

const match = (regex: RegExp) => {
  return (option: Option) => {
    const label = option.label;
    return label && label.match(regex);
  };
};

export default function(options: Options, searchText: string = ""): Options {
  if (typeof searchText !== "string" || searchText.trim() === "") {
    return options;
  }

  // match the options where the text appears anywhere
  var SIMPLE_MATCH_REGEX = new RegExp(searchText, "i");
  // match the options starting with the text
  var BEST_MATCH_REGEX = new RegExp(`^${searchText}`, "i");
  // match the options where the text does not start, but is directly after whitespace/underscore/dash/forward-slash
  var MIDDLING_MATCH_REGEX = new RegExp(`[\\s\\/\\-_]${searchText}`, "i");

  const allMatches = sortBy(options.filter(match(SIMPLE_MATCH_REGEX)), [
    "label",
  ]);

  const bestMatches = allMatches.filter(match(BEST_MATCH_REGEX));
  const middlingMatches = allMatches.filter(match(MIDDLING_MATCH_REGEX));
  const otherMatches = allMatches.filter(
    match => !bestMatches.includes(match) && !middlingMatches.includes(match)
  );

  return [...bestMatches, ...middlingMatches, ...otherMatches];
}
