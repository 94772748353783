import { gql, graphql } from "react-apollo";

export function pureLogin() {
  return gql`
    mutation($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        userId
        email
        firstName
        lastName
        groups
        company {
          companyId
          companyName
        }
      }
    }
  `;
}

export function login(options = {}) {
  return graphql<void, any>(pureLogin(), {
    name: "login",
    options: {
      ...options,
    },
  });
}

export function sendPasswordEmail(options = {}) {
  return graphql<void, any>(
    gql`
      mutation($email: String!) {
        sendPasswordEmail(email: $email)
      }
    `,
    {
      name: "sendPasswordEmail",
      options: {
        ...options,
      },
    }
  );
}

export function changePassword(options = {}) {
  return graphql<void, any>(
    gql`
      mutation($password: String!, $userId: Int!, $token: String!) {
        changePassword(password: $password, userId: $userId, token: $token)
      }
    `,
    {
      name: "changePassword",
      options: {
        ...options,
      },
    }
  );
}

export function verifyUser(options = {}) {
  return graphql<void, any>(
    gql`
      mutation($password: String!, $userId: Int!, $token: String!) {
        verifyAccount(password: $password, userId: $userId, token: $token)
      }
    `,
    {
      name: "verifyUser",
      options: {
        ...options,
      },
    }
  );
}
