import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Location, History } from 'history';

import Layout from '../../../_Home/components/layout';
import TransactionOverlay from '../../../toolkit/transactionOverlay';
import { isTransactionUnderway } from '../../reducers/documents/selectors';
import { DocumentListContainer } from '../../components/document/list';
import { DocumentListToolbarContainer } from '../../components/document/list/toolbar';
import { DocumentUploadContainer } from '../../components/document/upload';

interface Props {
  collectionId: string;
  location: Location;
  history: History;
  isTransactionUnderway: boolean;
  t: (str: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state)
});

class DocumentsPage extends Component<Props> {
  render() {
    const { t, collectionId, isTransactionUnderway } = this.props;

    return (
      <>
        <Layout
          title={t('documents.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<DocumentListToolbarContainer {...this.props} />}
          content={<DocumentListContainer {...this.props} />}
          sidebarShowRoute={`/collections/:collectionId/documents/upload`}
          sidebarCloseRoute={`/collections/${collectionId}/documents`}
          SidebarComponent={DocumentUploadContainer}
          sidebarProps={this.props}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(
  connect(mapStateToProps)(DocumentsPage)
);
