import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import {
  TAXONOMIES_REQUEST,
  DATASET_MODELS_REQUEST
} from '../../actions/types';

const intialState = {
  // all taxonomies
  taxonomies: [],
  // fetch status for taxonomies: loading, error, completed
  taxonomiesStatus: null,
  // fetch all models
  models: [],
  // fetch status for models: loading, error, completed
  modelsStatus: null
};

export default handleActions(
  {
    [TAXONOMIES_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('taxonomiesStatus', 'loading'),
        failure: s => s.set('taxonomiesStatus', 'error'),
        success: s => {
          const result = action.payload.data.getTaxonomies;
          return s.merge({
            taxonomiesStatus: 'completed',
            taxonomies: result.data
          });
        }
      });
    },
    [DATASET_MODELS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('modelsStatus', 'loading'),
        failure: s => s.set('modelsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getModels;
          return s.merge({
            modelsStatus: 'completed',
            models: result.data,
            totalItems: result.meta.totalItems,
            collection: result.meta.collection,
            pageSize: result.meta.pagination.pageSize,
            currentPage: result.meta.pagination.page
          });
        }
      });
    }
  },
  Immutable.from(intialState)
);
