import React from "react";
import classNames from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import spinner from "../../media/img/spinner.svg";
import s from "./styles.scss";

const svgSpinner = ({ small, modal, message, dataElmId, className }) => {
  if (modal) {
    return (
      <div
        data-elm-id={dataElmId || "svg-spinner"}
        className={`${s.spinnerContainer} ${className || ""}`}
      >
        <img className={`${s.spinner} ${small ? s.small : ""}`} src={spinner} />
        {message && <p className={s.message}>{message}</p>}
      </div>
    );
  }

  if (message) {
    return (
      <div className={classNames(className)}>
        <img
          className={classNames(s.spinner, {
            [s.small]: small,
          })}
          src={spinner}
        />
        <p className={classNames("m-t-5", s.message)}>{message}</p>
      </div>
    );
  }

  return (
    <img
      className={classNames(s.spinner, {
        [s.small]: small,
        [className]: !!className,
      })}
      src={spinner}
    />
  );
};

export default withStyles(s)(svgSpinner);
