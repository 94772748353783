import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import SvgSpinner from '../svgSpinner';
import s from './styles.scss';

export class TransactionOverlay extends Component {
  render() {
    return (
      <div className={s.transactionOverlay}>
        <div>
          <SvgSpinner />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(TransactionOverlay);
