import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';

import { Sentence as ISentence } from '../../../../types-business/Document';
import s from './styles.scss';
import Sentence from './sentence';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import Message from '../../../../toolkit/message';

interface Props {
  collectionId: string;
  documentId: string;
  hasError: boolean;
  isLoading: boolean;
  sentences: ISentence[];
  focusSentenceId: string;
  focusSentenceLabels: any[];
  focusSentence: (sentenceId: string) => void;
  requestDocument: (collectionId: string, documentId: string) => void;
  requestDocumentLabels: (documentId: string) => void;
  t: (key: string) => string;
}

class DocumentView extends Component<Props> {
  componentWillMount() {
    const { collectionId, documentId, requestDocument, requestDocumentLabels } = this.props;
    requestDocument(collectionId, documentId);
    requestDocumentLabels(documentId);
  }

  render() {
    const {
      sentences,
      isLoading,
      hasError,
      t,
      focusSentence,
      focusSentenceId,
      focusSentenceLabels
    } = this.props;

    if (hasError) {
      return <Message message={t('document.load-error')} type="error" />;
    }

    if (isLoading) {
      return (
        <div className={`${s.loading}`}>
          <SvgSpinner />
        </div>
      );
    }

    if (!sentences.length) {
      return <Message message={t('document.no-sentences')} type="info" />;
    }

    return (
      <section className={s.sentencesSection}>
        {sentences.map(sentence => (
          <Sentence
            {...sentence}
            key={sentence.id}
            onFocus={focusSentence.bind(this)}
            isActive={focusSentenceId === sentence.id}
            labels={focusSentenceLabels}
          />
        ))}
      </section>
    );
  }
}

export default translate('translations')(withStyles(s)(DocumentView));
