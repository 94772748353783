exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._3Rt8P{display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1;-ms-flex-pack:justify;justify-content:space-between;padding:0 10px;border-right:1px dotted #c9ccd4;border-left:1px dotted #c9ccd4}._3Rt8P ._10QBo{font-weight:lighter}._3Rt8P ._10QBo span{display:inline-block;margin-right:5px;font-weight:700}._3KE3u{min-height:100px}._3KE3u h3{font-size:16px;font-size:1rem;font-weight:700;text-transform:uppercase}._3KE3u ._1A_Me{margin:0;padding:0}._3KE3u ._1A_Me ._1oMCR{list-style:none;font-weight:700;font-size:10px;display:block;height:auto;margin:0;padding:3px 0;border-bottom:1px dotted #c9ccd4;position:relative}._3KE3u ._1A_Me ._1oMCR._1w4eP{font-size:14px;padding:5px 0}._3KE3u ._1A_Me ._1oMCR._1w4eP>._2zH-C>._7DFbJ{font-size:15px}._3KE3u ._1A_Me ._1oMCR ._2zH-C{height:20px;background-color:#5bc0de;white-space:nowrap}._3KE3u ._1A_Me ._1oMCR ._2zH-C ._7DFbJ{color:#1c1c43;font-weight:700;font-size:11px;background-color:#e4e5e9;position:absolute;right:0}", ""]);

// exports
exports.locals = {
	"metrics": "_3Rt8P",
	"metricItem": "_10QBo",
	"distribution": "_3KE3u",
	"barChart": "_1A_Me",
	"bar": "_1oMCR",
	"withMetrics": "_1w4eP",
	"barContent": "_2zH-C",
	"itemCount": "_7DFbJ"
};