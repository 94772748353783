import { gql } from 'react-apollo';

export default gql`
  query GetDatasetsForModel {
    getPublishedDatasets {
      data {
        id
        name
        type
        taxonomy {
          id
          value
        }
      }
    }
  }
`;
