import { gql } from 'react-apollo';

export default gql`
  query GetUserReviewProgress($datasetId: String!, $userId: Int!) {
    getUserReviewProgress(datasetId: $datasetId, userId: $userId) {
      data {
        reviewed
        unreviewed
      }
    }
  }
`;
