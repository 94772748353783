exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._3vUwA{display:block;position:relative;padding-left:25px;margin-bottom:12px;cursor:pointer;font-size:16px;font-family:Lato,Montserrat-Light,HelveticaNeue-Light,Segoe UI,sans-serif;color:#1c1c43;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._3vUwA input{position:absolute;opacity:0;cursor:pointer}._3vUwA:hover input~._38u8Q{background-color:#edf0f2}._3vUwA input[bg-class=primary]:checked~._38u8Q{background-color:#292d78}._3vUwA input[bg-class=success]:checked~._38u8Q{background-color:#51af46}._3vUwA input[bg-class=danger]:checked~._38u8Q{background-color:#e3495d}._3vUwA :after{content:\"\";position:absolute;display:none}._3vUwA input:checked~._38u8Q:after{display:block}._3vUwA ._38u8Q:after{top:6px;left:6px;width:6px;height:6px;border-radius:50%;background:#fff}._38u8Q{position:absolute;top:0;left:0;height:18px;width:18px;margin-top:2px;background-color:#edf0f2;border-radius:50%}._3vUwA[data-disabled=true]{color:#c9ccd4}", ""]);

// exports
exports.locals = {
	"container": "_3vUwA",
	"checkmark": "_38u8Q"
};