import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { handle } from 'redux-pack';
import { DATA_SETS_REQUEST, DATA_SET_CREATE } from '../../actions/types';

const intialState = {
  // all datasets
  datasets: [],
  // fetch status for datasets: loading, error, completed
  datasetsStatus: null,
  // page the user is currently on (zero-based index)
  currentPage: 0,
  // number of documents to display on each page
  pageSize: 20,
  // total items
  totalItems: 0,
  // whether a transaction (create/delete, e.g.) is underway
  isTransactionUnderway: false,

  sortBy: {},
};

export default handleActions(
  {
    [DATA_SETS_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('datasetsStatus', 'loading'),
        failure: s => s.set('datasetsStatus', 'error'),
        success: s => {
          const result = action.payload.data.getDatasets;
          return s.merge({
            datasetsStatus: 'completed',
            datasets: result.data,
            totalItems: result.meta.totalItems,
            pageSize: result.meta.pagination.pageSize,
            currentPage: result.meta.pagination.page
          });
        }
      });
    },
    [DATA_SET_CREATE]: (state, action) => {
      return handle(state, action, {
        start: s => s.set('isTransactionUnderway', true),
        failure: s => s.set('isTransactionUnderway', false),
        success: s => {
          return s.merge({
            isTransactionUnderway: false,
            datasets: [action.payload.data.createDataset, ...s.datasets]
          });
        }
      });
    }
  },
  Immutable.from(intialState)
);
