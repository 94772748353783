import IQueryError from '../types/IQueryError';
import queryString from 'query-string';
import intersection from 'lodash/intersection';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from "lodash/isEqual";
import constants from './constants';

export function bytesToSize(bytes: number): string {
  if (bytes == 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function getErrorMsgFromIQueryError(err: IQueryError): string {
  const errorsExist =
    err.graphQLErrors &&
    err.graphQLErrors[0] &&
    err.graphQLErrors[0].serviceErrors &&
    err.graphQLErrors[0].serviceErrors[0];
  let msg;

  msg =
    errorsExist && err.graphQLErrors[0].serviceErrors[0].defaultMessage
      ? err.graphQLErrors[0].serviceErrors[0].defaultMessage
      : null;

  msg =
    errorsExist && err.graphQLErrors[0].serviceErrors[0].error_description
      ? err.graphQLErrors[0].serviceErrors[0].error_description
      : msg;

  msg =
    errorsExist && err.graphQLErrors[0].serviceErrors[0].message
      ? err.graphQLErrors[0].serviceErrors[0].message
      : msg;

  return msg;
}

export function isActiveLocation(
  currentLocation: string,
  matchStart: boolean // if true, only worry about matching the start of the location, otherwise full match required
): boolean {
  const pathname = (window.location && window.location.pathname) || '';
  return matchStart
    ? pathname.indexOf(currentLocation) === 0
    : pathname === currentLocation;
}

export function isClientSide(): boolean {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

export const getPaginationData = (location: {search?: string} = {}) => {
  const qs = queryString.parse(location.search);
  const page = qs.page ? +qs.page - 1 : 0;
  const size = qs.size ? +qs.size : 20;
  const sort = qs.sort || '';
  return {
    page, size, sort
  }
};

export function getDataScienceApplicationRole(roles: string[]) {
  const appRoles: string[] = intersection(
    Object.keys(constants.ROLES),
    roles || []
  );
  return first(appRoles);
}

export function hasDataScienceApplicationAccess(roles: string[]) {
  const role = getDataScienceApplicationRole(roles);
  return !isEmpty(role);
}

export function hasDataReviewerRole(roles: string[]) {
  return roles.includes(constants.ROLES.DATA_REVIEWER);
}

export function hasDataManagerRole(roles: string[]) {
  const { ADMIN, DATA_MANAGER } = constants.ROLES;
  return roles.some(role => [DATA_MANAGER, ADMIN].includes(role));
}

export function downloadFile(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // For Edge browser
  setTimeout(function() {
    window.URL.revokeObjectURL(url);
  }, 1000);
}

/**
 * Convenience function for React "props" value comparison
 * @param field The path of the property to get in each props object
 * @param propsA The first props object for comparison
 * @param propsB The second props object for comparison
 */
export function hasPropChanged(
  field: string,
  propsA: any,
  propsB: any
): boolean {
  const valueA = get(propsA, field, null);
  const valueB = get(propsB, field, null);
  return isDifferent(valueA, valueB);
}

/**
 * Convenience function for React "props" value comparision, allowing many fields to be compared at once
 * @param fields an array of strings representing paths of the property to get in each props object
 * @param propsA The first props object for comparison
 * @param propsB The second props object for comparison
 */
export function havePropsChanged(
  fields: string[],
  propsA: any,
  propsB: any
): boolean {
  return fields.map(field => hasPropChanged(field, propsA, propsB)).some(hasChanged => !!hasChanged);
}

/**
 * Convenience function utilizing lodash's "isEqual". Returns
 * true if the two values are different. Note: _.isEqual uses a deep comparison
 * for objects and arrays and should be used wisely in cases of large object
 * or array comparisons.
 * @param valueA First value to compare
 * @param valueB Second value to compare
 */
export function isDifferent(valueA: any, valueB: any): boolean {
  return !isEqual(valueA, valueB);
}