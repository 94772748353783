import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './styles.scss';
interface Props {
  t: (str: string) => string;
  matrix: any;
  labels: any;
}

const bottomColor = {r: 28, g: 28, b: 63};
const topColor = {r: 237, g: 240, b: 242};

const calculateColor = (value, highestValue) => {
  const normalizedValue = value / highestValue;
  const r = topColor.r*(1-normalizedValue) + bottomColor.r*normalizedValue;
  const g = topColor.g*(1-normalizedValue) + bottomColor.g*normalizedValue;
  const b = topColor.b*(1-normalizedValue) + bottomColor.b*normalizedValue;
  return {
    r,
    g,
    b
  };
};

class ConfusionMatrix extends Component<Props> {
  render() {
    const { t, matrix = [], labels = [] } = this.props;
    const highestValue = Math.max(...(
      matrix.map((row) => {
        return Math.max(...row)
      })
    ));
    return (
      <aside className={s.metrics}>
        <header className={s.metricsHeader}>{t('modelDetails.ConfusionMatrix')}</header>
        <div>
          <div className={s.gradientTicks}>
            <div><span>{(highestValue * 0.2).toFixed(0)}</span></div>
            <div><span>{(highestValue * 0.4).toFixed(0)}</span></div>
            <div><span>{(highestValue * 0.6).toFixed(0)}</span></div>
            <div><span>{(highestValue * 0.8).toFixed(0)}</span></div>
            <div><span>{highestValue}</span></div>
          </div>
          <div className={s.gradient}></div>
        </div>
        <table>
          <tbody>
            { matrix.map( (row, index)  => {
              return (<tr key={labels[index]}>
                <th scope='row' key={`${labels[index]}-label`} >{labels[index]}</th>
                {row.map( (item, columnIndex) => {
                  const rgb = calculateColor(item, highestValue);
                  const color =  item > highestValue / 2 ? 'white' : 'black';
                  return (<td key={`${labels[index]}-${labels[columnIndex]}`}
                    style={{background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`, color}}>
                    {item}
                  </td>);
                })}
              </tr>)
            })}
            <tr className={s.labels} key={'labelsRow'}>
              <th></th>
              {labels.map(label =>
                <th key={`${label}-column`} scope='column'><div>{label}</div></th>
              )}
            </tr>
          </tbody>
        </table>
      </aside>
    );
  }
}

export default translate('translations')(
  withStyles(s)(ConfusionMatrix)
);
