import React, { Component } from 'react';
import { DocumentCollection } from '../../../../types-business/DocumentCollection';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import { translate } from 'react-i18next';

import ReactTable from 'react-table';
import ReactTableStyles from 'react-table/react-table.css';
import ReactTableStylesOverride from '../../../../scss/_react-table.scss';
import columns from './_table/columns';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import s from './styles.scss';
import { History, Location } from 'history';
import Message from '../../../../toolkit/message';
import { getPaginationData } from '../../../../utils/helpers';
import Pagination from '../../../../toolkit/pagination';

interface Props {
  items: DocumentCollection[];
  isLoading: boolean;
  hasError: boolean;
  history: History;
  location: Location;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  loadCollections: (page?: number, size?: number) => void;
  t: (str: string, vars?: any) => string;
}

export class CollectionList extends Component<Props> {
  componentWillMount() {
    const { loadCollections, location } = this.props;
    const {page, size} = getPaginationData(location);
    loadCollections(page, size);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { loadCollections, location } = this.props;
      const { page, size } = getPaginationData(location)
      loadCollections(page, size);
    }
  }

  requestPage = (page) => {
    this.props.history.push(`?page=${page + 1}`);
  }
  render() {
    const { items, isLoading, hasError, currentPage, pageSize, totalItems, t } = this.props;
    if (hasError) {
      return (
        <Message message={t('collections.data-load-error')} type="error" />
      );
    }

    if (isLoading) {
      return (
        <div className={`${s.loadingCollections} loading-collections`}>
          <SvgSpinner />
        </div>
      );
    }
    if (items.length) {
      return (
        <>
          <ReactTable
            className={classNames('default-akorda-table', s.collectionTable)}
            columns={columns({...this.props, styles: s})}
            data={items}
            loading={isLoading}
            loadingText={t('models.loading')}
            manual
            noDataText={t('models.no-data')}
            sortable={false}
            showPaginationBottom={false}
          />
          <Pagination
            className={s.dataSetPagination}
            pageNumber={currentPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPrevious={this.requestPage}
            onNext={this.requestPage}
            />
          </>
      );
    } else {
      return (
        <Message message={t('collections.no-items-message')} type="info" />
      );
    }
  }
}

export default translate('translations')(withStyles(ReactTableStyles, ReactTableStylesOverride, s)(CollectionList));
