exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}.XKqf_{width:220px}._1RH16{width:538px}._3CNNY{color:#1c1c43;font-size:28px;font-weight:300;line-height:38px}._3CNNY,._3OUm6{font-family:Lato,Montserrat-Light,HelveticaNeue-Light,Segoe UI,sans-serif}._3OUm6{color:#0174ca;font-size:14px;line-height:20px;text-align:right;position:absolute;right:0;top:84px}", ""]);

// exports
exports.locals = {
	"logo": "XKqf_",
	"formContainer": "_1RH16",
	"title": "_3CNNY",
	"forgotLink": "_3OUm6"
};