exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1GM_B{height:calc(100% - 50px);margin-bottom:6px}._1GM_B .material-icons.success{color:green;font-weight:700}._1GM_B .material-icons.training{color:orange}._1GM_B .material-icons.failed{color:red}", ""]);

// exports
exports.locals = {
	"trainingStatus": "_1GM_B"
};