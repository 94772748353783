import { createStore as createReduxStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import rootStore from './combineReducers';

const createStore = initialState => {
  const middlewares = [thunk, reduxPackMiddleware];

  if (__DEV__) {
    middlewares.push(createLogger());
  }

  const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
  });

  const store = createReduxStore(
    rootStore,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./combineReducers', () => {
      store.replaceReducer(rootStore);
    });
  }

  return store;
};

export default createStore;
