import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import constants from './constants';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { pureMe } from '../_Login/queries/authQueries';
import * as loginActions from '../_Login/actions';

class PrivateRoute extends Route<any> {
  state = {
    loading: true,
    success: false,
    userContext: null
  };

  static defaultProps = {
    reservedRoles: Object.values(constants.ROLES)
  };

  sessionCookieName: string = 'Authorization=';
  sessionCheckTime: number = 1000 * 60 * 15;

  async componentDidMount() {
    try {
      const { data } = await this.props.client.query({ query: pureMe() });
      this.props.setUserContext(data.me);
      this.setState({ success: true, loading: false, userContext: data.me });
    } catch (e) {
      this.props.setUserContext(null);
      this.setState({ success: false, loading: false });
    }
    this.isSessionAlive();
  }

  isSessionAlive = () => {
    setTimeout(async () => {
      if (document.cookie.includes(this.sessionCookieName)) {
        this.isSessionAlive();
      } else {
        this.setState(
          { success: false, loading: false, userContext: null },
          () => {
            this.props.setUserContext(null);
          }
        );
      }
    }, this.sessionCheckTime);
  };

  render() {
    const {
      component: Component,
      reservedRoles,
      reservedRoute,
      client,
      computedMatch,
      ...rest
    } = this.props;

    const roles = this.state.userContext ? this.state.userContext.groups : [];
    const hasAccess = roles.some(role => reservedRoles.includes(role));

    if (this.state.loading) {
      return <div />;
    } else {
      if (hasAccess && this.state.success) {
        return (
          <Route
            {...rest}
            render={props => <Component {...props} {...computedMatch.params} />}
          />
        );
      } else {
        return (
          <Route
            {...rest}
            render={props => (
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            )}
          />
        );
      }
    }
  }
}

const mapDispatchToProps = dispatch => ({
  setUserContext: (userContext = null) => {
    dispatch(loginActions.setUserContext(userContext));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withApollo(PrivateRoute));
