import React from "react";
import ContextType from "./types/contextType";

// TODO abstract this common context
interface Props {
  context: {
    // Enables critical path CSS rendering
    // https://github.com/kriasoft/isomorphic-style-loader
    insertCss: any;
    // Universal HTTP client
    fetch: any;
    store: any;
  };
  //TODO
  children: any;
}

interface State {}

export default class App extends React.PureComponent<Props, State> {
  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return React.Children.only(this.props.children);
  }
}
