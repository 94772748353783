import { gql } from 'react-apollo';

export default gql`
  mutation(
    $datasetId: String!
    $collectionId: String
    $documentId: String
    $restrictions: String
    $textRestrictions: String
    $limit: String
    $offset: String
  ) {
    addItemsToDataset(
      datasetId: $datasetId
      collectionId: $collectionId
      documentId: $documentId
      restrictions: $restrictions
      textRestrictions: $textRestrictions
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        text
        labels {
          value
        }
      }
    }
  }
`;
