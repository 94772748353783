import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Layout from '../../../_Home/components/layout';
import { CollectionListContainer } from '../../components/collection/list';
import CollectionListToolbar from '../../components/collection/list/toolbar';
import { NewCollectionContainer } from '../../components/collection/new';
import { History, Location } from 'history';

interface Props {
  location: Location;
  history: History;
  t: (key: string) => string;
}

class CollectionsPage extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <Layout
        title={t('collections.page-title')}
        fixedHeight={true}
        footer={false}
        toolbar={<CollectionListToolbar {...this.props} />}
        content={<CollectionListContainer  {...this.props} />}
        sidebarShowRoute={'/collections/new'}
        sidebarCloseRoute="/collections"
        SidebarComponent={NewCollectionContainer}
      />
    );
  }
}

export default translate('translations')(CollectionsPage);
