import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import classNames from 'classnames';
import s from './styles.scss';
import Mark from 'mark.js';
import debounce from 'lodash/debounce';
import { SUB_SENTENCE_LABEL_COLORS } from '../component';
import get from 'lodash/get';

interface Props {
  id: string;
  text: string;
  labels: any[];
  hasFocus: boolean;
}

export class DatasetReviewItemText extends Component<Props> {
  private sentenceTextRef;
  private markInstance;
  private highlightMatchesDebounced = null;

  constructor(props) {
    super(props);
    this.highlightMatchesDebounced = debounce(this.highlightMatches, 300);
  }

  componentDidUpdate() {
    this.highlightMatchesDebounced();
  }

  highlightMatches(): void {
    const { labels } = this.props;
    let partialLabelCount = 0;
    this.markInstance = this.markInstance || new Mark(this.sentenceTextRef);

    function highlight(): void {
      if (labels && labels.length) {
        labels.forEach((label) => {
          const sourceType = get(label, 'source.type', '');
          const isManualLabel = sourceType === 'MANUAL';
          // Show the highlighted, partial text classification only on manually tagged items
          if (isManualLabel && label.startPosition !== null && label.endPosition) {
            partialLabelCount++;
            const start = label.startPosition;
            const length = label.endPosition - label.startPosition;
            this.markInstance.markRanges([{ start, length }],{
              each: (node) => {
                node.setAttribute('title', label.value);
                node.setAttribute('style', `color: ${SUB_SENTENCE_LABEL_COLORS[partialLabelCount - 1]};`)
              }
            })
          }
        });
      }
    }
    this.markInstance.unmark({
      done: highlight.bind(this)
    });
  }

  render() {
    const { text, hasFocus, id } = this.props;
    return (
      <div className={s.textContent}>
        <div
          data-testid="dataset-review-sentence"
          ref={el => (this.sentenceTextRef = el)}
          className={classNames(s.text, {
            [s.hasFocus]: hasFocus
          })}>
          {text}
        </div>
        <div id={id} className={s.fakeText}>{text}</div>
      </div>
    );
  }
}

export default withStyles(s)(DatasetReviewItemText);
