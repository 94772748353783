import React, { Component } from 'react';
import _ from 'lodash';
import { DatasetItem } from '../../../../types-business/Dataset';
import Pagination from '../../../../toolkit/pagination';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { translate } from 'react-i18next';
import keydown from 'react-keydown';
import SvgSpinner from '../../../../toolkit/svgSpinner';
import Message from '../../../../toolkit/message';
import s from './styles.scss';
import { DatasetReviewItemContainer as DatasetReviewItem } from './item';
import { History, Location } from 'history';
import { getPaginationData, havePropsChanged } from '../../../../utils/helpers';

interface Props {
  acceptedSuggestions: string[];
  currentPage: number;
  datasetId: string;
  datasetInfo: any;
  focusItemId: string;
  hasError: boolean;
  history: History;
  isInLabelReviewMode: boolean;
  isLoading: boolean;
  items: DatasetItem[];
  location: Location;
  pageSize: number;
  rejectedSuggestions: string[];
  status: string;
  totalItems: number;
  accept: (
    datasetId: string,
    componentId: string,
    taxonomyValueId: string,
  ) => void;
  focusLastItemInPage: () => void;
  focusNextReviewItem: () => void;
  focusPreviousReviewItem: () => void;
  reject: (
    datasetId: string,
    componentId: string,
    taxonomyValueId: string
  ) => void;
  requestDataset: (datasetId: string) => void;
  requestDatasetItems: (
    datasetId: string,
    allLables: boolean,
    page: number,
    size: number,
    callbacks?: any
  ) => void;
  revert: (labelId: string, taxonomyValueId: string, isInLabelReviewMode: boolean) => void;
  t: (str: string, vars?: any) => string;
  selectOptions: Array<{
    label: string;
    value: string;
  }>;
}

export class DatasetReview extends Component<Props> {
  componentWillMount() {
    const { requestDataset, datasetId } = this.props;
    requestDataset(datasetId);
    this.loadDatasetItems();
  }

  componentDidUpdate(prevProps) {
    if (havePropsChanged(['location.search', 'isInLabelReviewMode'], prevProps, this.props)) {
      this.loadDatasetItems();
    }
  }

  loadDatasetItems() {
    const { requestDatasetItems, datasetId, location, isInLabelReviewMode } = this.props;
    const { page, size } = getPaginationData(location);
    const allLabels = isInLabelReviewMode; // if data manager review mode is on/active, we should fetch all labels
    requestDatasetItems(datasetId, allLabels, page, size, {
      onSuccess: () => {
        // the focusLastItemInPage state indicates to the load function to focus the last review item on the page
        // Handles the scenario in which the user is focused on the first item of a page, and shifts focus to the previous
        if (location.state && location.state.focusLastItemInPage) {
          this.props.focusLastItemInPage();
        }
      },
      onFailure: () => {}
    });
  }

  handlePageChange = page => {
    this.props.history.push(`?page=${page + 1}`);
  };

  isAccepted() {
    const { focusItemId, acceptedSuggestions } = this.props;
    return acceptedSuggestions.includes(focusItemId);
  }

  isRejected() {
    const { focusItemId, rejectedSuggestions } = this.props;
    return rejectedSuggestions.includes(focusItemId);
  }

  isUnreviewed() {
    return !this.isAccepted() && !this.isRejected();
  }

  @keydown('down')
  focusNext(e) {
    e.preventDefault();
    const { focusNextReviewItem } = this.props;
    focusNextReviewItem();
  }

  @keydown('up')
  focusPrevious(e) {
    e.preventDefault();
    const { focusPreviousReviewItem } = this.props;
    focusPreviousReviewItem();
  }

  @keydown('SHIFT+backspace')
  keyboardRevert(e) {
    e.preventDefault();
    if (!this.isUnreviewed()) {
      const { focusItemId, items, revert, isInLabelReviewMode } = this.props;
      const item = _.find(items, { id: focusItemId });
      item.labels.map(label => {
        if(label.id && label.taxonomyValueId) {
          revert(label.id, label.taxonomyValueId, isInLabelReviewMode);
        }

      });
    }
  }

  @keydown('SHIFT+right')
  keyboardAccept(e) {
    e.preventDefault();
    if (this.isUnreviewed()) {
      const { focusItemId, items, datasetId, accept } = this.props;
      const item = _.find(items, { id: focusItemId });
      const taxonomyValueId = item && item.labels[0] && item.labels[0].taxonomyValueId;
      if (taxonomyValueId) {
        accept.call(this, datasetId, focusItemId, taxonomyValueId);
      }
    } else {
      this.props.focusNextReviewItem();
    }
  }

  @keydown('SHIFT+left')
  keyboardReject(e) {
    e.preventDefault();
    if (this.isUnreviewed()) {
      const { focusItemId, items, datasetId, reject } = this.props;
      const item = _.find(items, { id: focusItemId });
      if (item && item.labels[0] && item.labels[0].taxonomyValueId) {
        reject(datasetId, focusItemId, item.labels[0].taxonomyValueId);
      }
    } else {
      this.props.focusNextReviewItem();
    }
  }

  render() {
    const {
      currentPage,
      hasError,
      items,
      isLoading,
      pageSize,
      totalItems,
      datasetInfo = {},
      t
    } = this.props;

    if (hasError) {
      return (
        <Message message={t('dataset-items.items-load-error')} type="error" />
      );
    }

    if (isLoading) {
      return (
        <div className={`${s.loadingItems}`}>
          <SvgSpinner />
        </div>
      );
    }

    if (items.length) {
      return (
        <div className={s.reviewItems}>
          <div className={s.itemList}>
            {items.map(item => (
              <DatasetReviewItem
                key={item.id}
                componentType={datasetInfo.componentType}
                {...this.props} {...item}
                />
            ))}
          </div>
          <Pagination
            pageNumber={currentPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPrevious={this.handlePageChange}
            onNext={this.handlePageChange}
          />
        </div>
      );
    } else {
      return (
        <Message message={t('dataset-review.no-items-message')} type="info" />
      );
    }
  }
}

export default translate('translations')(withStyles(s)(DatasetReview));
