exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2QIAi,.taOAr{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}._2QIAi span,.taOAr span{font-style:italic}.pfrBl{height:100%}._1yIb2{height:calc(100% - 50px);overflow:hidden;overflow-y:auto}", ""]);

// exports
exports.locals = {
	"noItemsMessage": "taOAr",
	"loadingItems": "_2QIAi",
	"reviewItems": "pfrBl",
	"itemList": "_1yIb2"
};