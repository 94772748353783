exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FhLt .main-page-content{height:calc(100% - 90px)}", ""]);

// exports
exports.locals = {
	"datasetReviewPage": "_1FhLt"
};