import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";

const Fragment = (props: any) => {
  const Fragment = (React as any).Fragment;

  return <Fragment key={props.key}>{props.children}</Fragment>;
};

export default withStyles()(Fragment);
