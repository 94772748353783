import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Layout from '../../../_Home/components/layout';
import TransactionOverlay from '../../../toolkit/transactionOverlay';
import { isTransactionUnderway } from '../../reducers/datasets/selectors';
import { DatasetListContainer } from '../../components/dataset/list';
import { DatasetNewContainer } from '../../components/dataset/new';
import DatasetListToolbar from '../../components/dataset/list/toolbar';
import { Location, History } from 'history';
import { getMyRoles } from '../../../_Login/reducers/selectors';

interface Props {
  isTransactionUnderway: boolean;
  location: Location;
  history: History;
  t: (key: string) => string;
}

const mapStateToProps = state => ({
  isTransactionUnderway: isTransactionUnderway(state),
  userRoles: getMyRoles(state)
});

class DatasetsPage extends Component<Props> {
  render() {
    const { t, isTransactionUnderway } = this.props;
    return (
      <>
        <Layout
          title={t('datasets.page-title')}
          fixedHeight={true}
          footer={false}
          toolbar={<DatasetListToolbar {...this.props} />}
          content={<DatasetListContainer {...this.props}/>}
          sidebarShowRoute={'/datasets/new'}
          sidebarCloseRoute="/datasets"
          SidebarComponent={DatasetNewContainer}
        />
        {isTransactionUnderway && <TransactionOverlay />}
      </>
    );
  }
}

export default translate('translations')(
  connect(mapStateToProps)(DatasetsPage)
);
