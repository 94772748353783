exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-spring{-ms-flex:1 1;flex:1 1}._2q6dP{vertical-align:top;display:inline-block;width:50px;height:50px;margin-right:30px}._2q6dP i.material-icons{font-size:40px}._2q6dP ._2anAl{color:#ff8c00}._2q6dP ._3Xe0t{color:#51af46}._2q6dP ._1dgLB{color:#aa1e30}._2q6dP ._1PpdK{cursor:pointer;color:#ff8c00}._2AKxm{display:block;font-size:12px;font-size:.75rem;padding-left:3px;font-style:italic}", ""]);

// exports
exports.locals = {
	"status": "_2q6dP",
	"unknown": "_2anAl",
	"accepted": "_3Xe0t",
	"rejected": "_1dgLB",
	"revert": "_1PpdK",
	"revertText": "_2AKxm"
};