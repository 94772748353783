import { connect } from 'react-redux';

import ModelDetail from './component';
import { requestModel, requestTaxonomyValues } from '../../../actions/creators';
import { toggleErrorModal } from '../../../../_Home/actions';
import { getModelDetails, getModelParams, isLoading, getModelMetrics, getDistributionFromMetrics, getGroundTruthDistribution } from '../../../reducers/models/selectors';
import { requestDistribution } from '../../../../_Datasets/actions/creators';
import { getDistributionWithMetrics } from '../../../../_Datasets/reducers/dataset/selectors';

const mapStateToProps = (state, ownProps) => {
  const modelDetails = getModelDetails(state, ownProps);
  const { datasetId } = modelDetails;
  const  distributionOnMetrics = getDistributionFromMetrics(state, ownProps);
  const isDistributionOnMetrics = distributionOnMetrics.items.length && distributionOnMetrics.totalCount;
  const distribution = isDistributionOnMetrics ? distributionOnMetrics : getDistributionWithMetrics(state, {...ownProps, datasetId});
  const groundTruthDistribution = getGroundTruthDistribution(state, {...ownProps, datasetId});
  return {
    modelDetails,
    parameters: getModelParams(state, ownProps),
    metrics: getModelMetrics(state, ownProps),
    isLoading: isLoading(state),
    distribution,
    groundTruthDistribution
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getModel: () => {
      const { t, modelId } = ownProps;
      dispatch(
        requestTaxonomyValues()
      );
      dispatch(
        requestModel(modelId, {
          onSuccess: (response) => {
            const datasetId = response && response.data && response.data.datasetId
            dispatch(requestDistribution(datasetId || ''));
          },
          onFailure: () => {
            dispatch(
              toggleErrorModal(t('collections.requestDocumentTypes.failure'))
            );
          }
        })

      )
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelDetail);
