import React, { Component } from 'react';
import { History } from 'history';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import BaseButton from '../../../../../toolkit/baseButton';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { matchPath } from 'react-router-dom';
import { hasDataManagerRole } from '../../../../../utils/helpers';

interface Props {
  location: Location;
  history: History;
  userRoles: string[];
  t: (str: string) => string;
}

class DatasetListToolbar extends Component<Props> {
  render() {
    const { history, t, location, userRoles } = this.props;
    const isDataManager = hasDataManagerRole(userRoles);
    const isShowingNewDatasetSidebar = !!matchPath(location.pathname, {
      path: '/datasets/new',
      exact: true
    });
    return (
      <>
        <section className="crumb-zone">
          <Breadcrumb className="toolbar-breadcrumb">
            <BreadcrumbItem active>{t('datasets.page-title')}</BreadcrumbItem>
          </Breadcrumb>
        </section>
        <section className="controls-zone">
          {isDataManager && (<BaseButton
            onClick={() => history.push('/datasets/new')}
            size="sm"
            color="primary"
            data-elm-id={'create_dataset_button'}
            disabled={isShowingNewDatasetSidebar}>
            {t('datasets.new-dataset-button-label')}
          </BaseButton>)}
        </section>
      </>
    );
  }
}

export default withRouter(translate('translations')(DatasetListToolbar));
