import { gql } from 'react-apollo';

export default gql`
  query GetTaxonomies {
    getTaxonomies {
      data {
        id
        value
        type
      }
      meta {
        totalItems
        pagination {
          pageSize
          page
          isFirstPage
          isLastPage
          totalPages
        }
      }
    }
  }
`;
