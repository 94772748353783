import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SortBy from '../../../../../toolkit/sortBy/sortBy';
import moment from 'moment-timezone';

export default props => {
  const { t, isSelected, toggleModelSelection, location, onSortBy } = props;

  return [
    {
      maxWidth: 40,
      Cell: (row) => {
        const {original: {id, taxonomyId, status}} = row;
        return <input
          type='checkbox'
          checked={isSelected(id)}
          onChange={() => toggleModelSelection(id, taxonomyId)}
          disabled={status !== 'SUCCESS'}
          />;
      }
    },
    {
      Header: _ => {
        return <SortBy
          field='name'
          fieldText={t('models.fields.name')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'name',
      Cell: row => {
        return <Link to={`/model/${row.original.id}`}>{row.value}</Link>;
      }
    },
    {
      Header: _ => {
        return <SortBy
          field='createdDate'
          fieldText={t('models.fields.createdDate')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'createdDate',
      maxWidth: 190,
      minWidth: 135,
      Cell: row => {
        return (
          <div>{moment.unix(row.original.createdDate).format("MMM DD YYYY, h:mm a")}</div>
        );
      }
    },
    {
      Header: t('taxonomies.taxonomy'),
      accessor: 'taxonomy.value',
      style: {
        textTransform: 'capitalize'
      },
      Cell: row => ((row.value || '').replace('_', ' ').toLowerCase())
    },
    {
      Header: _ => {
        return <SortBy
          field='pipelineName'
          fieldText={t('models.pipeline-name')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'pipelineName',
      style: {
        textTransform: 'capitalize'
      },
      Cell: row => ((row.value || '').replace('_', ' ').toLowerCase())
    },
    {
      Header: t('models.dataset'),
      accessor: 'dataset',
      Cell: row => {
        return (
          <Link to={`/datasets/${row.original.datasetId}/items`}>
            {row.original.dataset.name}
          </Link>
        );
      }
    },
    {
      Header: _ => {
        return <SortBy
          field='published'
          fieldText={t('models.published')}
          location={location}
          onSortBy={onSortBy}
        />
      },
      accessor: 'published',
      maxWidth: 100,
      Cell: row => {
        return row.value ? t('globals.yes') : t('globals.no');
      }
    },
    {
      Header: t('models.training-status'),
      accessor: 'status',
      maxWidth: 150,
      style: {
        display: 'flex',
        justifyContent: 'center'
      },
      Cell: row => {
        let icon;
        const value = row.value || 'unknown';
        switch (value) {
          case 'SUCCESS':
            icon = 'done';
            break;
          case 'TRAINING':
            icon = 'cached';
            break;
          case 'FAILED':
            icon = 'error';
            break;
          default:
            icon = 'help_outline';
        }
        return (
          <i
            className={classNames('material-icons', row.value.toLowerCase())}
            title={icon}>
            {icon}
          </i>
        );
      }
    }
  ];
};
